/*
  +-------------------------------------------------------------+
  !     CODE SOURCE MATERIALS                                   !
  !     DIGITECH CONFIDENTIAL                                   !
  !                                                             !
  !     DIGITECH Platform Code                                  !
  !     (C) COPYRIGHT DIGITECH 2022-2024                        !
  !     Licensed Internal Code - Property of DIGITECH           !
  +-------------------------------------------------------------+
  +-------------------------------------------------------------+
  !                                                             !
  !  File  : index.js                                           !
  !  Desc. : Nodejs Digines Front server start point            !
  !                                                             !
  !  Author: D.ESTEVE                                           !
  !  Modif.: 29/03/2024                                         !
  !                                                             !
  !  0.1: Creation                                              !
  +-------------------------------------------------------------+
*/
/*=============== Imports ======================================*/
/*
--- Diginesfront resources
*/
import './index.css';
import {frontinit_f} from './frontinit';
import {frontmain_f} from './frontmain';

/*=============== Global variables =============================*/
/*
---- General context
*/
const frontCtx = {};

/*=============== Main =========================================*/
/*
--- Start initialization
*/
frontinit_f(frontCtx);
/*
--- Get current Arguments
*/
const locArg = window.location.search.toLowerCase();
/*
--- Check if Test Flag is set
*/
if (locArg.includes('test')) frontCtx.test = true;
/*
--- Start the Front Main page
*/
frontmain_f(frontCtx);
