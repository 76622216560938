/*
  +-------------------------------------------------------------+
  !     CODE SOURCE MATERIALS                                   !
  !     DIGITECH CONFIDENTIAL                                   !
  !                                                             !
  !     DIGITECH Platform Code                                  !
  !     (C) COPYRIGHT DIGITECH 2022-2024                        !
  !     Licensed Internal Code - Property of DIGITECH           !
  +-------------------------------------------------------------+
  +-------------------------------------------------------------+
  !                                                             !
  !  File  : commisc.js                                         !
  !  Desc. : Nodejs Object for Miscellaneous common functions   !
  !                                                             !
  !  Author: D.ESTEVE                                           !
  !  Modif.: 29/04/2024                                         !
  !                                                             !
  !  0.1: Creation                                              !
  +-------------------------------------------------------------+
*/
/*=============== Imports ======================================*/

/*=============== Class definition ============================*/

/*+-------------------------------------------------------------+
  ! Class      : Commisc_c                                      !
  ! Description: Class for Miscellaneous common functions       !
  !                                                             !
  ! Public methods:                                             !
  !   - constructor     : Constructor opening new Log File      !
  +-------------------------------------------------------------+
*/
export class Commisc_c {
    /*=============== Constructor Routine ==========================*/

    /*+-------------------------------------------------------------+
      ! Description: Constructor                                    !
      !                                                             !
      ! IN:  - Nothing                                              !
      ! OUT: - Nothing                                              !
      +-------------------------------------------------------------+
    */
    constructor() {
        /*=============== Instance Variables =========================*/

        /*=============== Create Instance ============================*/
        return true;

    } // constructor

    /*=============== Class methods ================================*/

    /*+-------------------------------------------------------------+
      ! Routine    : commiscCompareDate_m                           !
      ! Description: Compare two Dates in format JJ/MM/YY HH:MM:SS  !
      !                                                             !
      ! IN:  - Date 1                                               !
      !      - Date 2                                               !
      ! OUT:  -1 if Date 1 < Date 2                                 !
      !        0 if Date 1 = Date 2                                 !
      !        1 if Date 1 > Date 2                                 !
      !        2 if Date 1 not valid                                !
      !        3 if Date 2 not valid                                !
      +-------------------------------------------------------------+
    */
    commiscCompareDate_m(paramDate1, paramDate2) {
        /*
        --- Process Date 1
        */
        const locDateTime1_a = paramDate1.split(' ');
        if (locDateTime1_a.length !== 2) return 2;
        const locDate1_a = locDateTime1_a[0].split('/');
        if (locDate1_a.length !== 3) return 2;
        const locTime1_a = locDateTime1_a[1].split(':');
        if (locTime1_a.length !== 3) return 2;
        const locTime1 = (new Date(locDate1_a[2], locDate1_a[1], locDate1_a[0],
            locTime1_a[0], locTime1_a[1], locTime1_a[2])).getTime();
        /*
        --- Process Date 2
        */
        const locDateTime2_a = paramDate2.split(' ');
        if (locDateTime2_a.length !== 2) return 3;
        const locDate2_a = locDateTime2_a[0].split('/');
        if (locDate2_a.length !== 3) return 3;
        const locTime2_a = locDateTime2_a[1].split(':');
        if (locTime2_a.length !== 3) return 3;
        const locTime2 = (new Date(locDate2_a[2], locDate2_a[1], locDate2_a[0],
            locTime2_a[0], locTime2_a[1], locTime2_a[2])).getTime();
        /*
        --- Compare the two time
        */
        if (locTime1 < locTime2) return -1;
        if (locTime1 > locTime2) return 1;
        return 0;
    } // commiscCompareDate_m

    /*+-------------------------------------------------------------+
      ! Routine    : commiscHtmlEncode_m                            !
      ! Description: Encode String to HTML                          !
      !                                                             !
      ! IN:  - String to encode                                     !
      ! OUT: - Encoded HTML string                                  !
      +-------------------------------------------------------------+
    */
    commiscHtmlEncode_m(paramString) {
        /*
        --- Initialisation
        */
        const locCharNames = {
            160: 'nbsp',
            161: 'iexcl',
            220: 'Uuml',
            223: 'szlig',
            196: 'Auml',
            252: 'uuml',
        };
        const locString_a = paramString.split('');
        let locHtmlString = "";
        /*
        --- Process Character by character
        */
        for (let locI = 0; locI < paramString.length; locI++) {
            const locChar = locString_a[locI].charCodeAt(0);
            if (locChar < 32 || (locChar > 32 && locChar < 65) || locChar > 127 || (locChar > 90 && locChar < 97)) {
                if (locCharNames[locChar] !== undefined) {
                    locHtmlString += '&' + locCharNames[locChar] + ';';
                } else {
                    locHtmlString += '&#' + locChar + ';';
                }
            } else {
                locHtmlString += locString_a[locI];
            }
        }
        return locHtmlString;
    } // commiscHtmlEncode_m

    /*+-------------------------------------------------------------+
      ! Routine    : commiscHtmlDecode_m                            !
      ! Description: Decode HTML String to Text                     !
      !                                                             !
      ! IN:  - HTML String to decode                                !
      ! OUT: - Decoded string                                       !
      +-------------------------------------------------------------+
    */
    commiscHtmlDecode_m(paramHtmlString) {
        /*
        --- Initialisation
        */
        const locCharNames = {
            'nbsp': ' ',
            'iexcl': '¡',
            'Uuml': 'Ü',
            'szlig': 'ß',
            'Auml': 'Ä',
            'uuml': 'ü',
        };
        const locSpecialChars = {
            'Ã©': 'é',
            'Ã¨': 'è'
        }
        let locDecodedString = "";
        /*
        --- Process Character by character
        */
        for (let locI = 0; locI < paramHtmlString.length; locI++) {
            const locChar = paramHtmlString.substring(locI, locI + 1);
            if (locChar !== '&') {
                /*
                --- Check if special char
                */
                const loc2Char = paramHtmlString.substring(locI, locI + 2);
                if (locSpecialChars[loc2Char] !== undefined) {
                    locDecodedString += locSpecialChars[loc2Char];
                    locI++;
                } else {
                    /*
                    --- Normal character, keep it
                    */
                    locDecodedString += locChar;
                }
            } else {
                /*
                --- Get end of encoded character
                */
                const locEnd = paramHtmlString.indexOf(';', locI);
                if ((locEnd < 0) || ((locEnd - locI) > 6)) {
                    /*
                    --- No End: keep the & alone
                    */
                    locDecodedString += '&';
                } else {
                    /*
                    --- Extract the HTML code
                    */
                    const locCode = paramHtmlString.substring(locI + 1, locEnd);
                    if (locCode.substring(0, 1) === '#') {
                        locDecodedString += String.fromCharCode(parseInt(locCode.substring(1)));
                    } else {
                        if (locCharNames[locCode] !== undefined) {
                            locDecodedString += locCharNames[locCode];
                        }
                    }
                    /*
                    --- Increment the index
                    */
                    locI = locEnd;
                }
            }
        }
        return locDecodedString;
    } // commiscHtmlDecode_m
}
