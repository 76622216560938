/*
  +-------------------------------------------------------------+
  !     CODE SOURCE MATERIALS                                   !
  !     DIGITECH CONFIDENTIAL                                   !
  !                                                             !
  !     DIGITECH Platform Code                                  !
  !     (C) COPYRIGHT DIGITECH 2022                             !
  !     Licensed Internal Code - Property of DIGITECH           !
  +-------------------------------------------------------------+
  +-------------------------------------------------------------+
  !                                                             !
  !  File  : frontlocale.js                                     !
  !  Desc. : Nodejs Digines Front Locale switcher               !
  !                                                             !
  !  Author: D.ESTEVE                                           !
  !  Modif.: 11/08/2022                                         !
  !                                                             !
  !  0.1: Creation                                              !
  +-------------------------------------------------------------+
*/
/*=============== Imports ======================================*/
/*
--- External products
*/
import React from 'react';
import {ToggleButton, ToggleButtonGroup} from "@mui/material";

/*
--- Digitech products
*/
import {frontmainRefreshPage_f} from "./frontmain";

/*=============== Local functions ==============================*/


/*=============== Exported JSX components ======================*/

/*+-------------------------------------------------------------+
  ! Routine    : FrontlocaleSwitcherJSX                         !
  ! Description: JSX Locale switcher                            !
  !                                                             !
  ! IN:  - Properties including Context                         !
  ! OUT: - Page rendering                                       !
  +-------------------------------------------------------------+
*/
export function FrontlocaleSwitcherJSX(paramProps) {
    /*
    --- Initialisation
    */
    const locCtx = paramProps.ctx;
    /*
    --- Return the Switcher
    */
    return (
        <ToggleButtonGroup
            orientation="horizontal"
            value={locCtx.config_o.locale}
            exclusive
            size="small"
            onChange={(paramEvent, paramLocale) => {
                locCtx.config_o.locale = paramLocale;
                locCtx.trans_o.comtransChangeLocale_m(paramLocale);
                frontmainRefreshPage_f(locCtx);
            }}
        >
            <ToggleButton value="fr-FR" aria-label="fr-FR">
                FR
            </ToggleButton>
            <ToggleButton value="en-GB" aria-label="en-GB">
                EN
            </ToggleButton>
        </ToggleButtonGroup>
    );
}

