/*
  +-------------------------------------------------------------+
  !     CODE SOURCE MATERIALS                                   !
  !     DIGITECH CONFIDENTIAL                                   !
  !                                                             !
  !     DIGITECH Platform Code                                  !
  !     (C) COPYRIGHT DIGITECH 2022                             !
  !     Licensed Internal Code - Property of DIGITECH           !
  +-------------------------------------------------------------+
  +-------------------------------------------------------------+
  !                                                             !
  !  File  : frontlogin.js                                      !
  !  Desc. : Nodejs Digines Front Main Web page                 !
  !                                                             !
  !  Author: D.ESTEVE                                           !
  !  Modif.: 13/12/2022                                         !
  !                                                             !
  !  0.1: Creation                                              !
  +-------------------------------------------------------------+
*/
/*=============== Imports ======================================*/
/*
--- External products
*/
import React from 'react';
import {
    Avatar,
    Box,
    Button,
    Grid,
    TextField,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    CircularProgress,
} from "@mui/material";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

/*
--- Digitech products
*/
import {frontmsgState_e, frontmsgLoginRequest_f} from './frontmsg';
import {FrontlocaleSwitcherJSX} from "./frontlocale";
import {FronterrorJSX} from "./fronterror";
import {FrontpasswordresetJS} from "./frontpasswordreset";
import {FrontclientsetJS} from "./frontclientset";
import {FrontcontactsetJS} from "./frontcontactset";
import {frontmainModal_e} from "./frontmain";

/*=============== Local functions ==============================*/

/*+-------------------------------------------------------------+
  ! Routine    : locSubmit_f                                    !
  ! Description: Handle the Submit Button                       !
  !                                                             !
  ! IN:  - Context                                              !
  !      - Event                                                !
  ! OUT: - Nothing                                              !
  +-------------------------------------------------------------+
*/
function locSubmit_f(paramCtx, paramEvent) {
    /*
    --- Start Submit processing
    */
    paramEvent.preventDefault();
    /*
    --- Request login
    */
    frontmsgLoginRequest_f(paramCtx);
    /*
    --- Refresh the login Modal
    */
    frontloginRefreshModal_f(paramCtx);
}

/*+-------------------------------------------------------------+
  ! Routine    : locClose_f                                     !
  ! Description: Handle the Cancel Button                       !
  !                                                             !
  ! IN:  - Context                                              !
  !      - Event                                                !
  ! OUT: - Nothing                                              !
  +-------------------------------------------------------------+
*/
function locClose_f(paramCtx, paramEvent) {
    /*
    --- Start Submit processing
    */
    paramEvent.preventDefault();
    /*
    --- Close the Page
    */
    window.close();
}

/*=============== Local JSX components =========================*/

/*+-------------------------------------------------------------+
  ! Routine    : LocJSXLogin                                    !
  ! Description: JSX User Login modal, local definition         !
  !                                                             !
  ! IN:  - Properties including Context                         !
  ! OUT: - Page rendering                                       !
  +-------------------------------------------------------------+
*/
function LocJSXLogin(paramProps) {
    /*
    --- Initialisation
    */
    const locCtx = paramProps.ctx;
    const locTrans = locCtx.trans_o;
    const locColors = locCtx.config_o.colors;
    /*
    --- Return Login Modal
    */
    return (
        <div>
            <FronterrorJSX ctx={locCtx}/>
            <DialogTitle>
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    {locTrans.comtransGet_m("login", "signIn")}
                    <FrontlocaleSwitcherJSX ctx={locCtx}/>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Box
                    sx={{
                        marginTop: 1, display: 'flex', flexDirection: 'column', alignItems: 'center',
                    }}
                >
                    <Avatar sx={{bgcolor: locColors.backgroundIcon, color: locColors.foregroundIcon}}>
                        <LockOutlinedIcon/>
                    </Avatar>
                    <Box component="form"
                         onSubmit={(paramEvent) => locSubmit_f(locCtx, paramEvent)}
                         noValidate sx={{mt: 1}}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            defaultValue={locCtx.user_o.email}
                            label={locTrans.comtransGet_m("login", "emailAddress")}
                            name="email"
                            autoComplete="email"
                            autoFocus
                            onChange={(paramEvent) => {
                                locCtx.user_o.email = paramEvent.target.value.trim();
                                frontloginRefreshModal_f(locCtx);
                            }}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label={locTrans.comtransGet_m("login", "password")}
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            onChange={(paramEvent) => {
                                locCtx.user_o.password = paramEvent.target.value.trim();
                                frontloginRefreshModal_f(locCtx);
                            }}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{mt: 3, mb: 2}}
                            disabled={((locCtx.user_o.email.length < 1) || (locCtx.user_o.password.length < 1))}
                        >
                            {locTrans.comtransGet_m("login", "submit")}
                        </Button>
                        <Button onClick={() => {
                            locCtx.modalPasswordReset.isDisplayed = true;
                            locCtx.modalPasswordReset.title = locTrans.comtransGet_m("passwordReset", "titleCreate");
                            locCtx.error_o.inError = false;
                            frontloginRefreshModal_f(locCtx);
                        }}>
                            {locTrans.comtransGet_m("login", "new")}
                        </Button>
                        <Button onClick={() => {
                            locCtx.modalPasswordReset.isDisplayed = true;
                            locCtx.modalPasswordReset.title = locTrans.comtransGet_m("passwordReset", "titleReset");
                            locCtx.error_o.inError = false;
                            frontloginRefreshModal_f(locCtx);
                        }}>
                            {locTrans.comtransGet_m("login", "forgot")}
                        </Button>
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={(paramEvent) => locClose_f(locCtx, paramEvent)}>
                    {locTrans.comtransGet_m("common", "cancel")}
                </Button>
            </DialogActions>
        </div>);
}

/*+-------------------------------------------------------------+
  ! Routine    : LocJSXWaiting                                  !
  ! Description: JSX User Waiting modal, local definition       !
  !                                                             !
  ! IN:  - Properties including Context                         !
  ! OUT: - Page rendering                                       !
  +-------------------------------------------------------------+
*/
function LocJSXWaiting(paramProps) {
    /*
    --- Initialisation
    */
    const locTrans = paramProps.ctx.trans_o;

    /*
    --- Return Login Modal
    */
    return (
        <div>
            <DialogTitle>
                {locTrans.comtransGet_m("login", "signIn")}
            </DialogTitle>
            <DialogContent>
                <Box
                    sx={{
                        marginTop: 1, display: 'flex', flexDirection: 'column', alignItems: 'center',
                    }}
                >
                    <Box sx={{mt: 1}}>
                        <CircularProgress/>
                    </Box>
                </Box>
            </DialogContent>
        </div>);
}

/*=============== Exported functions ===========================*/

/*+-------------------------------------------------------------+
  ! Routine    : frontloginRefreshModal_f                       !
  ! Description: Request the refresh of the Login Modal         !
  !                                                             !
  ! IN:  - Context                                              !
  ! OUT: - Nothing                                              !
  +-------------------------------------------------------------+
*/
export function frontloginRefreshModal_f(paramCtx) {
    paramCtx.refresh_o.login_s = !paramCtx.refresh_o.login_s;
    paramCtx.refresh_o.login_f(paramCtx.refresh_o.login_s);
}

/*=============== Exported JSX components ======================*/

/*+-------------------------------------------------------------+
  ! Routine    : FrontloginJSX                                  !
  ! Description: JSX User Login Modal                           !
  !                                                             !
  ! IN:  - Properties including Context                         !
  ! OUT: - Page rendering                                       !
  +-------------------------------------------------------------+
*/
export function FrontloginJSX(paramProps) {
    /*
    --- Initialisation
    */
    const locCtx = paramProps.ctx;
    /*
    --- Get React state for refreshing the page
    */
    const [locLogin_s, locLogin_f] = React.useState(false);
    locCtx.refresh_o.login_f = locLogin_f;
    locCtx.refresh_o.login_s = locLogin_s;
    /*
    --- Process login according the User connection status
    */
    let locLoginContains;
    let locOpen;
    switch (locCtx.user_o.connection) {
        case frontmsgState_e.idle:
        case frontmsgState_e.inError:
            locLoginContains = (<LocJSXLogin ctx={locCtx}/>);
            locOpen = true;
            break
        case frontmsgState_e.requested:
            locLoginContains = (<LocJSXWaiting ctx={locCtx}/>);
            locOpen = true;
            break
        default:
            locLoginContains = (<></>);
            locOpen = false;
            break
    }
    /*
    --- Check if Reset Password modal is to be called
    */
    const locResetPasswordModal = (locCtx.modalPasswordReset.isDisplayed && locOpen) ? (
        <FrontpasswordresetJS ctx={locCtx}/>) : (<></>);
    /*
    --- Check if Client Set modal is to called
    */
    const locClientSetModal = (locCtx.modalClientSet.isDisplayed && locOpen) ? (
        <FrontclientsetJS ctx={locCtx} parentModal={frontmainModal_e.loginModal}/>) : (<></>);
    /*
    --- Check if Contact Set modal is to called
    */
    const locContactSetModal = (locCtx.modalContactSet.isDisplayed && locOpen) ? (
        <FrontcontactsetJS ctx={locCtx}/>) : (<></>);
    /*
    --- Return the Modal
    */
    return (
        <div>
            <Dialog open={locOpen}>
                {locLoginContains}
            </Dialog>
            {locResetPasswordModal}
            {locClientSetModal}
            {locContactSetModal}
        </div>
    );
}

