/*
  +-------------------------------------------------------------+
  !     CODE SOURCE MATERIALS                                   !
  !     DIGITECH CONFIDENTIAL                                   !
  !                                                             !
  !     DIGITECH Platform Code                                  !
  !     (C) COPYRIGHT DIGITECH 2022-2024                        !
  !     Licensed Internal Code - Property of DIGITECH           !
  +-------------------------------------------------------------+
  +-------------------------------------------------------------+
  !                                                             !
  !  File  : frontAdminPassword.js                              !
  !  Desc. : Nodejs Digines Front Admin Password Mgt Modal      !
  !                                                             !
  !  Author: D.ESTEVE                                           !
  !  Modif.: 09/02/2024                                         !
  !                                                             !
  !  0.1: Creation                                              !
  +-------------------------------------------------------------+
*/
/*=============== Imports ======================================*/
/*
--- External products
*/
import React from 'react';
import {
    Avatar,
    Box,
    Button,
    Grid,
    TextField,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    CircularProgress, Typography,
} from "@mui/material";
import LockResetIcon from '@mui/icons-material/LockReset';

/*
--- Digitech products
*/
import {frontmsgAdminPasswordRequest_f, frontmsgState_e} from './frontmsg';
import {FronterrorJSX} from "./fronterror";
import {frontmainModal_e, frontmainRefreshPage_f} from "./frontmain";

/*=============== Local functions ==============================*/

/*+-------------------------------------------------------------+
  ! Routine    : locSubmit_f                                    !
  ! Description: Handle the Submit Button                       !
  !                                                             !
  ! IN:  - Context                                              !
  !      - Event                                                !
  ! OUT: - Nothing                                              !
  +-------------------------------------------------------------+
*/
function locSubmit_f(paramCtx, paramEvent) {
    /*
    --- Start Submit processing
    */
    paramEvent.preventDefault();
    /*
    --- Request Change Password
    */
    frontmsgAdminPasswordRequest_f(paramCtx);
    /*
    --- Refresh the Change Password Modal
    */
    frontAdminPasswordRefreshModal_f(paramCtx);
}

/*+-------------------------------------------------------------+
  ! Routine    : locClose_f                                     !
  ! Description: Handle the Cancel Button                       !
  !                                                             !
  ! IN:  - Context                                              !
  !      - Event                                                !
  ! OUT: - Nothing                                              !
  +-------------------------------------------------------------+
*/
function locClose_f(paramCtx, paramEvent) {
    /*
    --- Stop Event
    */
    paramEvent.preventDefault();
    /*
    --- Close the Modal
    */
    paramCtx.currentModal = frontmainModal_e.adminMenuModal;
    /*
    --- Reset the message state
    */
    paramCtx.modalAdminPassword.msgState = frontmsgState_e.idle;
    paramCtx.error_o.inError = false;
    /*
    --- Refresh the main page
    */
    frontmainRefreshPage_f(paramCtx);
}

/*=============== Local JSX components =========================*/

/*+-------------------------------------------------------------+
  ! Routine    : LocJSXAdminPassword                           !
  ! Description: JSX Password Change modal, local definition    !
  !                                                             !
  ! IN:  - Properties including Context                         !
  ! OUT: - Page rendering                                       !
  +-------------------------------------------------------------+
*/
function LocJSXAdminPassword(paramProps) {
    /*
    --- Initialisation
    */
    const locCtx = paramProps.ctx;
    const locTrans = locCtx.trans_o;
    const locColors = locCtx.config_o.colors;
    /*
    --- Return Login Modal
    */
    return (
        <div>
            <FronterrorJSX ctx={locCtx}/>
            <DialogTitle>
                <Grid container direction="row" justifyContent="flex-start">
                    <Avatar sx={{bgcolor: locColors.backgroundIcon, color: locColors.foregroundIcon}}>
                        <LockResetIcon/>
                    </Avatar>
                    <Box sx={{ml: 4, mt: 1}}>
                        {locTrans.comtransGet_m("adminPassword", "titleReset")}
                    </Box>
                </Grid>
            </DialogTitle>
            <DialogContent sx={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                <Box component="form"
                     noValidate sx={{mt: 1, width: "500px"}}>
                    <TextField
                        margin="normal"
                        autoFocus
                        required
                        sx={{mb: 2}}
                        fullWidth
                        id="email"
                        defaultValue={locCtx.modalAdminPassword.userEmail ? locCtx.modalAdminPassword.userEmail : ""}
                        label={locTrans.comtransGet_m("adminPassword", "emailAddress")}
                        name="email"
                        onChange={(paramEvent) => {
                            locCtx.modalAdminPassword.userEmail = paramEvent.target.value.trim();
                            frontAdminPasswordRefreshModal_f(locCtx);
                        }}
                    />

                </Box>
            </DialogContent>
            <DialogActions>
                <Button
                    type="submit"
                    variant="contained"
                    disabled={(locCtx.modalAdminPassword.userEmail.length < 1)}
                    onClick={(paramEvent) => locSubmit_f(locCtx, paramEvent)}
                >
                    {locTrans.comtransGet_m("adminPassword", "request")}
                </Button>
                <Button onClick={(paramEvent) => locClose_f(locCtx, paramEvent)}>
                    {locTrans.comtransGet_m("common", "cancel")}
                </Button>
            </DialogActions>
        </div>);
}

/*+-------------------------------------------------------------+
  ! Routine    : LocJSXWaiting                                  !
  ! Description: JSX User Waiting modal, local definition       !
  !                                                             !
  ! IN:  - Properties including Context                         !
  ! OUT: - Page rendering                                       !
  +-------------------------------------------------------------+
*/
function LocJSXWaiting(paramProps) {
    /*
    --- Initialisation
    */
    const locCtx = paramProps.ctx;
    const locTrans = locCtx.trans_o;
    const locColors = locCtx.config_o.colors;
    /*
    --- Return Login Modal
    */
    return (<div>
        <DialogTitle>
            <Grid container direction="row" justifyContent="flex-start">
                <Avatar sx={{bgcolor: locColors.backgroundIcon, color: locColors.foregroundIcon}}>
                    <LockResetIcon/>
                </Avatar>
                <Box sx={{ml: 4, mt: 1}}>
                    {locTrans.comtransGet_m("adminPassword", "titleWaiting")}
                </Box>
            </Grid>
        </DialogTitle>
        <DialogContent>
            <Box
                sx={{
                    marginTop: 1, display: 'flex', flexDirection: 'column', alignItems: 'center',
                }}
            >
                <Box sx={{mt: 1}}>
                    <CircularProgress/>
                </Box>
            </Box>
        </DialogContent>
    </div>);
}

/*+-------------------------------------------------------------+
  ! Routine    : LocJSXResponseOkDisplay                        !
  ! Description: JSX Confirmation modal, local definition       !
  !                                                             !
  ! IN:  - Properties including Context                         !
  ! OUT: - Page rendering                                       !
  +-------------------------------------------------------------+
*/
function LocJSXResponseOkDisplay(paramProps) {
    /*
    --- Initialisation
    */
    const locCtx = paramProps.ctx;
    const locTrans = locCtx.trans_o;
    const locColors = locCtx.config_o.colors;
    /*
    --- Return Login Modal
    */
    return (<div>
        <DialogTitle>
            <Box sx={{mb: 2, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <Avatar sx={{bgcolor: locColors.backgroundIcon, color: locColors.foregroundIcon}}>
                    <LockResetIcon/>
                </Avatar>
            </Box>
        </DialogTitle>
        <DialogContent>
            <Box
                sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}
            >
                <Box sx={{mt: 1, mb: 1}}>
                    <Typography variant="body2" component="div" align="center">
                        {locTrans.comtransGet_m("adminPassword", "completed")}
                        <strong> {locCtx.modalAdminPassword.userEmail} </strong>:
                        <br/>
                        <br/>
                        <strong> {locCtx.modalAdminPassword.userNewPassword} </strong>
                    </Typography>
                </Box>
            </Box>
        </DialogContent>
        <DialogActions>
            <Button
                type="submit"
                variant="contained"
                onClick={(paramEvent) => locClose_f(locCtx, paramEvent)}
            >
                {locTrans.comtransGet_m("common", "return")}
            </Button>
        </DialogActions>
    </div>);
}

/*=============== Exported functions ===========================*/

/*+-------------------------------------------------------------+
  ! Routine    : frontAdminPasswordRefreshModal_f               !
  ! Description: Request refresh of the Change Password Modal   !
  !                                                             !
  ! IN:  - Context                                              !
  ! OUT: - Nothing                                              !
  +-------------------------------------------------------------+
*/
export function frontAdminPasswordRefreshModal_f(paramCtx) {
    paramCtx.refresh_o.adminPassword_s = !paramCtx.refresh_o.adminPassword_s;
    paramCtx.refresh_o.adminPassword_f(paramCtx.refresh_o.adminPassword_s);
}

/*=============== Exported JSX components ======================*/

/*+-------------------------------------------------------------+
  ! Routine    : FrontAdminPasswordJS                           !
  ! Description: JSX User Password Reset Modal                  !
  !                                                             !
  ! IN:  - Properties including Context                         !
  ! OUT: - Page rendering                                       !
  +-------------------------------------------------------------+
*/
export function FrontadminpasswordJS(paramProps) {
    /*
    --- Initialisation
    */
    const locCtx = paramProps.ctx;
    /*
    --- Get React state for refreshing the page
    */
    const [locAdminPassword_s, locAdminPassword_f] = React.useState(false);
    locCtx.refresh_o.adminPassword_f = locAdminPassword_f;
    locCtx.refresh_o.adminPassword_s = locAdminPassword_s;
    /*
    --- Process Modal according the Change request message status
    */
    let locContains;
    switch (locCtx.modalAdminPassword.msgState) {
        case frontmsgState_e.idle:
        case frontmsgState_e.inError:
        case frontmsgState_e.ok:
            locContains = (<LocJSXAdminPassword ctx={locCtx}/>);
            break
        case frontmsgState_e.requested:
            locContains = (<LocJSXWaiting ctx={locCtx}/>);
            break
        default:
            locContains = (<></>);
            break
    }
    /*
    --- Return the Modal
    */
    return (
        <div>
            <Dialog open={true} maxWidth="md">
                {locContains}
            </Dialog>
            <Dialog open={locCtx.modalAdminPassword.msgState === frontmsgState_e.ok}>
                <LocJSXResponseOkDisplay ctx={locCtx}/>
            </Dialog>
        </div>
    );
}

