/*
  +-------------------------------------------------------------+
  !     CODE SOURCE MATERIALS                                   !
  !     DIGITECH CONFIDENTIAL                                   !
  !                                                             !
  !     DIGITECH Platform Code                                  !
  !     (C) COPYRIGHT DIGITECH 2022-2024                        !
  !     Licensed Internal Code - Property of DIGITECH           !
  +-------------------------------------------------------------+
  +-------------------------------------------------------------+
  !                                                             !
  !  File  : frontmsg.js                                        !
  !  Desc. : Nodejs Digines Front Messages management           !
  !                                                             !
  !  Author: D.ESTEVE                                           !
  !  Modif.: 21/03/2024                                         !
  !                                                             !
  !  0.1: Creation                                              !
  +-------------------------------------------------------------+
*/
/*=============== Imports ======================================*/
/*
--- External products
*/
import {encode} from 'base-64';
/*
--- Digitech products
*/
import {
    backinterfaceError,
    backinterfaceApi,
    backinterfaceUserType,
    backinterfaceStatus
} from "./diginesback/backinterface";
import {frontmainModal_e, frontmainRefreshPage_f} from "./frontmain";
import {frontloginRefreshModal_f} from "./frontlogin";
import {frontticketslistRefreshModal_f} from "./frontticketslist";
import {frontticketdetailRefreshModal_f} from "./frontticketdetail";
import {frontticketcreationRefreshModal_f} from "./frontticketcreation";
import {frontticketaddcommentRefreshModal_f} from "./frontticketaddcomment";
import {frontPasswordChangeRefreshModal_f} from "./frontpasswordchange";
import {frontPasswordResetRefreshModal_f} from "./frontpasswordreset";
import {frontclientsetRefreshModal_f} from "./frontclientset";
import {frontcontactsetExtractContactsList_f, frontcontactsetRefreshModal_f} from "./frontcontactset";
import {frontfaqdocRefreshModal_f, frontfaqdocType_e} from "./frontfaqdoc";
import {frontadminfaqdocRefreshModal_f} from "./frontadmifaqdoc";
import {frontadminuploadInitFields_f, frontadminuploadRefreshModal_f} from "./frontadminupload";
import {frontadmindownloadRefreshModal_f, frontadmindownloadType_e} from "./frontadmindownload";
import {frontAdminPasswordRefreshModal_f} from "./frontadminpassword";

/*=============== Exported objects =============================*/
/*
--- Message state
*/
export const frontmsgState_e = {
    idle: 0,
    requested: 1,
    ok: 2,
    inError: 3
}
/*
--- Authentication type
*/
export const frontmsgAuthentication_e = {
    none: 0,
    basic: 1,
    token: 2
}
/*=============== Local functions ==============================*/

/*+-------------------------------------------------------------+
  ! Routine    : locSendMsg_f                                   !
  ! Description: Send a Get, POST, PUt or DELETE message to the !
  !               Back Server                                   !
  !                                                             !
  ! IN:  - Context                                              !
  !      - Method (POST, PUT, DELETE)                           !
  !      - Command                                              !
  !      - Authentication type                                  !
  !      - Query                                                !
  !      - Body                                                 !
  !      - Function to call for Response processing             !
  ! OUT: - Updated context                                      !
  +-------------------------------------------------------------+
*/
function locSendMsg_f(paramCtx,
                      paramMethod,
                      paramAuthentication,
                      paramCommand,
                      paramQuery_o,
                      paramBody_o,
                      paramCallback_f) {
    /*
    --- Build the URL
    */
    const locHostname = paramCtx.config_o.backServerHostname;
    const locPort = paramCtx.config_o.backServerPort;
    let locUrl = "https://" + locHostname + ":" + locPort + "/" + paramCommand;

    /*
    --- Check if Query parameters are present
    */
    if (paramQuery_o) {
        /*
        --- Build the Query
        */
        const locQuery = new URLSearchParams(paramQuery_o);
        locUrl += "?" + locQuery.toString();
    }
    /*
    --- Build the options
    */
    const locOptions = {
        method: paramMethod,
        headers: {}
    }
    /*
    --- Check the type of authentication
    */
    switch (paramAuthentication) {
        case frontmsgAuthentication_e.basic:
            /*
            --- Basic Authentication with Email:Password
            */
            const locValue = paramCtx.user_o.email + ":" + paramCtx.user_o.password;
            const locValueBase64 = encode(locValue);
            locOptions.headers['Authorization'] = "Basic " + locValueBase64;
            break;
        case frontmsgAuthentication_e.token:
            /*
            --- Token authentication
            */
            locOptions.headers['Authorization'] = "Bearer " + paramCtx.token;
            break;
        default:
            /*
            --- No Authentication
            */
            break;
    }
    /*
    --- Check if a Body is present
    */
    if (paramBody_o) {
        /*
        --- Convert the Body to Data String
        */
        locOptions.body = JSON.stringify(paramBody_o);
        /*
        --- Set Content type
        */
        locOptions.headers['Accept'] = 'application/json, text/plain, */*';
        locOptions.headers['Content-Type'] = 'application/json';
    }
    /*
    --- Send the HTTPS message
    */
    fetch(locUrl, locOptions)
        .then(paramResponse => paramResponse.json())
        .then((paramData) => paramCallback_f(paramCtx, null, paramData))
        .catch((catchError) => paramCallback_f(paramCtx, catchError, null));

    return true;
}

/*+-------------------------------------------------------------+
  ! Routine    : locFileUpload_f                                !
  ! Description: Upload a File to the Back Server               !
  !                                                             !
  ! IN:  - Context                                              !
  !      - Command                                              !
  !      - File                                                 !
  !      - Query                                                !
  !      - Function to call for Response processing             !
  ! OUT: - Updated context                                      !
  +-------------------------------------------------------------+
*/
function locFileUpload_f(paramCtx,
                         paramCommand,
                         paramFile_o,
                         paramQuery_o,
                         paramCallback_f) {
    /*
    --- Build the URL
    */
    const locHostname = paramCtx.config_o.backServerHostname;
    const locPort = paramCtx.config_o.backServerPort;
    let locUrl = "https://" + locHostname + ":" + locPort + "/" + paramCommand;
    /*
    --- Check if Query parameters are present
    */
    if (paramQuery_o) {
        /*
        --- Build the Query
        */
        const locQuery = new URLSearchParams(paramQuery_o);
        locUrl += "?" + locQuery.toString();
    }
    /*
    --- Build the options
    */
    const locOptions = {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/octet-stream',
            'Authorization': 'Bearer ' + paramCtx.token
        },
        body: paramFile_o
    }
    /*
    --- Send the HTTPS message
    */
    fetch(locUrl, locOptions)
        .then(paramResponse => paramResponse.json())
        .then((paramData) => paramCallback_f(paramCtx, null, paramData))
        .catch((catchError) => paramCallback_f(paramCtx, catchError, null));
    return true;
}

/*+-------------------------------------------------------------+
  ! Routine    : locResponse_f                                  !
  ! Description: Common Response handling                       !
  !                                                             !
  ! IN:  - Context                                              !
  !      - Error if present                                     !
  !      - Json Response                                        !
  ! OUT: - True: Response is Ok                                 !
  +-------------------------------------------------------------+
*/
function locResponse_f(paramCtx, paramError, paramResp, paramRefreshCallback_f) {
    /*
    --- Check if a Network error is found
    */
    if (paramError) {
        /*
        --- Gather Error information
        */
        paramCtx.error_o.inError = true;
        paramCtx.error_o.code = backinterfaceError.networkError;
        paramCtx.error_o.message = paramError.toString();
        /*
        --- Refresh the Modal
        */
        paramRefreshCallback_f(paramCtx);
        return false;
    }
    /*
    --- Check if the Response is in error
    */
    if (paramResp.error) {
        /*
        --- Gather Error information
        */
        paramCtx.error_o.inError = true;
        paramCtx.error_o.code = paramResp.error;
        paramCtx.error_o.message = "";
        /*
        --- Refresh the Modal
        */
        paramRefreshCallback_f(paramCtx);
        return false;
    }
    /*
    --- Set the message as Ok
    */
    paramCtx.error_o.inError = false;
    return true;
}

/*+-------------------------------------------------------------+
  ! Routine    : locAddInfoInText_f                             !
  ! Description: Add information in a ticket of activity text   !
  !                                                             !
  ! IN:  - Context                                              !
  !      - Initial Ticket or Comment text                       !
  ! OUT: - Updated text                                         !
  +-------------------------------------------------------------+
*/
function locAddInfoInText_f(paramCtx, paramInitialText, paramProduct) {
    /*
    --- Initialisation
    */
    const locTrans = paramCtx.trans_o;
    let locNewText = "<div><div>";
    /*
    --- Add Author Information
    */
    const locAuthorName = paramCtx.misc_o.commiscHtmlEncode_m(paramCtx.user_o.firstName + " " + paramCtx.user_o.lastName);
    const locAuthorEmail = paramCtx.user_o.email;
    locNewText += "<!-- $$$Author: " + locAuthorName + " --><strong>" +
        locTrans.comtransGet_m("ticketDetail", "author") + "</strong>: " + locAuthorName +
        "<br /><strong>" + locTrans.comtransGet_m("ticketDetail", "email") + "</strong>: " + locAuthorEmail;
    /*
    --- Add product if present
    */
    if (paramProduct.length > 0) {
        locNewText += "<!-- $$$Product: " + paramCtx.misc_o.commiscHtmlEncode_m(paramProduct) + " -->";
    }
    /*
    --- Check if Files are attached
    */
    if (paramCtx.modalTicketAddFiles.filesLink_a.length > 0) {
        /*
        --- Add attached files Links
        */
        locNewText += "<br /><strong>" + locTrans.comtransGet_m("ticketDetail", "attachedFiles") + "</strong>:<br />";
        let locLineFeed = 3;
        for (let locI = 0; locI < paramCtx.modalTicketAddFiles.filesLink_a.length; locI++) {
            locNewText += paramCtx.modalTicketAddFiles.filesLink_a[locI];
            locLineFeed--;
            if (locLineFeed < 1) {
                locNewText += "<br />"
                locLineFeed = 3;
            } else {
                locNewText += "&nbsp;&nbsp;&nbsp;";
            }
        }
    }
    /*
    --- Add the initial text and return it
    */
    locNewText += "<hr></div>" + paramInitialText + "</div>";
    return locNewText;
}

/*+-------------------------------------------------------------+
  ! Routine    : locLoginResponse_f                             !
  ! Description: Handle the Login response                      !
  !                                                             !
  ! IN:  - Context                                              !
  !      - Error if present                                     !
  !      - Json Response                                        !
  ! OUT: - Nothing                                              !
  +-------------------------------------------------------------+
*/
function locLoginResponse_f(paramCtx, paramError, paramResp) {
    /*
    --- Common response checking
    */
    if (!locResponse_f(paramCtx, paramError, paramResp, frontloginRefreshModal_f)) {
        /*
        --- Set the login state as in Error and return
        */
        paramCtx.user_o.connection = frontmsgState_e.inError;
        return;
    }
    /*
    --- Extract Token
    */
    paramCtx.token = paramResp.token ? paramResp.token : "";
    /*
    --- Extract User information
    */
    paramCtx.user_o.type = paramResp.type ? paramResp.type : backinterfaceUserType.contact;
    paramCtx.user_o.id = paramResp.id ? paramResp.id : "";
    paramCtx.user_o.email = paramResp.email ? paramResp.email : paramCtx.user_o.email;
    paramCtx.user_o.firstName = paramResp.firstName ? paramResp.firstName : "";
    paramCtx.user_o.lastName = paramResp.lastName ? paramResp.lastName : "";
    /*
    --- Check if the User is a Contact or an Admin, Reader or Writer
    */
    if (paramCtx.user_o.type === backinterfaceUserType.contact) {
        /*
        --- The user is a contact: Update the Client information, set the login state as connected
        */
        paramCtx.user_o.clientId = paramResp.clientId ? paramResp.clientId : "";
        paramCtx.user_o.clientName = paramResp.clientName ? paramResp.clientName : "";
        /*
        --- Extract Contacts list
        */
        const locContactsList_a = paramResp.contactsList_a ? paramResp.contactsList_a : [];
        if (frontcontactsetExtractContactsList_f(paramCtx, locContactsList_a) > 1) {
            /*
            --- More than one Client attached to the User: Open the Contact set dialog
            */
            paramCtx.user_o.clientId = "";
            paramCtx.user_o.clientName = "";
            paramCtx.currentModal = frontmainModal_e.contactSetModal;
            paramCtx.modalContactSet.isDisplayed = true;
            paramCtx.modalContactSet.msgState = frontmsgState_e.idle;
            /*
            --- Refresh the Login page
            */
            frontloginRefreshModal_f(paramCtx);
        } else {
            /*
            --- One only Client attached to the User: Set the user as connected and reset the current Modal
            */
            paramCtx.user_o.connection = frontmsgState_e.ok;
            paramCtx.currentModal = frontmainModal_e.noModal;
            /*
            --- Get the Client Information
            */
            frontmsgClientInfoRequest_f(paramCtx);
        }

    } else {
        /*
        --- The User is an Admin or a Reader or a Writer: Open Client Selection modal
        */
        paramCtx.user_o.clientId = "";
        paramCtx.user_o.clientName = "";
        paramCtx.currentModal = frontmainModal_e.clientSetModal;
        paramCtx.modalClientSet.isDisplayed = true;
        paramCtx.modalClientSet.msgGetClientsListState = frontmsgState_e.idle;
        paramCtx.modalClientSet.msgPutClientSetState = frontmsgState_e.idle;
        /*
        --- Refresh the Login page
        */
        frontloginRefreshModal_f(paramCtx);
    }
}

/*+-------------------------------------------------------------+
  ! Routine    : locLogoutResponse_f                            !
  ! Description: Handle the Logout response                     !
  !                                                             !
  ! IN:  - Context                                              !
  !      - Error if present                                     !
  !      - Json Response                                        !
  ! OUT: - Nothing                                              !
  +-------------------------------------------------------------+
*/
function locLogoutResponse_f(paramCtx, paramError, paramResp) {
    /*
    --- Don't do anything, the User is still disconnected
    */
}

/*+-------------------------------------------------------------+
  ! Routine    : locTicketsListResponse_f                       !
  ! Description: Handle the Tickets List response               !
  !                                                             !
  ! IN:  - Context                                              !
  !      - Error if present                                     !
  !      - Json Response                                        !
  ! OUT: - Nothing                                              !
  +-------------------------------------------------------------+
*/
function locTicketsListResponse_f(paramCtx, paramError, paramResp) {
    /*
    --- Common response checking
    */
    if (!locResponse_f(paramCtx, paramError, paramResp, frontticketslistRefreshModal_f)) {
        /*
        --- Set the message state as in Error and return
        */
        paramCtx.modalTicketsList.msgState = frontmsgState_e.inError;
        return;
    }
    /*
    --- Extract Tickets List
    */
    paramCtx.modalTicketsList.ticketsList = paramResp ? paramResp : [];
    /*
    --- Set the message state as ok
    */
    paramCtx.modalTicketsList.msgState = frontmsgState_e.ok;
    /*
    --- Refresh the Modal
    */
    frontticketslistRefreshModal_f(paramCtx);
}

/*+-------------------------------------------------------------+
  ! Routine    : locTicketDetailResponse_f                      !
  ! Description: Handle the Ticket Detail response              !
  !                                                             !
  ! IN:  - Context                                              !
  !      - Error if present                                     !
  !      - Json Response                                        !
  ! OUT: - Nothing                                              !
  +-------------------------------------------------------------+
*/
function locTicketDetailResponse_f(paramCtx, paramError, paramResp) {
    /*
    --- Common response checking
    */
    if (!locResponse_f(paramCtx, paramError, paramResp, frontticketdetailRefreshModal_f)) {
        /*
        --- Set the message state as in Error and return
        */
        paramCtx.modalTicketDetail.msgState = frontmsgState_e.inError;
        return;
    }
    /*
    --- Extract Ticket Detail
    */
    paramCtx.modalTicketDetail.ticketInformation = paramResp ? paramResp : {};
    /*
    --- Set the message state as ok
    */
    paramCtx.modalTicketDetail.msgState = frontmsgState_e.ok;
    /*
    --- Refresh the Modal
    */
    frontticketdetailRefreshModal_f(paramCtx);
}

/*+-------------------------------------------------------------+
  ! Routine    : locProductsListResponse_f                      !
  ! Description: Handle the Products List response              !
  !                                                             !
  ! IN:  - Context                                              !
  !      - Error if present                                     !
  !      - Json Response                                        !
  ! OUT: - Nothing                                              !
  +-------------------------------------------------------------+
*/
function locProductsListResponse_f(paramCtx, paramError, paramResp) {
    /*
    --- Common response checking
    */
    if (!locResponse_f(paramCtx, paramError, paramResp, frontticketcreationRefreshModal_f)) {
        /*
        --- Set the message state as in Error and return
        */
        paramCtx.modalTicketCreation.msgProductListState = frontmsgState_e.inError;
        return;
    }
    /*
    --- Check if the Products List is present
    */
    if ((!paramResp) || (paramResp.length < 1)) {
        /*
        --- Gather Error information
        */
        paramCtx.error_o.inError = true;
        paramCtx.error_o.code = backinterfaceError.noProduct;
        paramCtx.error_o.message = "";
        /*
        --- Set the message state as in Error
        */
        paramCtx.modalTicketCreation.msgProductListState = frontmsgState_e.inError;
        /*
        --- Refresh the Modal
        */
        frontticketcreationRefreshModal_f(paramCtx);
        return;
    }
    /*
    --- Extract Products List
    */
    paramCtx.modalTicketCreation.productsList = [];
    for (let locI = 0; locI < paramResp.length; locI++) {
        paramCtx.modalTicketCreation.productsList.push(paramResp[locI].id);
    }
    /*
    --- Set the Products List message state as ok
    */
    paramCtx.modalTicketCreation.msgProductListState = frontmsgState_e.ok;
    /*
    --- Refresh the Modal
    */
    frontticketcreationRefreshModal_f(paramCtx);
}

/*+-------------------------------------------------------------+
  ! Routine    : locClientsListResponse_f                       !
  ! Description: Handle the Clients List response               !
  !                                                             !
  ! IN:  - Context                                              !
  !      - Error if present                                     !
  !      - Json Response                                        !
  ! OUT: - Nothing                                              !
  +-------------------------------------------------------------+
*/
function locClientsListResponse_f(paramCtx, paramError, paramResp) {
    /*
    --- Common response checking
    */
    if (!locResponse_f(paramCtx, paramError, paramResp, frontclientsetRefreshModal_f)) {
        /*
        --- Set the message state as in Error and return
        */
        paramCtx.modalClientSet.msgGetClientsListState = frontmsgState_e.inError;
        return;
    }
    /*
    --- Check if the Response is present
    */
    if (!paramResp) {
        /*
        --- Gather Error information
        */
        paramCtx.error_o.inError = true;
        paramCtx.error_o.code = backinterfaceError.clientNotProvided;
        paramCtx.error_o.message = "";
        /*
        --- Set the message state as in Error
        */
        paramCtx.modalClientSet.msgGetClientsListState = frontmsgState_e.inError;
        /*
        --- Refresh the Modal
        */
        frontclientsetRefreshModal_f(paramCtx);
        return;
    }
    /*
    --- Save the Clients List
    */
    paramCtx.modalClientSet.clientsList_a = paramResp;
    /*
    --- Set the message state as ok
    */
    paramCtx.modalClientSet.msgGetClientsListState = frontmsgState_e.ok;
    /*
    --- Refresh the Modal
    */
    frontclientsetRefreshModal_f(paramCtx);
}

/*+-------------------------------------------------------------+
  ! Routine    : locClientInfoResponse_f                        !
  ! Description: Handle the Client Info response                !
  !                                                             !
  ! IN:  - Context                                              !
  !      - Error if present                                     !
  !      - Json Response                                        !
  ! OUT: - Nothing                                              !
  +-------------------------------------------------------------+
*/
function locClientInfoResponse_f(paramCtx, paramError, paramResp) {
    /*
    --- Common response checking
    */
    if (!locResponse_f(paramCtx, paramError, paramResp, frontmainRefreshPage_f)) {
        /*
        --- Set the message state as in Error and return
        */
        paramCtx.pageMain.msgState = frontmsgState_e.inError;
        return;
    }
    /*
    --- Check if the Response is present
    */
    if ((!paramResp) || (!paramResp.typeId)) {
        /*
        --- Gather Error information
        */
        paramCtx.error_o.inError = true;
        paramCtx.error_o.code = backinterfaceError.clientNotProvided;
        paramCtx.error_o.message = "";
        /*
        --- Set the message state as in Error
        */
        paramCtx.pageMain.msgState = frontmsgState_e.inError;
        /*
        --- Refresh the Main Page
        */
        frontmainRefreshPage_f(paramCtx);
        return;
    }
    /*
    --- Save the Client Type
    */
    paramCtx.user_o.clientType = paramResp.typeId;
    /*
    --- Set the message state as ok
    */
    paramCtx.pageMain.msgState = frontmsgState_e.ok;
    /*
    --- Refresh the Main Page
    */
    frontmainRefreshPage_f(paramCtx);
}

/*+-------------------------------------------------------------+
  ! Routine    : locClientSetResponse_f                         !
  ! Description: Handle the Client Set response                 !
  !                                                             !
  ! IN:  - Context                                              !
  !      - Error if present                                     !
  !      - Json Response                                        !
  ! OUT: - Nothing                                              !
  +-------------------------------------------------------------+
*/
function locClientSetResponse_f(paramCtx, paramError, paramResp) {
    /*
    --- Common response checking
    */
    if (!locResponse_f(paramCtx, paramError, paramResp, frontclientsetRefreshModal_f)) {
        /*
        --- Set the message state as in Error and return
        */
        paramCtx.modalClientSet.msgPutClientSetState = frontmsgState_e.inError;
        return;
    }
    /*
    --- Set the message state as ok
    */
    paramCtx.modalClientSet.msgPutClientSetState = frontmsgState_e.ok;
    paramCtx.modalClientSet.msgGetClientsListState = frontmsgState_e.idle;
    /*
    --- Set User as Connected and return to main page
     */
    paramCtx.modalClientSet.isDisplayed = false;
    paramCtx.modalClientSet.searchText = "";
    paramCtx.modalClientSet.selectedClientIndex = -1;
    paramCtx.user_o.connection = frontmsgState_e.ok;
    paramCtx.currentModal = frontmainModal_e.noModal;
    /*
    --- Get the Client Information
    */
    frontmsgClientInfoRequest_f(paramCtx);
}


/*+-------------------------------------------------------------+
  ! Routine    : locContactSetResponse_f                        !
  ! Description: Handle the Contact Set response                !
  !                                                             !
  ! IN:  - Context                                              !
  !      - Error if present                                     !
  !      - Json Response                                        !
  ! OUT: - Nothing                                              !
  +-------------------------------------------------------------+
*/
function locContactSetResponse_f(paramCtx, paramError, paramResp) {
    /*
    --- Common response checking
    */
    if (!locResponse_f(paramCtx, paramError, paramResp, frontcontactsetRefreshModal_f)) {
        /*
        --- Set the message state as in Error and return
        */
        paramCtx.modalContactSet.msgState = frontmsgState_e.inError;
        return;
    }
    /*
    --- Set the message state as ok
    */
    paramCtx.modalContactSet.msgState = frontmsgState_e.ok;
    /*
    --- Set User as Connected and return to main page
     */
    paramCtx.modalContactSet.isDisplayed = false;
    paramCtx.user_o.connection = frontmsgState_e.ok;
    paramCtx.currentModal = frontmainModal_e.noModal;
    /*
    --- Get the Client Information
    */
    frontmsgClientInfoRequest_f(paramCtx);
}

/*+-------------------------------------------------------------+
  ! Routine    : locTicketCreationResponse_f                    !
  ! Description: Handle the Ticket Creation response            !
  !                                                             !
  ! IN:  - Context                                              !
  !      - Error if present                                     !
  !      - Json Response                                        !
  ! OUT: - Nothing                                              !
  +-------------------------------------------------------------+
*/
function locTicketCreationResponse_f(paramCtx, paramError, paramResp) {
    /*
    --- Common response checking
    */
    if (!locResponse_f(paramCtx, paramError, paramResp, frontticketcreationRefreshModal_f)) {
        /*
        --- Set the message state as in Error and return
        */
        paramCtx.modalTicketCreation.msgTicketCreationState = frontmsgState_e.inError;
        return;
    }
    /*
    --- Get the created ticket id and reference
    */
    paramCtx.modalTicketCreation.ticket.id = paramResp.id;
    paramCtx.modalTicketCreation.ticket.reference = paramResp.reference;
    paramCtx.modalTicketAddFiles.ticketId = paramResp.id;
    /*
    --- Start the Upload of Files if present
    */
    frontmsgFileUploadRequest_f(paramCtx);
}

/*+-------------------------------------------------------------+
  ! Routine    : locTicketUpdateResponse_f                      !
  ! Description: Handle the Ticket Update response              !
  !                                                             !
  ! IN:  - Context                                              !
  !      - Error if present                                     !
  !      - Json Response                                        !
  ! OUT: - Nothing                                              !
  +-------------------------------------------------------------+
*/
function locTicketUpdateResponse_f(paramCtx, paramError, paramResp) {
    /*
    --- Common response checking
    */
    if (!locResponse_f(paramCtx, paramError, paramResp, frontticketcreationRefreshModal_f)) {
        /*
        --- Set the message state as in Error and return
        */
        paramCtx.modalTicketCreation.msgTicketCreationState = frontmsgState_e.inError;
        return;
    }
    /*
    --- Set the message as Ok
    */
    paramCtx.error_o.inError = false;
    paramCtx.modalTicketCreation.msgTicketCreationState = frontmsgState_e.ok;
    /*
    --- Refresh the Modal
    */
    frontticketcreationRefreshModal_f(paramCtx);
}

/*+-------------------------------------------------------------+
  ! Routine    : locTicketReopenResponse_f                      !
  ! Description: Handle the Ticket Reopen response              !
  !                                                             !
  ! IN:  - Context                                              !
  !      - Error if present                                     !
  !      - Json Response                                        !
  ! OUT: - Nothing                                              !
  +-------------------------------------------------------------+
*/
function locTicketReopenResponse_f(paramCtx, paramError, paramResp) {
    /*
    --- Common response checking
    */
    if (!locResponse_f(paramCtx, paramError, paramResp, frontticketaddcommentRefreshModal_f)) {
        /*
        --- Set the message state as in Error and return
        */
        paramCtx.modalTicketAddComment.msgState = frontmsgState_e.inError;
        return;
    }
    /*
    --- Set the message as Ok
    */
    paramCtx.error_o.inError = false;
    paramCtx.modalTicketAddComment.msgState = frontmsgState_e.ok;
    /*
    --- Refresh the Add comment page
    */
    frontticketaddcommentRefreshModal_f(paramCtx);
}

/*+-------------------------------------------------------------+
  ! Routine    : locTicketAddCommentResponse_f                  !
  ! Description: Handle the Ticket Add Comment response         !
  !                                                             !
  ! IN:  - Context                                              !
  !      - Error if present                                     !
  !      - Json Response                                        !
  ! OUT: - Nothing                                              !
  +-------------------------------------------------------------+
*/
function locTicketAddCommentResponse_f(paramCtx, paramError, paramResp) {
    /*
    --- Common response checking
    */
    if (!locResponse_f(paramCtx, paramError, paramResp, frontticketaddcommentRefreshModal_f)) {
        /*
        --- Set the message state as in Error and return
        */
        paramCtx.modalTicketAddComment.msgState = frontmsgState_e.inError;
        return;
    }
    /*
    --- Check if the Ticket should be reopened
    */
    if (paramCtx.modalTicketDetail.isTicketReopen === true) {
        /*
        --- Request the Reopen of the Ticket and return
        */
        frontmsgTicketReopenRequest_f(paramCtx);
        return;
    }
    /*
    --- Reset the message state
    */
    paramCtx.modalTicketAddComment.msgState = frontmsgState_e.ok;
    /*
    --- Refresh the add comment Ticket page
    */
    frontticketaddcommentRefreshModal_f(paramCtx);
}

/*+-------------------------------------------------------------+
  ! Routine    : locPasswordChangeResponse_f                    !
  ! Description: Handle the Password Change response            !
  !                                                             !
  ! IN:  - Context                                              !
  !      - Error if present                                     !
  !      - Json Response                                        !
  ! OUT: - Nothing                                              !
  +-------------------------------------------------------------+
*/
function locPasswordChangeResponse_f(paramCtx, paramError, paramResp) {
    /*
    --- Common response checking
    */
    if (!locResponse_f(paramCtx, paramError, paramResp, frontPasswordChangeRefreshModal_f)) {
        /*
        --- Set the message state as in Error and return
        */
        paramCtx.modalPasswordChange.msgState = frontmsgState_e.inError;
        return;
    }
    /*
    --- Set the message as Ok
    */
    paramCtx.modalPasswordChange.msgState = frontmsgState_e.ok;
    /*
    --- Refresh the Modal
    */
    frontPasswordChangeRefreshModal_f(paramCtx);
}

/*+-------------------------------------------------------------+
  ! Routine    : locPasswordResetResponse_f                     !
  ! Description: Handle the Password Reset response             !
  !                                                             !
  ! IN:  - Context                                              !
  !      - Error if present                                     !
  !      - Json Response                                        !
  ! OUT: - Nothing                                              !
  +-------------------------------------------------------------+
*/
function locPasswordResetResponse_f(paramCtx, paramError, paramResp) {
    /*
    --- Common response checking
    */
    if (!locResponse_f(paramCtx, paramError, paramResp, frontPasswordResetRefreshModal_f)) {
        /*
        --- Set the message state as in Error and return
        */
        paramCtx.modalPasswordReset.msgState = frontmsgState_e.inError;
        return;
    }
    /*
    --- Set the message as Ok
    */
    paramCtx.error_o.inError = false;
    paramCtx.modalPasswordReset.msgState = frontmsgState_e.ok;
    /*
    --- Refresh the Modal
    */
    frontPasswordResetRefreshModal_f(paramCtx);
}

/*+-------------------------------------------------------------+
  ! Routine    : locFileUploadResponse_f                        !
  ! Description: Handle the File Upload response                !
  !                                                             !
  ! IN:  - Context                                              !
  !      - Error if present                                     !
  !      - Json Response                                        !
  ! OUT: - Nothing                                              !
  +-------------------------------------------------------------+
*/
function locFileUploadResponse_f(paramCtx, paramError, paramResp) {
    /*
    --- Common response checking
    */
    if (!locResponse_f(paramCtx, paramError, paramResp, paramCtx.modalTicketAddFiles.refreshFunction_f)) {
        /*
        --- Set the upload state as in Error and call the next call back
        */
        paramCtx.modalTicketAddFiles.uploadState = frontmsgState_e.inError;
        paramCtx.modalTicketAddFiles.afterUploadCallback_f(paramCtx);
        return;
    }
    /*
    --- Check if the file Id is present
    */
    if ((!paramResp) || (!paramResp.fileId)) {
        /*
        --- Gather Error information
        */
        paramCtx.error_o.inError = true;
        paramCtx.error_o.code = backinterfaceError.uploadNoPathId;
        paramCtx.error_o.message = "";
        /*
        --- Set the message state as in Error and call next call back
        */
        paramCtx.modalTicketAddFiles.uploadState = frontmsgState_e.inError;
        paramCtx.modalTicketAddFiles.afterUploadCallback_f(paramCtx);
        return;
    }
    /*
    --- Build the File URL from the Upload Response
    */
    const locIndex = paramCtx.modalTicketAddFiles.nbUploadsDone;
    const locHostname = paramCtx.config_o.backServerHostname;
    const locPort = paramCtx.config_o.backServerPort;
    const locFileId = paramResp.fileId;
    const locFileName = paramCtx.modalTicketAddFiles.files_a[locIndex].file.name;
    const locFileLink = '<a href="https://' + locHostname + ':' + locPort + '/filedownload?file=' + locFileId + '">' + locFileName + '</a>';
    /*
    --- Store the created File Link
    */
    paramCtx.modalTicketAddFiles.filesLink_a.push(locFileLink);
    /*
    --- Increment the number of Upload completed
    */
    paramCtx.modalTicketAddFiles.nbUploadsDone++;
    /*
    --- Call for the next upload
    */
    frontmsgFileUploadRequest_f(paramCtx);
}

/*+-------------------------------------------------------------+
  ! Routine    : locFaqDocUploadResponse_f                      !
  ! Description: Handle the FAQ/DOC Upload response             !
  !                                                             !
  ! IN:  - Context                                              !
  !      - Error if present                                     !
  !      - Json Response                                        !
  ! OUT: - Nothing                                              !
  +-------------------------------------------------------------+
*/
function locFaqDocUploadResponse_f(paramCtx, paramError, paramResp) {
    /*
    --- Common response checking
    */
    if (!locResponse_f(paramCtx, paramError, paramResp, frontadminfaqdocRefreshModal_f)) {
        /*
        --- Set the upload state as in Error
        */
        paramCtx.modalAdminUpload.uploadState = frontmsgState_e.inError;
        return;
    }
    /*
    --- Increment the number of Upload completed
    */
    paramCtx.modalAdminUpload.nbUploadsDone++;
    /*
    --- Call for the next upload
    */
    frontmsgFaqDocUploadRequest_f(paramCtx);
}

/*+-------------------------------------------------------------+
  ! Routine    : locFaqDocListResponse_f                        !
  ! Description: Handle the FAQ/Doc List response               !
  !                                                             !
  ! IN:  - Context                                              !
  !      - Error if present                                     !
  !      - Json Response                                        !
  ! OUT: - Nothing                                              !
  +-------------------------------------------------------------+
*/
function locFaqDocListResponse_f(paramCtx, paramError, paramResp) {
    /*
    --- Common response checking
    */
    if (!locResponse_f(paramCtx, paramError, paramResp, frontfaqdocRefreshModal_f)) {
        /*
        --- Set the message state as in Error and return
        */
        paramCtx.modalFaqDoc.msgState = frontmsgState_e.inError;
        return;
    }
    /*
    --- Extract FAQ or Doc List
    */
    paramCtx.modalFaqDoc.faqDocList_a = paramResp ? paramResp : [];
    /*
    --- Set the message state as ok
    */
    paramCtx.modalFaqDoc.msgState = frontmsgState_e.ok;
    /*
    --- Refresh the Modal
    */
    frontfaqdocRefreshModal_f(paramCtx);
}

/*+-------------------------------------------------------------+
  ! Routine    : locFaqDocDirResponse_f                         !
  ! Description: Handle the FAQ/Doc Directory Contents response !
  !                                                             !
  ! IN:  - Context                                              !
  !      - Error if present                                     !
  !      - Json Response                                        !
  ! OUT: - Nothing                                              !
  +-------------------------------------------------------------+
*/
function locFaqDocDirResponse_f(paramCtx, paramError, paramResp) {
    /*
    --- Common response checking
    */
    if (!locResponse_f(paramCtx, paramError, paramResp, frontadminfaqdocRefreshModal_f)) {
        /*
        --- Set the message state as in Error and return
        */
        paramCtx.modalAdminFaqDoc.msgGetDirState = frontmsgState_e.inError;
        return;
    }
    /*
    --- Extract FAQ or Doc Directory
    */
    paramCtx.modalAdminFaqDoc.faqDocDir_a = paramResp ? paramResp : [];
    /*
    --- Set the message state as ok
    */
    paramCtx.modalAdminFaqDoc.msgGetDirState = frontmsgState_e.ok;
    /*
    --- Refresh the Modal
    */
    frontadminfaqdocRefreshModal_f(paramCtx);
}

/*+-------------------------------------------------------------+
  ! Routine    : locFaqDocDeleteResponse_f                      !
  ! Description: Handle the FAQ/Doc File Delete response        !
  !                                                             !
  ! IN:  - Context                                              !
  !      - Error if present                                     !
  !      - Json Response                                        !
  ! OUT: - Nothing                                              !
  +-------------------------------------------------------------+
*/
function locFaqDocDeleteResponse_f(paramCtx, paramError, paramResp) {
    /*
    --- Common response checking
    */
    if (!locResponse_f(paramCtx, paramError, paramResp, frontadminfaqdocRefreshModal_f)) {
        /*
        --- Set the message state as in Error and return
        */
        paramCtx.modalAdminFaqDoc.msgGetDirState = frontmsgState_e.inError;
        return;
    }
    /*
    --- Set the message state as ok
    */
    paramCtx.modalAdminFaqDoc.msgDeleteState = frontmsgState_e.ok;
    paramCtx.modalAdminFaqDoc.msgGetDirState = frontmsgState_e.idle;
    /*
    --- Refresh the Modal
    */
    frontadminfaqdocRefreshModal_f(paramCtx);
}

/*+-------------------------------------------------------------+
  ! Routine    : locAdminDownloadResponse_f                     !
  ! Description: Handle the FAQ/Doc Directory Contents response !
  !                                                             !
  ! IN:  - Context                                              !
  !      - Error if present                                     !
  !      - Json Response                                        !
  ! OUT: - Nothing                                              !
  +-------------------------------------------------------------+
*/
function locAdminDownloadResponse_f(paramCtx, paramError, paramResp, paramBlob) {
    /*
    --- Set the message state as ok
    */
    paramCtx.modalAdminDownload.downloadState = frontmsgState_e.ok;
    /*
    --- Response checking
    */
    if (!locResponse_f(paramCtx, paramError, paramResp, frontadmindownloadRefreshModal_f)) {
        /*
        --- Set the message state as in Error and return
        */
        paramCtx.modalAdminDownload.downloadState = frontmsgState_e.inError;
        return;
    }
    /*
    --- Goto the Admin Modal
    */
    paramCtx.currentModal = frontmainModal_e.adminMenuModal;
    frontmainRefreshPage_f(paramCtx);
    /*
    --- Create a Link to the downloaded file as a Blob
    */
    const locBlob = new Blob([paramBlob], {type: 'text/plain'});
    const locAnchor = document.createElement('a');
    locAnchor.download = paramCtx.modalAdminDownload.currentFile;
    locAnchor.href = window.URL.createObjectURL(locBlob);
    /*
    --- Click on it
    */
    locAnchor.click();
}

/*+-------------------------------------------------------------+
  ! Routine    : locAdminPasswordResponse_f                     !
  ! Description: Handle the Admin Password Management response  !
  !                                                             !
  ! IN:  - Context                                              !
  !      - Error if present                                     !
  !      - Json Response                                        !
  ! OUT: - Nothing                                              !
  +-------------------------------------------------------------+
*/
function locAdminPasswordResponse_f(paramCtx, paramError, paramResp) {
    /*
    --- Common response checking
    */
    if (!locResponse_f(paramCtx, paramError, paramResp, frontAdminPasswordRefreshModal_f)) {
        /*
        --- Set the message state as in Error and return
        */
        paramCtx.modalAdminPassword.msgState = frontmsgState_e.inError;
        return;
    }
    /*
    --- Get the user new password
    */
    paramCtx.modalAdminPassword.userNewPassword = paramResp.password;
    /*
    --- Set the message as Ok
    */
    paramCtx.error_o.inError = false;
    paramCtx.modalAdminPassword.msgState = frontmsgState_e.ok;
    /*
    --- Refresh the Modal
    */
    frontAdminPasswordRefreshModal_f(paramCtx);
}

/*=============== Exported functions ===========================*/

/*+-------------------------------------------------------------+
  ! Routine    : frontmsgLoginRequest_f                         !
  ! Description: Request the User Login                         !
  !                                                             !
  ! IN:  - Context                                              !
  ! OUT: - Updated context                                      !
  +-------------------------------------------------------------+
*/
export function frontmsgLoginRequest_f(paramCtx) {
    /*
    --- Prepare REST GET Query
    */
    const locQuery_o = {
        locale: paramCtx.config_o.locale
    };
    /*
    --- Set the login state as requested
    */
    paramCtx.user_o.connection = frontmsgState_e.requested;
    /*
    --- Sent the request
    */
    locSendMsg_f(paramCtx, "GET", frontmsgAuthentication_e.basic,
        backinterfaceApi.getLogin, locQuery_o, null, locLoginResponse_f);
}


/*+-------------------------------------------------------------+
  ! Routine    : frontmsgLogoutRequest_f                        !
  ! Description: Request the User logout to Back End            !
  !                                                             !
  ! IN:  - Context                                              !
  ! OUT: - Updated context                                      !
  +-------------------------------------------------------------+
*/
export function frontmsgLogoutRequest_f(paramCtx) {
    /*
    --- Set the User as disconnected
    */
    paramCtx.user_o.connection = frontmsgState_e.idle;
    paramCtx.currentModal = frontmainModal_e.loginModal;
    frontmainRefreshPage_f(paramCtx);
    /*
    --- Sent the request
    */
    locSendMsg_f(paramCtx, "GET", frontmsgAuthentication_e.token,
        backinterfaceApi.getLogout, null, null, locLogoutResponse_f);
}

/*+-------------------------------------------------------------+
  ! Routine    : frontmsgTicketsListRequest_f                   !
  ! Description: Request the Tickets List                       !
  !                                                             !
  ! IN:  - Context                                              !
  ! OUT: - Updated context                                      !
  +-------------------------------------------------------------+
*/
export function frontmsgTicketsListRequest_f(paramCtx) {
    /*
    --- Prepare REST GET Query
    */
    const locQuery_o = {
        status: paramCtx.modalTicketsList.ticketsStatus,
    };
    /*
    --- Set the message state as requested
    */
    paramCtx.modalTicketsList.msgState = frontmsgState_e.requested;
    /*
    --- Sent the request
    */
    locSendMsg_f(paramCtx, "GET", frontmsgAuthentication_e.token,
        backinterfaceApi.getTicketsList, locQuery_o, null, locTicketsListResponse_f);
}

/*+-------------------------------------------------------------+
  ! Routine    : frontmsgTicketDetailRequest_f                  !
  ! Description: Request the Detail for a Ticket                !
  !                                                             !
  ! IN:  - Context                                              !
  ! OUT: - Updated context                                      !
  +-------------------------------------------------------------+
*/
export function frontmsgTicketDetailRequest_f(paramCtx) {
    /*
    --- Prepare REST GET Query
    */
    const locQuery_o = {
        id: paramCtx.modalTicketDetail.ticketId,
    };
    /*
    --- Set the message state as requested
    */
    paramCtx.modalTicketDetail.msgState = frontmsgState_e.requested;
    /*
    --- Sent the request
    */
    locSendMsg_f(paramCtx, "GET", frontmsgAuthentication_e.token,
        backinterfaceApi.getTicketDetail, locQuery_o, null, locTicketDetailResponse_f);
}

/*+-------------------------------------------------------------+
  ! Routine    : frontmsgProductsListRequest_f                  !
  ! Description: Request the Products List                      !
  !                                                             !
  ! IN:  - Context                                              !
  ! OUT: - Updated context                                      !
  +-------------------------------------------------------------+
*/
export function frontmsgProductsListRequest_f(paramCtx) {
    /*
    --- Set the message state as requested
    */
    paramCtx.modalTicketCreation.msgProductListState = frontmsgState_e.requested;
    /*
    --- Sent the request
    */
    locSendMsg_f(paramCtx, "GET", frontmsgAuthentication_e.token,
        backinterfaceApi.getProductsList, null, null, locProductsListResponse_f);
}

/*+-------------------------------------------------------------+
  ! Routine    : frontmsgClientsListRequest_f                   !
  ! Description: Request a Clients list from searched text      !
  !                                                             !
  ! IN:  - Context                                              !
  ! OUT: - Updated context                                      !
  +-------------------------------------------------------------+
*/
export function frontmsgClientsListRequest_f(paramCtx) {
    /*
    --- Prepare REST GET Query
    */
    const locQuery_o = {
        search: paramCtx.modalClientSet.searchText
    };
    /*
    --- Set the message state as requested
    */
    paramCtx.modalClientSet.msgGetClientsListState = frontmsgState_e.requested;
    /*
    --- Sent the request
    */
    locSendMsg_f(paramCtx, "GET", frontmsgAuthentication_e.token,
        backinterfaceApi.getClientsList, locQuery_o, null, locClientsListResponse_f);
}

/*+-------------------------------------------------------------+
  ! Routine    : frontmsgClientInfoRequest_f                    !
  ! Description: Request the Client information                 !
  !                                                             !
  ! IN:  - Context                                              !
  ! OUT: - Updated context                                      !
  +-------------------------------------------------------------+
*/
export function frontmsgClientInfoRequest_f(paramCtx) {
    /*
    --- Prepare REST GET Query
    */
    const locQuery_o = {
        clientId: paramCtx.user_o.clientId
    };
    /*
    --- Set the message state as requested
    */
    paramCtx.pageMain.msgState = frontmsgState_e.requested;
    /*
    --- Sent the request
    */
    locSendMsg_f(paramCtx, "GET", frontmsgAuthentication_e.token,
        backinterfaceApi.getClientInfo, locQuery_o, null, locClientInfoResponse_f);
}

/*+-------------------------------------------------------------+
  ! Routine    : frontmsgClientSetRequest_f                     !
  ! Description: Request the Update of the current Client       !
  !                                                             !
  ! IN:  - Context                                              !
  ! OUT: - Updated context                                      !
  +-------------------------------------------------------------+
*/
export function frontmsgClientSetRequest_f(paramCtx) {
    /*
    --- Prepare the REST POST Body
    */
    const locBody_o =
        {
            "id": paramCtx.user_o.clientId,
            "value": paramCtx.user_o.clientName
        }
    /*
    --- Set the message state as requested
    */
    paramCtx.modalClientSet.msgPutClientSetState = frontmsgState_e.requested;
    /*
    --- Sent the request
    */
    locSendMsg_f(paramCtx, "PUT", frontmsgAuthentication_e.token,
        backinterfaceApi.putClientSet, null, locBody_o, locClientSetResponse_f
    );
}

/*+-------------------------------------------------------------+
  ! Routine    : frontmsgContactSetRequest_f                    !
  ! Description: Request the Update of the current Contact      !
  !                                                             !
  ! IN:  - Context                                              !
  ! OUT: - Updated context                                      !
  +-------------------------------------------------------------+
*/
export function frontmsgContactSetRequest_f(paramCtx) {
    /*
    --- Prepare the REST POST Body
    */
    const locBody_o =
        {
            "id": paramCtx.user_o.id
        }
    /*
    --- Set the message state as requested
    */
    paramCtx.modalContactSet.msgState = frontmsgState_e.requested;
    /*
    --- Sent the request
    */
    locSendMsg_f(paramCtx, "PUT", frontmsgAuthentication_e.token,
        backinterfaceApi.putContactSet, null, locBody_o, locContactSetResponse_f
    );
}

/*+-------------------------------------------------------------+
  ! Routine    : frontmsgTicketCreationRequest_f                !
  ! Description: Request the Creation of a new Ticket           !
  !                                                             !
  ! IN:  - Context                                              !
  ! OUT: - Updated context                                      !
  +-------------------------------------------------------------+
*/
export function frontmsgTicketCreationRequest_f(paramCtx) {
    /*
    --- Get the Product Id and Value
    */
    const locProduct = paramCtx.modalTicketCreation.productsList[parseInt(paramCtx.modalTicketCreation.ticket.product)];
    /*
    --- Prepare the REST POST Body
    */
    const locBody_o =
        {
            "name": paramCtx.modalTicketCreation.ticket.summary,
            "client": {"id": paramCtx.user_o.clientId},
            "contact": {"id": paramCtx.user_o.id},
            "owner": {"id": paramCtx.config_o.supportResponsibleId},
            "manager": {"id": paramCtx.config_o.supportResponsibleId},
            "author": {"id": paramCtx.config_o.supportResponsibleId},
            "status": {"id": "14"},
            "file": {"id": "2"},
            "origin": {"id": "4"},
            "importance": "1",
            "criticality": {"id": paramCtx.modalTicketCreation.ticket.severity},
            "type": {"id": paramCtx.modalTicketCreation.ticket.type},
            "text": paramCtx.modalTicketCreation.ticket.description,
            "active": "True",
            "reclaMere": {"id": "0"},
            "canEdit": "True",
            "persoFields": {
                "Service Clients": [
                    {
                        "id": "305",
                        "name": "Produit",
                        "value": [
                            {
                                "id": locProduct,
                                "value": locProduct
                            }
                        ],
                        "type": 5,
                        "groupAssociated": "0",
                        "linkedNumber": "0"
                    }
                ]
            }
        }

    /*
    --- Set the message state as requested
    */
    paramCtx.modalTicketCreation.msgTicketCreationState = frontmsgState_e.requested;
    /*
    --- Sent the request
    */
    locSendMsg_f(paramCtx, "POST", frontmsgAuthentication_e.token,
        backinterfaceApi.postTicketCreation, null, locBody_o, locTicketCreationResponse_f
    );
}

/*+-------------------------------------------------------------+
  ! Routine    : frontmsgTicketUpdateRequest_f                  !
  ! Description: Request the Update of the current Ticket       !
  !                                                             !
  ! IN:  - Context                                              !
  ! OUT: - Updated context                                      !
  +-------------------------------------------------------------+
*/
export function frontmsgTicketUpdateRequest_f(paramCtx) {
    /*
    --- Get the Product name
    */
    const locProductsList = paramCtx.modalTicketCreation.productsList;
    const locProductIndex = parseInt(paramCtx.modalTicketCreation.ticket.product);
    const locProductName = (locProductIndex < locProductsList.length) ? locProductsList[locProductIndex] : "";
    /*
    --- Update the current description
    */
    const locDescription = locAddInfoInText_f(
        paramCtx,
        paramCtx.modalTicketCreation.ticket.description,
        locProductName);
    /*
    --- Prepare the REST POST Body
    */
    const locBody_o =
        {
            "id": paramCtx.modalTicketCreation.ticket.id,
            "text": locDescription
        }
    /*
    --- Sent the request
    */
    locSendMsg_f(paramCtx, "PUT", frontmsgAuthentication_e.token,
        backinterfaceApi.putTicketUpdate, null, locBody_o, locTicketUpdateResponse_f
    );
}

/*+-------------------------------------------------------------+
  ! Routine    : frontmsgTicketReopenRequest_f                  !
  ! Description: Request the Reopen of the current Ticket       !
  !                                                             !
  ! IN:  - Context                                              !
  ! OUT: - Updated context                                      !
  +-------------------------------------------------------------+
*/
export function frontmsgTicketReopenRequest_f(paramCtx) {
    /*
    --- Prepare the REST POST Body by setting new ticket status to "Demande de réponse de Digitech"
    */
    const locBody_o =
        {
            "id": paramCtx.modalTicketDetail.ticketId,
            "status": {
                "id": "11"
            }
        }
    /*
    --- Sent the request
    */
    locSendMsg_f(paramCtx, "PUT", frontmsgAuthentication_e.token,
        backinterfaceApi.putTicketUpdate, null, locBody_o, locTicketReopenResponse_f
    );
}

/*+-------------------------------------------------------------+
  ! Routine    : frontmsgTicketAddCommentRequest_f              !
  ! Description: Request the Add Comment to a Ticket            !
  !                                                             !
  ! IN:  - Context                                              !
  ! OUT: - Updated context                                      !
  +-------------------------------------------------------------+
*/
export function frontmsgTicketAddCommentRequest_f(paramCtx) {
    /*
    --- Get current ISO Date
    */
    const locCurDate = new Date();
    const locCurDateIso = locCurDate.toISOString();
    /*
    --- Set the assignee as the Ticket Owner if present
    */
    let locAssignee = paramCtx.config_o.supportResponsibleId;
    if ((paramCtx.modalTicketDetail.ticketInformation.owner) &&
        (paramCtx.modalTicketDetail.ticketInformation.owner.id) &&
        (paramCtx.modalTicketDetail.ticketInformation.owner.id.length > 0)) {
        locAssignee = paramCtx.modalTicketDetail.ticketInformation.owner.id;
    }
    /*
    --- Add Information to the comment text
    */
    const locComment = locAddInfoInText_f(paramCtx, paramCtx.modalTicketAddComment.comment.description, "");
    /*
    --- Prepare the REST POST Body (only Type = "4": Note)
    */
    const locBody_o = {
        "assignee": {
            "id": locAssignee,
        },
        "subject": paramCtx.modalTicketAddComment.comment.summary,
        "comment": locComment,
        "client": {
            "id": paramCtx.user_o.clientId,
        },
        "date": locCurDateIso,
        "creationDate": locCurDateIso,
        "dateFin": locCurDateIso,
        "contact": {
            "id": paramCtx.user_o.id,
        },
        "type": "4",
        "stage": "0",
        "ticket": {
            "id": paramCtx.modalTicketDetail.ticketId
        }
    }
    /*
    --- Set the message state as requested
    */
    paramCtx.modalTicketAddComment.msgState = frontmsgState_e.requested;
    /*
    --- Sent the request
    */
    locSendMsg_f(paramCtx, "POST", frontmsgAuthentication_e.token,
        backinterfaceApi.postTicketAddComment, null, locBody_o, locTicketAddCommentResponse_f);
}

/*+-------------------------------------------------------------+
  ! Routine    : frontmsgPasswordChangeRequest_f                !
  ! Description: Request the Change of Password                 !
  !                                                             !
  ! IN:  - Context                                              !
  ! OUT: - Updated context                                      !
  +-------------------------------------------------------------+
*/
export function frontmsgPasswordChangeRequest_f(paramCtx) {
    /*
    --- Prepare the REST PUT Body
    */
    const locBody_o = {
        "oldPassword": paramCtx.modalPasswordChange.oldPassword,
        "newPassword": paramCtx.modalPasswordChange.newPassword,
    }
    /*
    --- Set the message state as requested
    */
    paramCtx.modalPasswordChange.msgState = frontmsgState_e.requested;
    /*
    --- Sent the request
    */
    locSendMsg_f(paramCtx, "PUT", frontmsgAuthentication_e.token,
        backinterfaceApi.putChangePassword, null, locBody_o, locPasswordChangeResponse_f);
}

/*+-------------------------------------------------------------+
  ! Routine    : frontmsgPasswordResetRequest_f                 !
  ! Description: Request the Reset of Password                  !
  !                                                             !
  ! IN:  - Context                                              !
  ! OUT: - Updated context                                      !
  +-------------------------------------------------------------+
*/
export function frontmsgPasswordResetRequest_f(paramCtx) {
    /*
    --- Prepare REST POST Query
    */
    const locQuery_o = {
        email: paramCtx.user_o.email,
        locale: paramCtx.config_o.locale,
    };
    /*
    --- Set the message state as requested
    */
    paramCtx.modalPasswordReset.msgState = frontmsgState_e.requested;
    /*
    --- Sent the request
    */
    locSendMsg_f(paramCtx, "POST", frontmsgAuthentication_e.none,
        backinterfaceApi.postResetPassword, locQuery_o, null, locPasswordResetResponse_f);
}

/*+-------------------------------------------------------------+
  ! Routine    : frontmsgFileUploadRequest_f                    !
  ! Description: Request the Upload of a File                   !
  !                                                             !
  ! IN:  - Context                                              !
  ! OUT: - Updated context                                      !
  +-------------------------------------------------------------+
*/
export function frontmsgFileUploadRequest_f(paramCtx) {
    /*
    --- Check if a File should be uploaded
    */
    const locNbUploadsDone = paramCtx.modalTicketAddFiles.nbUploadsDone;
    if (paramCtx.modalTicketAddFiles.files_a.length <= locNbUploadsDone) {
        /*
        --- Complete the Upload sequence and call the next callback
        */
        paramCtx.modalTicketAddFiles.uploadState = frontmsgState_e.ok;
        paramCtx.modalTicketAddFiles.afterUploadCallback_f(paramCtx);
        return;
    }
    /*
    --- Get File to upload
    */
    const locFile_o = paramCtx.modalTicketAddFiles.files_a[locNbUploadsDone].file;
    /*
    --- Prepare REST POST Query
    */
    const locClientId = paramCtx.user_o.clientId;
    const locDate_o = new Date();
    const locYear = locDate_o.getFullYear();
    const locTicketId = paramCtx.modalTicketAddFiles.ticketId;
    const locQuery_o = {
        path: locClientId + "-" + locYear + "-" + locTicketId,
        name: locFile_o.name
    };
    /*
    --- Set the upload state as requested
    */
    paramCtx.modalTicketAddFiles.uploadState = frontmsgState_e.requested;
    /*
    --- Sent the Upload Request
    */
    locFileUpload_f(paramCtx, backinterfaceApi.postFileUpload, locFile_o, locQuery_o, locFileUploadResponse_f);
}

/*+-------------------------------------------------------------+
  ! Routine    : frontmsgFaqDocListRequest_f                    !
  ! Description: Request the FAQ/Doc List                       !
  !                                                             !
  ! IN:  - Context                                              !
  ! OUT: - Updated context                                      !
  +-------------------------------------------------------------+
*/
export function frontmsgFaqDocListRequest_f(paramCtx) {
    /*
    --- Set the message state as requested
    */
    paramCtx.modalFaqDoc.msgState = frontmsgState_e.requested;
    /*
    --- Define the command
    */
    const locCommand = (paramCtx.modalFaqDoc.type === frontfaqdocType_e.faq) ?
        backinterfaceApi.getFaq : backinterfaceApi.getDoc;
    /*
    --- Sent the request
    */
    locSendMsg_f(paramCtx, "GET", frontmsgAuthentication_e.token,
        locCommand, null, null, locFaqDocListResponse_f);
}

/*+-------------------------------------------------------------+
  ! Routine    : frontmsgFaqDocDirRequest_f                     !
  ! Description: Request the FAQ/Doc Directory contents         !
  !                                                             !
  ! IN:  - Context                                              !
  ! OUT: - Updated context                                      !
  +-------------------------------------------------------------+
*/
export function frontmsgFaqDocDirRequest_f(paramCtx) {
    /*
    --- Set the message state as requested
    */
    paramCtx.modalAdminFaqDoc.msgGetDirState = frontmsgState_e.requested;
    /*
    --- Define the command
    */
    const locCommand = (paramCtx.modalAdminFaqDoc.type === frontfaqdocType_e.faq) ?
        backinterfaceApi.getFaqDir : backinterfaceApi.getDocDir;
    /*
    --- Sent the request
    */
    locSendMsg_f(paramCtx, "GET", frontmsgAuthentication_e.token,
        locCommand, null, null, locFaqDocDirResponse_f);
}

/*+-------------------------------------------------------------+
  ! Routine    : frontmsgFaqDocUploadRequest_f                  !
  ! Description: Request the Upload of a FAQ or DOC File        !
  !                                                             !
  ! IN:  - Context                                              !
  ! OUT: - Updated context                                      !
  +-------------------------------------------------------------+
*/
export function frontmsgFaqDocUploadRequest_f(paramCtx) {
    /*
    --- Check if a File should be uploaded
    */
    const locNbUploadsDone = paramCtx.modalAdminUpload.nbUploadsDone;
    const locType = paramCtx.modalAdminUpload.type;
    if (paramCtx.modalAdminUpload.files_a.length <= locNbUploadsDone) {
        /*
        --- Complete the Upload sequence and return to FAQ/DOC Admin modal
        */
        paramCtx.currentModal = (locType === frontfaqdocType_e.faq) ? frontmainModal_e.adminFaqModal : frontmainModal_e.adminDocModal;
        paramCtx.modalAdminFaqDoc.msgGetDirState = frontmsgState_e.idle;
        /*
        --- Re-initialize the Upload Modal
        */
        frontadminuploadInitFields_f(paramCtx, locType);
        /*
        --- Refresh the Main page and Return
        */
        frontmainRefreshPage_f(paramCtx);
        return;
    }
    /*
    --- Get File to upload
    */
    const locFile_o = paramCtx.modalAdminUpload.files_a[locNbUploadsDone].file;
    paramCtx.modalAdminUpload.currentFile = locFile_o.name;
    /*
    --- Prepare REST POST Query
    */
    const locQuery_o = {
        file: locFile_o.name
    };
    /*
    --- Set the Command according the Type
    */
    const locCommand = (locType === frontfaqdocType_e.faq) ? backinterfaceApi.postFaq : backinterfaceApi.postDoc;
    /*
    --- Sent the Upload Request
    */
    locFileUpload_f(paramCtx, locCommand, locFile_o, locQuery_o, locFaqDocUploadResponse_f);
    /*
    --- Set the upload state as requested
    */
    paramCtx.modalAdminUpload.uploadState = frontmsgState_e.requested;
    /*
    --- Refresh the Upload Dialog
    */
    frontadminuploadRefreshModal_f(paramCtx);
}

/*+-------------------------------------------------------------+
  ! Routine    : frontmsgFaqDocDeleteRequest_f                  !
  ! Description: Request the FAQ/Doc File Delete                !
  !                                                             !
  ! IN:  - Context                                              !
  ! OUT: - Updated context                                      !
  +-------------------------------------------------------------+
*/
export function frontmsgFaqDocDeleteRequest_f(paramCtx, paramFileName) {
    /*
    --- Set the message state as requested
    */
    paramCtx.modalAdminFaqDoc.msgDeleteState = frontmsgState_e.requested;
    /*
    --- Define the command
    */
    const locCommand = (paramCtx.modalAdminFaqDoc.type === frontfaqdocType_e.faq) ?
        backinterfaceApi.deleteFaq : backinterfaceApi.deleteDoc;
    /*
    --- Prepare REST POST Query
    */
    const locQuery_o = {
        file: paramFileName
    }
    /*
    --- Sent the request
    */
    locSendMsg_f(paramCtx, "DELETE", frontmsgAuthentication_e.token,
        locCommand, locQuery_o, null, locFaqDocDeleteResponse_f);
}

/*+-------------------------------------------------------------+
  ! Routine    : frontmsgAdminDownloadRequest_f                 !
  ! Description: Request the Admin File Download                !
  !                                                             !
  ! IN:  - Context                                              !
  ! OUT: - Updated context                                      !
  +-------------------------------------------------------------+
*/
export function frontmsgAdminDownloadRequest_f(paramCtx) {
    /*
    --- Set the message state as requested
    */
    paramCtx.modalAdminDownload.downloadState = frontmsgState_e.requested;
    /*
    --- Define the command
    */
    const locCommand = (paramCtx.modalAdminDownload.type === frontadmindownloadType_e.logBackup) ?
        backinterfaceApi.getLogFileBackup : backinterfaceApi.getLogFileCurrent;
    /*
    --- Build the URL
    */
    const locHostname = paramCtx.config_o.backServerHostname;
    const locPort = paramCtx.config_o.backServerPort;
    let locUrl = "https://" + locHostname + ":" + locPort + "/" + locCommand;
    /*
    --- Build the options
    */
    const locOptions = {
        method: "GET",
        headers: {
            Authorization: "Bearer " + paramCtx.token
        },
        reactNative: {textStreaming: true}
    }
    /*
    --- Send the HTTPS message
    */
    fetch(locUrl, locOptions)
        .then((paramResp) => {
            if (paramResp.status !== backinterfaceStatus.ok) {
                paramResp.json().then(paramResp => locAdminDownloadResponse_f(paramCtx, null, paramResp, null));
                return false;
            } else {
                paramResp.blob().then((paramBlob) =>
                    locAdminDownloadResponse_f(paramCtx, null, paramResp, paramBlob));
                return true;
            }
        })
        .catch((catchError) => locAdminDownloadResponse_f(paramCtx, catchError, null, null));
}

/*+-------------------------------------------------------------+
  ! Routine    : frontmsgAdminPasswordRequest_f                 !
  ! Description: Request the Reset of Password                  !
  !                                                             !
  ! IN:  - Context                                              !
  ! OUT: - Updated context                                      !
  +-------------------------------------------------------------+
*/
export function frontmsgAdminPasswordRequest_f(paramCtx) {
    /*
    --- Prepare REST POST Query
    */
    const locQuery_o = {
        email: paramCtx.modalAdminPassword.userEmail
    };
    /*
    --- Set the message state as requested
    */
    paramCtx.modalAdminPassword.msgState = frontmsgState_e.requested;
    /*
    --- Sent the request
    */
    locSendMsg_f(paramCtx, "POST", frontmsgAuthentication_e.token,
        backinterfaceApi.postAdminResetPassword, locQuery_o, null, locAdminPasswordResponse_f);
}