/*
  +-------------------------------------------------------------+
  !     CODE SOURCE MATERIALS                                   !
  !     DIGITECH CONFIDENTIAL                                   !
  !                                                             !
  !     DIGITECH Platform Code                                  !
  !     (C) COPYRIGHT DIGITECH 2022                             !
  !     Licensed Internal Code - Property of DIGITECH           !
  +-------------------------------------------------------------+
  +-------------------------------------------------------------+
  !                                                             !
  !  File  : fronterror.js                                      !
  !  Desc. : Nodejs Digines Front Error display                 !
  !                                                             !
  !  Author: D.ESTEVE                                           !
  !  Modif.: 13/05/2022                                         !
  !                                                             !
  !  0.1: Creation                                              !
  +-------------------------------------------------------------+
*/
/*=============== Imports ======================================*/
/*
--- External products
*/
import React from 'react';
import {Alert, AlertTitle} from "@mui/material";

/*=============== Exported JSX components ======================*/

/*+-------------------------------------------------------------+
  ! Routine    : FronterrorJSX                                  !
  ! Description: JSX Display Error message                      !
  !                                                             !
  ! IN:  - Properties including Context                         !
  ! OUT: - Page rendering                                       !
  +-------------------------------------------------------------+
*/
export function FronterrorJSX(paramProps) {
    /*
    --- Initialisation
    */
    const locCtx = paramProps.ctx;
    const locTrans = locCtx.trans_o;
    /*
    --- Check if an error has been detected
    */
    if (locCtx.error_o.inError) {
        /*
        --- Prepare the Alert
        */
        const locAlertTitle = locTrans.comtransGet_m("login", "errorTitle");
        let locAlertMessage = locTrans.comtransGet_m("error", `${locCtx.error_o.code}`) +
            "(" + locCtx.error_o.code + ")";
        if (locCtx.error_o.message.length > 0) locAlertMessage += ": " + locCtx.error_o.message;
        return (
            <Alert severity="error">
                <AlertTitle>{locAlertTitle}</AlertTitle>
                {locAlertMessage}
            </Alert>
        );
    } else {
        /*
        --- No Error no alert
         */
        return (<></>);
    }
}
