/*
  +-------------------------------------------------------------+
  !     CODE SOURCE MATERIALS                                   !
  !     DIGITECH CONFIDENTIAL                                   !
  !                                                             !
  !     DIGITECH Platform Code                                  !
  !     (C) COPYRIGHT DIGITECH 2022                             !
  !     Licensed Internal Code - Property of DIGITECH           !
  +-------------------------------------------------------------+
  +-------------------------------------------------------------+
  !                                                             !
  !  File  : frontticketaddfiles.js                             !
  !  Desc. : Nodejs Digines Front Ticket Add Files Modal        !
  !                                                             !
  !  Author: D.ESTEVE                                           !
  !  Modif.: 24/10/2022                                         !
  !                                                             !
  !  0.1: Creation                                              !
  +-------------------------------------------------------------+
*/
/*=============== Imports ======================================*/
/*
--- External products
*/
import React from 'react';
import {
    Box,
    Button,
    Grid,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Avatar,
    IconButton
} from "@mui/material";
import {Dropzone, FileItem} from "@dropzone-ui/react";

/*
--- Digitech products
*/
import {Close} from "@mui/icons-material";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import {frontmsgState_e} from "./frontmsg";

/*=============== Local functions ==============================*/

/*
  +-------------------------------------------------------------+
  ! Routine    : locClose_f                                     !
  ! Description: Handle the Return or Close Button              !
  !                                                             !
  ! IN:  - Context                                              !
  !      - Event                                                !
  ! OUT: - Nothing                                              !
  +-------------------------------------------------------------+
*/
function locClose_f(paramCtx, paramEvent) {
    /*
    --- Stop Event
    */
    paramEvent.preventDefault();
    /*
    --- Remove all not valid files
    */
    paramCtx.modalTicketAddFiles.files_a =
        paramCtx.modalTicketAddFiles.files_a.filter((paramFile) => paramFile.valid === true);
    /*
    --- Reset the display flag
    */
    paramCtx.modalTicketAddFiles.isDisplayed = false;
    /*
    --- Refresh the calling Modal
    */
    paramCtx.modalTicketAddFiles.refreshFunction_f(paramCtx);
}

/*=============== Local JSX components =========================*/

/*
  +-------------------------------------------------------------+
  ! Routine    : LocJSXFilesList                                !
  ! Description: JSX Ticket List selected files                 !
  !                                                             !
  ! IN:  - Properties including Context                         !
  ! OUT: - Page rendering                                       !
  +-------------------------------------------------------------+
*/
function LocJSXFilesList(paramProps) {
    /*
    --- Initialisation
    */
    const locCtx = paramProps.ctx;
    const locTrans = locCtx.trans_o;
    /*
    --- Convert locale for Dropzone
    */
    const locLocale = (locCtx.config_o.locale === "fr-FR") ? "FR-fr" : "EN-en";

    /*
    --- Return Dropzone with Files list updated
    */
    return (
        <Box>
            <Dropzone
                localization={locLocale}
                view="list"
                info={true}
                maxFileSize={locCtx.config_o.maxFileSize}
                label={locTrans.comtransGet_m("ticketAddFiles", "add")}
                onChange={(paramFilesList) => {
                    locCtx.modalTicketAddFiles.files_a = paramFilesList;
                    frontticketaddfilesRefreshModal_f(locCtx);
                }}
                value={locCtx.modalTicketAddFiles.files_a}>
                {locCtx.modalTicketAddFiles.files_a.length > 0 &&
                    locCtx.modalTicketAddFiles.files_a.map((paramFile) => (
                        <FileItem
                            {...paramFile}
                            preview
                            info
                            alwaysActive={true}
                            localization={locLocale}
                            onDelete={(paramId) => {
                                locCtx.modalTicketAddFiles.files_a =
                                    locCtx.modalTicketAddFiles.files_a.filter((paramFile) => paramFile.id !== paramId);
                                frontticketaddfilesRefreshModal_f(locCtx);
                            }}
                        />
                    ))}
            </Dropzone>
        </Box>)
}

/*
+-------------------------------------------------------------+
! Routine    : LocJSXTicketAddFiles                           !
! Description: JSX Ticket Add Files display modal             !
!                                                             !
! IN:  - Properties including Context                         !
! OUT: - Page rendering                                       !
+-------------------------------------------------------------+
*/
function LocJSXTicketAddFiles(paramProps) {
    /*
    --- Initialisation
    */
    const locCtx = paramProps.ctx;
    const locTrans = locCtx.trans_o;
    const locColors = locCtx.config_o.colors;
    /*
    --- Return Add Comment Modal
    */
    return (<div>
        <DialogTitle>
            <Grid container direction="row" justifyContent="flex-start">
                <Avatar sx={{bgcolor: locColors.backgroundIcon, color: locColors.foregroundIcon}}>
                    <UploadFileIcon/>
                </Avatar>
                <Box sx={{ml: 4, mt: 1}}>
                    {locTrans.comtransGet_m("ticketAddFiles", "title")}
                </Box>
            </Grid>
            <IconButton
                aria-label="close"
                onClick={(paramEvent) => locClose_f(locCtx, paramEvent)}
                sx={{position: 'absolute', right: 8, top: 8, color: locColors.backgroundIcon}}
            >
                <Close/>
            </IconButton>
        </DialogTitle>
        <DialogContent sx={{pb: 0}}>
            <LocJSXFilesList ctx={locCtx}/>
        </DialogContent>
        <DialogActions sx={{mt: 1, pt: 0, mr: 2}}>
            <Button variant="contained" onClick={(paramEvent) => locClose_f(locCtx, paramEvent)}>
                {locTrans.comtransGet_m("ticketAddFiles", "submit")}
            </Button>
        </DialogActions>
    </div>);
}

/*=============== Exported functions ===========================*/

/*+-------------------------------------------------------------+
  ! Routine    : frontticketaddfilesInitFields_f                !
  ! Description: Initialise the Fields                          !
  !                                                             !
  ! IN:  - Context                                              !
  !      - Dialog Refresh Function                              !
  !      - Current Ticket Id                                    !
  ! OUT: - Nothing                                              !
  +-------------------------------------------------------------+
*/
export function frontticketaddfilesInitFields_f(paramCtx,
                                                paramRefreshFunction_f,
                                                paramTicketId,
                                                paramAfterUploadCallback_f) {
    paramCtx.modalTicketAddFiles = {
        ticketId: paramTicketId,
        isDisplayed: false,
        refreshFunction_f: paramRefreshFunction_f,
        files_a: [],
        filesLink_a: [],
        nbUploadsDone: 0,
        uploadState: frontmsgState_e.idle,
        afterUploadCallback_f: paramAfterUploadCallback_f
    };
}

/*
+-------------------------------------------------------------+
! Routine : frontticketaddfilesRefreshModal_f                 !
! Description: Request the refresh of Ticket Add files Modal  !
!                                                             !
! IN: - Context                                               !
! OUT: - Nothing                                              !
+-------------------------------------------------------------+
*/
export function frontticketaddfilesRefreshModal_f(paramCtx) {
    paramCtx.refresh_o.ticketAddFiles_s = !paramCtx.refresh_o.ticketAddFiles_s;
    paramCtx.refresh_o.ticketAddFiles_f(paramCtx.refresh_o.ticketAddFiles_s);
}

/*=============== Exported JSX components ======================*/

/*
+-------------------------------------------------------------+
! Routine : FrontticketaddfilesJSX                            !
! Description: JSX Ticket Add Files Modal                     !
!                                                             !
! IN: - Properties including Context                          !
! OUT: - Page rendering                                       !
+-------------------------------------------------------------+
*/
export function FrontticketaddfilesJSX(paramProps) {
    /*
    --- Initialisation
    */
    const locCtx = paramProps.ctx;
    /*
    --- Get React state for refreshing the page
    */
    const [locTicketAddFiles_s, locTicketAddFiles_f] = React.useState(false);
    locCtx.refresh_o.ticketAddFiles_f = locTicketAddFiles_f;
    locCtx.refresh_o.ticketAddFiles_s = locTicketAddFiles_s;
    /*
    --- Return the Modal
    */
    return (
        <Dialog open={true} maxWidth="lg" fullWidth>
            <LocJSXTicketAddFiles ctx={locCtx}/>
        </Dialog>);
}

