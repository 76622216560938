/*
  +-------------------------------------------------------------+
  !     CODE SOURCE MATERIALS                                   !
  !     DIGITECH CONFIDENTIAL                                   !
  !                                                             !
  !     DIGITECH Platform Code                                  !
  !     (C) COPYRIGHT DIGITECH 2022                             !
  !     Licensed Internal Code - Property of DIGITECH           !
  +-------------------------------------------------------------+
  +-------------------------------------------------------------+
  !                                                             !
  !  File  : frontticketslist.js                                !
  !  Desc. : Nodejs Digines Front Tickets list Modal            !
  !                                                             !
  !  Author: D.ESTEVE                                           !
  !  Modif.: 05/11/2022                                         !
  !                                                             !
  !  0.1: Creation                                              !
  +-------------------------------------------------------------+
*/
/*=============== Imports ======================================*/
/*
--- External products
*/
import React from 'react';
import {
    Box,
    Button,
    Grid,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    CircularProgress,
    Avatar,
    FormControl,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
    Link, IconButton
} from "@mui/material";
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarExport,
    GridToolbarFilterButton, GridToolbarQuickFilter
} from '@mui/x-data-grid';

/*
--- Digitech products
*/
import {frontmsgState_e, frontmsgTicketsListRequest_f} from './frontmsg';
import {frontmainModal_e, frontmainRefreshPage_f} from "./frontmain";
import {FronterrorJSX} from "./fronterror";
import ViewTimelineIcon from "@mui/icons-material/ViewTimeline";
import {backinterfaceTicketStatus} from "./diginesback/backinterface";
import {Close} from "@mui/icons-material";
import {frontticketdetailGetTicketAuthor_f, frontticketdetailGetTicketProduct_f} from "./frontticketdetail";

/*=============== Local functions ==============================*/

/*+-------------------------------------------------------------+
  ! Routine    : locClose_f                                     !
  ! Description: Handle the Cancel Button                       !
  !                                                             !
  ! IN:  - Context                                              !
  !      - Event                                                !
  ! OUT: - Nothing                                              !
  +-------------------------------------------------------------+
*/
function locClose_f(paramCtx, paramEvent) {
    /*
    --- Stop Event
    */
    paramEvent.preventDefault();
    /*
    --- Close the Modal
    */
    paramCtx.currentModal = frontmainModal_e.noModal;
    /*
    --- Reset the message state
    */
    paramCtx.modalTicketsList.msgState = frontmsgState_e.idle;
    paramCtx.error_o.inError = false;
    /*
    --- Refresh the main page
    */
    frontmainRefreshPage_f(paramCtx);
}

/*+-------------------------------------------------------------+
  ! Routine    : locChangeStatus_f                              !
  ! Description: Handle the Status Radio Button                 !
  !                                                             !
  ! IN:  - Context                                              !
  !      - Event                                                !
  ! OUT: - Nothing                                              !
  +-------------------------------------------------------------+
*/
function locChangeStatus_f(paramCtx, paramEvent, paramStatus) {
    /*
    --- Update the Tickets Status choice
    */
    paramCtx.modalTicketsList.ticketsStatus = paramStatus;
    /*
    --- Reset the message state
    */
    paramCtx.modalTicketsList.msgState = frontmsgState_e.idle;
    /*
    --- Refresh the Modal
    */
    frontticketslistRefreshModal_f(paramCtx);
}

/*+-------------------------------------------------------------+
  ! Routine    : locRequestTicketDetails_f                      !
  ! Description: Handle the Double Click on a Ticket            !
  !                                                             !
  ! IN:  - Context                                              !
  !      - Cell Parameters                                      !
  !      - Event                                                !
  !      - Details                                              !
  ! OUT: - Nothing                                              !
  +-------------------------------------------------------------+
*/
function locRequestTicketDetails_f(paramCtx, paramCell, paramEvent) {
    /*
    --- Stop Event
    */
    paramEvent.stopPropagation();
    /*
    --- No Action if not the Reference Cell
    */
    if (paramCell.field !== "reference") return;
    /*
    --- Get the Ticket ID
    */
    const locIndex = paramCell.row.id;
    paramCtx.modalTicketDetail.ticketId = paramCtx.modalTicketsList.ticketsList[locIndex].id;
    paramCtx.modalTicketDetail.ticketReference = paramCtx.modalTicketsList.ticketsList[locIndex].reference;
    paramCtx.modalTicketDetail.msgState = frontmsgState_e.idle;
    /*
    --- Open the Ticket Detail modal
    */
    paramCtx.currentModal = frontmainModal_e.ticketDetails;
    /*
    --- Refresh the Main Page
    */
    frontmainRefreshPage_f(paramCtx);
}

/*+-------------------------------------------------------------+
  ! Routine    : locReferenceLink_f                             !
  ! Description: Define the Reference Link for each ticket      !
  !                                                             !
  ! IN:  - Context                                              !
  ! OUT: - Nothing                                              !
  +-------------------------------------------------------------+
*/
function locReferenceLink_f(paramCell) {
    return (<Link href="#">{paramCell.value}</Link>);
}

/*+-------------------------------------------------------------+
  ! Routine    : locHeader_f                                    !
  ! Description: Define the Tickets list columns titles         !
  !                                                             !
  ! IN:  - Context                                              !
  ! OUT: - Nothing                                              !
  +-------------------------------------------------------------+
*/
function locHeader_f(paramCtx) {
    /*
    --- Initialisation
    */
    const locTrans = paramCtx.trans_o;
    const locMisc = paramCtx.misc_o;
    /*
    --- Return the defined columns titles
    */
    const locHeader = [{
        field: 'id', headerName: "Index", minWidth: 1, hide: true, editable: false, flex: 1
    }, {
        field: 'reference',
        headerName: locTrans.comtransGet_m("ticketsList", "reference"),
        width: 140,
        editable: false,
        renderCell: locReferenceLink_f
    }, {
        field: 'severity',
        headerName: locTrans.comtransGet_m("severity", "title"),
        width: 120,
        editable: false
    }, {
        field: 'shortDescription',
        headerName: locTrans.comtransGet_m("ticketsList", "shortDescription"),
        minWidth: 500,
        editable: false,
        flex: 1
    }, {
        field: 'author',
        headerName: locTrans.comtransGet_m("ticketsList", "author"),
        minWidth: 150,
        maxWidth: 250,
        editable: false,
        flex: 1
    }, {
        field: 'creationDate',
        type: 'dateTime',
        headerName: locTrans.comtransGet_m("ticketsList", "creationDate"),
        width: 170,
        editable: false,
        sortComparator: (v1, v2) => locMisc.commiscCompareDate_m(v1, v2)
    }, {
        field: 'currentStatus',
        headerName: locTrans.comtransGet_m("ticketsList", "currentStatus"),
        minWidth: 60,
        maxWidth: 110,
        editable: false,
        flex: 1
    }];
    /*
    --- Add Product on test
    */
    if (paramCtx.test) {
        locHeader.splice(2,0,{
            field: 'product',
            headerName: locTrans.comtransGet_m("ticketsList", "product"),
            width: 160,
            editable: false
        })
    }
    /*
    --- Return the built header
    */
    return locHeader;
}

/*+-------------------------------------------------------------+
  ! Routine    : locOneRow_f                                    !
  ! Description: Define one row of the Tickets list             !
  !                                                             !
  ! IN:  - Context                                              !
  !      - JSON values of the Row                               !
  !      - Index of the Row                                     !
  ! OUT: - Nothing                                              !
  +-------------------------------------------------------------+
*/
function locOneRow_f(paramCtx, paramJson, paramIndex) {
    /*
    --- Initialisation
    */
    const locTrans = paramCtx.trans_o;
    /*
    --- Get ticket information
    */
    const locReference = paramJson.reference ? paramJson.reference : " ";
    let locSeverity = "";
    if ((paramJson.criticality) && (paramJson.criticality.id)) {
        locSeverity = locTrans.comtransGet_m("severity", paramJson.criticality.id);
    }
    const locShortDescription = paramJson.name ? paramJson.name : " ";
    /*
    --- Get the Author name
    */
    const locAuthor = frontticketdetailGetTicketAuthor_f(paramCtx, paramJson);
    /*
    --- Get the product name
    */
    const locProduct = frontticketdetailGetTicketProduct_f(paramCtx, paramJson);
    /*
    --- Get the Creation Date and the current Status
    */
    const locCreationDate = paramJson.creationDate ? paramJson.creationDate : " ";
    let locStatus = " ";
    if ((paramJson.status) && (paramJson.status.id)) {
        locStatus = locTrans.comtransGet_m("status", paramJson.status.id);
    }
    /*
    --- Return the defined columns titles
    */
    return {
        id: paramIndex,
        reference: locReference,
        severity: locSeverity,
        shortDescription: locShortDescription,
        author: locAuthor,
        creationDate: locCreationDate,
        currentStatus: locStatus,
        product: locProduct
    };
}

/*=============== Local JSX components =========================*/

/*+-------------------------------------------------------------+
  ! Routine    : LocJSXRadioButton                              !
  ! Description: JSX Tickets Status Radio Button Display        !
  !                                                             !
  ! IN:  - Properties including Context                         !
  ! OUT: - Page rendering                                       !
  +-------------------------------------------------------------+
*/
function LocJSXRadioButton(paramProps) {
    /*
    --- Initialisation
    */
    const locCtx = paramProps.ctx;
    const locTrans = locCtx.trans_o;
    /*
    --- Search the selected Radio button name
    */
    let locRadioButtonName;
    switch (locCtx.modalTicketsList.ticketsStatus) {
        case backinterfaceTicketStatus.open:
            locRadioButtonName = "open";
            break
        case backinterfaceTicketStatus.close:
            locRadioButtonName = "close";
            break
        default:
            locRadioButtonName = "all";
            break
    }
    /*
    --- Return the Radio Button group
    */
    return (<Grid container direction="row" justifyContent="flex-end">
        <FormControl>
            < FormLabel
                id="frontticketslistRadioButtonsGroupLabel">
                {locTrans.comtransGet_m("ticketsList", "status")}
            </FormLabel>
            <RadioGroup
                row
                aria-labelledby="frontticketslistRadioButtonsGroupLabel"
                defaultValue={locRadioButtonName}
                name="frontticketslistRadioButtonsGroup"
            >
                <FormControlLabel value="open" control={<Radio/>}
                                  label={locTrans.comtransGet_m("ticketsList", "open")}
                                  onClick={(paramEvent) => locChangeStatus_f(locCtx, paramEvent, backinterfaceTicketStatus.open)}/>
                <FormControlLabel value="close" control={<Radio/>}
                                  label={locTrans.comtransGet_m("ticketsList", "close")}
                                  onClick={(paramEvent) => locChangeStatus_f(locCtx, paramEvent, backinterfaceTicketStatus.close)}/>

                <FormControlLabel value="all" control={<Radio/>}
                                  label={locTrans.comtransGet_m("ticketsList", "all")}
                                  onClick={(paramEvent) => locChangeStatus_f(locCtx, paramEvent, backinterfaceTicketStatus.all)}/>

            </RadioGroup>
        </FormControl>
    </Grid>)
}

/*+-------------------------------------------------------------+
  ! Routine    : LocJSXDataGrid                                 !
  ! Description: JSX Tickets List table display                 !
  !                                                             !
  ! IN:  - Properties including Context                         !
  ! OUT: - Page rendering                                       !
  +-------------------------------------------------------------+
*/
function LocJSXDataGrid(paramProps) {
    /*
    --- Initialisation
    */
    const locCtx = paramProps.ctx;
    const locColors = locCtx.config_o.colors;

    /*
    --- Build the Columns header
    */
    const locHeader = locHeader_f(locCtx);
    /*
    --- Build a Row for each ticket
    */
    let locRows = [];
    for (let locI = 0; locI < locCtx.modalTicketsList.ticketsList.length; locI++) {
        locRows.push(locOneRow_f(locCtx, locCtx.modalTicketsList.ticketsList[locI], locI))
    }
    /*
    --- Tool bar definition
    */
    const locToolBar = () => {
        return (<GridToolbarContainer>
            <GridToolbarQuickFilter/>
            <GridToolbarFilterButton/>
            <GridToolbarExport/>
        </GridToolbarContainer>)
    }
    /*
    --- Return the Data Grid
    */
    return (<div style={{height: "500px", width: "100%"}}>
        <DataGrid
            rows={locRows}
            columns={locHeader}
            density="compact"
            components={{Toolbar: locToolBar}}
            sx={{
                "& .MuiDataGrid-columnHeader": {background: locColors.backgroundMainGrey, color: locColors.foregroundMain}
            }}
            onCellClick={(paramCell, paramEvent) => locRequestTicketDetails_f(locCtx, paramCell, paramEvent)}
            autoPageSize
            initialState={{
                sorting: {
                    sortModel: [{field: 'creationDate', sort: 'desc'}],
                },
            }}
        />
    </div>);
}

/*+-------------------------------------------------------------+
  ! Routine    : LocJSXTicketsList                              !
  ! Description: JSX Tickets List display modal                 !
  !                                                             !
  ! IN:  - Properties including Context                         !
  ! OUT: - Page rendering                                       !
  +-------------------------------------------------------------+
*/
function LocJSXTicketsList(paramProps) {
    /*
    --- Initialisation
    */
    const locCtx = paramProps.ctx;
    const locTrans = locCtx.trans_o;
    const locColors = locCtx.config_o.colors;
    /*
    --- Return Login Modal
    */
    return (<div>
        <FronterrorJSX ctx={locCtx}/>
        <DialogTitle>
            <Grid container direction="row" justifyContent="flex-start">
                <Avatar sx={{bgcolor: locColors.backgroundIcon, color: locColors.foregroundIcon}}>
                    <ViewTimelineIcon/>
                </Avatar>
                <Box sx={{ml: 4, mt: 1}}>
                    {locTrans.comtransGet_m("ticketsList", "title")}
                </Box>
                <LocJSXRadioButton ctx={locCtx}/>
            </Grid>
            <IconButton
                aria-label="close"
                onClick={(paramEvent) => locClose_f(locCtx, paramEvent)}
                sx={{position: 'absolute', right: 8, top: 8, color: locColors.backgroundIcon}}
            >
                <Close/>
            </IconButton>
        </DialogTitle>
        <DialogContent sx={{pb: 0, mb: 0}}>
            <LocJSXDataGrid ctx={locCtx}/>
        </DialogContent>
        <DialogActions sx={{mt: 0, mb: 0}}>
            <Button onClick={(paramEvent) => locClose_f(locCtx, paramEvent)} sx={{mr: 2}}>
                {locTrans.comtransGet_m("common", "return")}
            </Button>
        </DialogActions>
    </div>);
}

/*
+-------------------------------------------------------------+
! Routine : LocJSXWaiting                                     !
! Description: JSX User Waiting modal, local definition       !
!                                                             !
! IN: - Properties including Context                          !
! OUT: - Page rendering                                       !
+-------------------------------------------------------------+
*/
function LocJSXWaiting(paramProps) {
    /*
    --- Initialisation
    */
    const locCtx = paramProps.ctx;
    const locTrans = locCtx.trans_o;
    const locColors = locCtx.config_o.colors;

    /*
    --- Return Login Modal
    */
    return (<div>
        <DialogTitle>
            <Grid container direction="row" justifyContent="flex-start">
                <Avatar sx={{bgcolor: locColors.backgroundIcon, color: locColors.foregroundIcon}}>
                    <ViewTimelineIcon/>
                </Avatar>
                <Box sx={{ml: 4, mt: 1}}>
                    {locTrans.comtransGet_m("ticketsList", "title")}
                </Box>
            </Grid>
        </DialogTitle>
        <DialogContent>
            <Box
                sx={{
                    marginTop: 1, display: 'flex', flexDirection: 'column', alignItems: 'center',
                }}
            >
                <Box sx={{mt: 1}}>
                    <CircularProgress/>
                </Box>
            </Box>
        </DialogContent>
    </div>);
}

/*=============== Exported functions ===========================*/

/*
+-------------------------------------------------------------+
! Routine : frontticketslistRefreshModal_f !
! Description: Request the refresh of the Tickets List Modal !
! !
! IN: - Context !
! OUT: - Nothing !
+-------------------------------------------------------------+
*/
export function frontticketslistRefreshModal_f(paramCtx) {
    paramCtx.refresh_o.ticketsList_s = !paramCtx.refresh_o.ticketsList_s;
    paramCtx.refresh_o.ticketsList_f(paramCtx.refresh_o.ticketsList_s);
}

/*=============== Exported JSX components ======================*/

/*
+-------------------------------------------------------------+
! Routine : FrontticketslistJSX !
! Description: JSX Tickets list Modal !
! !
! IN: - Properties including Context !
! OUT: - Page rendering !
+-------------------------------------------------------------+
*/
export function FrontticketslistJSX(paramProps) {
    /*
    --- Initialisation
    */
    const locCtx = paramProps.ctx;
    /*
    --- Get React state for refreshing the page
    */
    const [locTicketsList_s, locTicketsList_f] = React.useState(false);
    locCtx.refresh_o.ticketsList_f = locTicketsList_f;
    locCtx.refresh_o.ticketsList_s = locTicketsList_s;
    /*
    --- Process the modal according the Tickets list request message state
    */
    let locModalContains;
    switch (locCtx.modalTicketsList.msgState) {
        case frontmsgState_e.idle:
            /*
            --- Request the tickets list and display waiting modal
            */
            frontmsgTicketsListRequest_f(locCtx);
            locModalContains = (<LocJSXWaiting ctx={locCtx}/>);
            break
        case frontmsgState_e.ok:
        case frontmsgState_e.inError:
            locModalContains = (<LocJSXTicketsList ctx={locCtx}/>);
            break
        case frontmsgState_e.requested:
            locModalContains = (<LocJSXWaiting ctx={locCtx}/>);
            break
        default:
            locModalContains = (<></>);
            break
    }
    /*
    --- Return the Modal
    */
    return (<Dialog open={true} fullWidth maxWidth="xl">
        {locModalContains}
    </Dialog>);

}

