/*
  +-------------------------------------------------------------+
  !     CODE SOURCE MATERIALS                                   !
  !     DIGITECH CONFIDENTIAL                                   !
  !                                                             !
  !     DIGITECH Platform Code                                  !
  !     (C) COPYRIGHT DIGITECH 2022-2023                        !
  !     Licensed Internal Code - Property of DIGITECH           !
  +-------------------------------------------------------------+
  +-------------------------------------------------------------+
  !                                                             !
  !  File  : frontfaqdoc.js                                     !
  !  Desc. : Nodejs Digines Front FAQ and Documentation Modal   !
  !                                                             !
  !  Author: D.ESTEVE                                           !
  !  Modif.: 08/01/2023                                         !
  !                                                             !
  !  0.1: Creation                                              !
  +-------------------------------------------------------------+
*/
/*=============== Imports ======================================*/
/*
--- External products
*/
import React from 'react';
import {
    Avatar,
    Box,
    Button,
    Grid,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    IconButton, CircularProgress, Tabs, Tab
} from "@mui/material";
import {Close} from "@mui/icons-material";
import LiveHelpIcon from '@mui/icons-material/LiveHelp';

/*
--- Digitech products
*/
import {frontmainModal_e, frontmainRefreshPage_f} from "./frontmain";
import {backinterfaceApi} from "./diginesback/backinterface";
import {frontmsgFaqDocListRequest_f, frontmsgState_e} from "./frontmsg";
import MenuBookIcon from "@mui/icons-material/MenuBook";

/*=============== Exported objects =============================*/
export const frontfaqdocType_e = {
    faq: 0,
    doc: 1
}

/*=============== Local functions ==============================*/

/*+-------------------------------------------------------------+
  ! Routine    : locClose_f                                     !
  ! Description: Handle the Cancel Button                       !
  !                                                             !
  ! IN:  - Context                                              !
  !      - Event                                                !
  ! OUT: - Nothing                                              !
  +-------------------------------------------------------------+
*/
function locClose_f(paramCtx, paramEvent) {
    /*
    --- Stop Event
    */
    paramEvent.preventDefault();
    /*
    --- Close the Modal
    */
    paramCtx.modalFaqDoc.msgState = frontmsgState_e.idle;
    paramCtx.currentModal = frontmainModal_e.noModal;
    /*
    --- Refresh the main page
    */
    frontmainRefreshPage_f(paramCtx);
}

/*=============== Local JSX components =========================*/

/*
+-------------------------------------------------------------+
! Routine    : LocJSXTabPanel                                 !
! Description: JSX Build Tab Panel, local definition          !
!                                                             !
! IN:  - Properties including Context                         !
! OUT: - Tabs rendering                                       !
+-------------------------------------------------------------+
*/
function LocJSXTabPanel(paramProps) {
    /*
    --- Initialisation
    */
    const locValue = paramProps.value;
    const locIndex = paramProps.index;
    const locName = paramProps.name;
    const locUri = paramProps.uri;
    /*
    --- Define a Panel
    */
    return (
        <div
            role="tabpanel"
            hidden={locValue !== locIndex}
            id={`tabpanel-${locIndex}`}
            aria-labelledby={`tab-${locIndex}`}
        >
            {locValue === locIndex && (
                <iframe
                    src={locUri}
                    title={locName}
                    style={{width: '100%', minHeight: '450px'}}/>
            )}
        </div>
    );
}

/*
+-------------------------------------------------------------+
! Routine    : LocJSXTabs                                     !
! Description: JSX Build Tabs, local definition               !
!                                                             !
! IN:  - Properties including Context                         !
! OUT: - Tabs rendering                                       !
+-------------------------------------------------------------+
*/
function LocJSXTabs(paramProps) {
    /*
    --- Initialisation
    */
    const locCtx = paramProps.ctx;
    const locFaqDoc_a = locCtx.modalFaqDoc.faqDocList_a;
    /*
    --- Build the FAQ URL
    */
    const locHostname = locCtx.config_o.backServerHostname;
    const locPort = locCtx.config_o.backServerPort;
    let locUrl = "https://" + locHostname + ":" + locPort + "/";
    locUrl += (locCtx.modalFaqDoc.type === frontfaqdocType_e.faq) ? backinterfaceApi.getFaq : backinterfaceApi.getDoc;
    /*
    --- Build Value State
     */
    const [locValue_s, locValue_f] = React.useState(0);
    /*
    --- Return Tabs
    */
    return (
        <Box component="div"
             noValidate sx={{mt: 1, mb: 0, pb: 0}}>
            <Box>
                <Tabs value={locValue_s} onChange={(paramEvent, paramNewValue) => {
                    locValue_f(paramNewValue)
                }}>
                    {
                        locFaqDoc_a.map((paramItem) => (
                            <Tab label={paramItem.name}/>
                        ))
                    }
                </Tabs>
            </Box>
            <div>
                {
                    locFaqDoc_a.map((paramItem, paramIndex) => (
                        <LocJSXTabPanel ctx={locCtx} value={locValue_s} index={paramIndex} name={paramItem.name}
                                        uri={locUrl + "?file=" + paramItem.file}/>
                    ))
                }
            </div>
        </Box>);
}

/*
+-------------------------------------------------------------+
! Routine : LocJSXFaqDoc                                      !
! Description: JSX FAQ or Doc modal, local definition         !
!                                                             !
! IN: - Properties including Context                          !
! OUT: - Page rendering                                       !
+-------------------------------------------------------------+
*/
function LocJSXFaqDoc(paramProps) {
    /*
    --- Initialisation
    */
    const locCtx = paramProps.ctx;
    const locTrans = locCtx.trans_o;
    const locColors = locCtx.config_o.colors;
    const locFaqDoc = (locCtx.modalFaqDoc.type === frontfaqdocType_e.faq) ? "faq" : "doc";
    const locIcon = (locCtx.modalFaqDoc.type === frontfaqdocType_e.faq) ? (<LiveHelpIcon/>) : (<MenuBookIcon/>);
    /*
    --- Return Login Modal
    */
    return (
        <div>
            <DialogTitle>
                <Grid container direction="row" justifyContent="flex-start">
                    <Avatar sx={{bgcolor: locColors.backgroundIcon, color: locColors.foregroundIcon}}>
                        {locIcon}
                    </Avatar>
                    <Box sx={{ml: 4, mt: 1}}>
                        {locTrans.comtransGet_m(locFaqDoc, "title")}
                    </Box>
                </Grid>
                <IconButton
                    aria-label="close"
                    onClick={(paramEvent) => locClose_f(locCtx, paramEvent)}
                    sx={{position: 'absolute', right: 8, top: 8, color: locColors.backgroundIcon}}
                >
                    <Close/>
                </IconButton>
            </DialogTitle>
            <DialogContent sx={{pb: 0}}>
                <LocJSXTabs ctx={locCtx}/>
            </DialogContent>
            <DialogActions>
                <Button onClick={(paramEvent) => locClose_f(locCtx, paramEvent)} sx={{mt: 0, mr: 2, pt: 0}}>
                    {locTrans.comtransGet_m("common", "return")}
                </Button>
            </DialogActions>
        </div>);
}

/*
+-------------------------------------------------------------+
! Routine : LocJSXWaiting                                     !
! Description: JSX User Waiting modal, local definition       !
!                                                             !
! IN: - Properties including Context                          !
! OUT: - Page rendering                                       !
+-------------------------------------------------------------+
*/
function LocJSXWaiting(paramProps) {
    /*
    --- Initialisation
    */
    const locCtx = paramProps.ctx;
    const locTrans = locCtx.trans_o;
    const locColors = locCtx.config_o.colors;
    const locFaqDoc = (locCtx.modalFaqDoc.type === frontfaqdocType_e.faq) ? "faq" : "doc";
    const locIcon = (locCtx.modalFaqDoc.type === frontfaqdocType_e.faq) ? (<LiveHelpIcon/>) : (<MenuBookIcon/>);
    /*
    --- Return Login Modal
    */
    return (<div>
        <DialogTitle>
            <Grid container direction="row" justifyContent="flex-start">
                <Avatar sx={{bgcolor: locColors.backgroundIcon, color: locColors.foregroundIcon}}>
                    {locIcon}
                </Avatar>
                <Box sx={{ml: 4, mt: 1}}>
                    {locTrans.comtransGet_m(locFaqDoc, "title")}
                </Box>
            </Grid>
        </DialogTitle>
        <DialogContent>
            <Box
                sx={{
                    marginTop: 1, display: 'flex', flexDirection: 'column', alignItems: 'center',
                }}
            >
                <Box sx={{mt: 1}}>
                    <CircularProgress/>
                </Box>
            </Box>
        </DialogContent>
    </div>);
}


/*=============== Exported functions ===========================*/

/*
+-------------------------------------------------------------+
! Routine : frontfaqdocRefreshModal_f                         !
! Description: Request the refresh of the FAQ/Doc Modal       !
!                                                             !
! IN: - Context                                               !
! OUT: - Nothing                                              !
+-------------------------------------------------------------+
*/
export function frontfaqdocRefreshModal_f(paramCtx) {
    paramCtx.refresh_o.faqDoc_s = !paramCtx.refresh_o.faqDoc_s;
    paramCtx.refresh_o.faqDoc_f(paramCtx.refresh_o.faqDoc_s);
}

/*=============== Exported JSX components ======================*/

/*
+-------------------------------------------------------------+
! Routine : FrontfaqdocJSX                                    !
! Description: JSX FAQ Modal                                  !
!                                                             !
! IN: - Properties including Context                          !
! OUT: - Page rendering                                       !
+-------------------------------------------------------------+
*/
export function FrontfaqdocJSX(paramProps) {
    /*
    --- Initialisation
    */
    const locCtx = paramProps.ctx;
    locCtx.modalFaqDoc.type = paramProps.type;
    /*
    --- Get React state for refreshing the page
    */
    const [locFaqDoc_s, locFaqDoc_f] = React.useState(false);
    locCtx.refresh_o.faqDoc_f = locFaqDoc_f;
    locCtx.refresh_o.faqDoc_s = locFaqDoc_s;
    /*
    --- Process the modal according the FAQ Get List request message state
    */
    let locModalContains;
    switch (locCtx.modalFaqDoc.msgState) {
        case frontmsgState_e.idle:
            /*
            --- Request the FAQ or Documentation list and display waiting modal
            */
            frontmsgFaqDocListRequest_f(locCtx);
            locModalContains = (<LocJSXWaiting ctx={locCtx}/>);
            break
        case frontmsgState_e.ok:
        case frontmsgState_e.inError:
            locModalContains = (<LocJSXFaqDoc ctx={locCtx}/>);
            break
        case frontmsgState_e.requested:
            locModalContains = (<LocJSXWaiting ctx={locCtx}/>);
            break
        default:
            locModalContains = (<></>);
            break
    }
    /*
    --- Return the Modal
    */
    return (<Dialog open={true} fullWidth maxWidth="xl">
        {locModalContains}
    </Dialog>);
}
