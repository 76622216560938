/*
  +-------------------------------------------------------------+
  !     CODE SOURCE MATERIALS                                   !
  !     DIGITECH CONFIDENTIAL                                   !
  !                                                             !
  !     DIGITECH Platform Code                                  !
  !     (C) COPYRIGHT DIGITECH 2022-2024                        !
  !     Licensed Internal Code - Property of DIGITECH           !
  +-------------------------------------------------------------+
  +-------------------------------------------------------------+
  !                                                             !
  !  File  : frontmain.js                                       !
  !  Desc. : Nodejs Digines Front Main Web page                 !
  !                                                             !
  !  Author: D.ESTEVE                                           !
  !  Modif.: 21/03/2024                                         !
  +-------------------------------------------------------------+
*/
/*=============== Imports ======================================*/
/*
--- External products
*/
import React, {useLayoutEffect, useState} from 'react';
import {
    AppBar,
    Avatar,
    Box,
    Button,
    Card,
    CardActionArea,
    CardContent,
    Container,
    createTheme,
    CssBaseline,
    Grid, IconButton,
    Paper,
    ThemeProvider, Tooltip,
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    CircularProgress
} from "@mui/material";
import ViewTimelineIcon from '@mui/icons-material/ViewTimeline';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import {enUS, frFR} from "@mui/x-data-grid";
import FindReplaceIcon from '@mui/icons-material/FindReplace';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';

/*
--- Digitech products
*/
import {createRoot} from "react-dom/client";
import {FrontloginJSX} from "./frontlogin";
import {frontmsgLogoutRequest_f, frontmsgState_e} from "./frontmsg";
import {FrontlocaleSwitcherJSX} from "./frontlocale";
import {FrontticketslistJSX} from "./frontticketslist";
import {FrontticketdetailJSX} from "./frontticketdetail";
import {FrontticketcreationJSX} from "./frontticketcreation";
import {FrontpasswordchangeJSX} from "./frontpasswordchange";
import {backinterfaceUserType} from "./diginesback/backinterface";
import {FrontclientsetJS} from "./frontclientset";
import {FrontfaqdocJSX, frontfaqdocType_e} from "./frontfaqdoc";
import {FrontadminmenuJSX} from "./frontadminmenu";
import {FrontadminfaqdocJSX} from "./frontadmifaqdoc";
import {FrontadminuploadJSX} from "./frontadminupload";
import {FrontcontactsetJS} from "./frontcontactset";
import {FrontreportsHealthCheckJSX} from "./frontreports";
import {FrontadmindownloadJSX} from "./frontadmindownload";
import {FrontadminpasswordJS} from "./frontadminpassword";

/*
--- Digitech resources
*/
import logoDigitech from "./images/logoDigitech.png";
import backgroundDigitech from "./images/backgroundDigitech.jpg";
import footerDigitech from "./images/footerDigitech.jpg";
import pictoLinkedin from "./images/picto_linkedin.png";
import pictoTwitter from "./images/picto_twitter.png";
import pictoYoutube from "./images/picto_youtube.png";


/*=============== Exported objects =============================*/
export const frontmainModal_e = {
    noModal: 0,
    loginModal: 1,
    ticketsListModal: 2,
    ticketDetails: 3,
    ticketCreationModal: 4,
    supportContactsModal: 7,
    passwordChangeModal: 8,
    clientSetModal: 9,
    contactSetModal: 10,
    faqModal: 11,
    docModal: 12,
    adminMenuModal: 13,
    adminFaqModal: 14,
    adminDocModal: 15,
    adminUploadModal: 16,
    reportHealthCheck: 17,
    adminLogModal: 18,
    adminPasswordModal: 19
}

/*=============== Local functions ==============================*/

/*+-------------------------------------------------------------+
  ! Routine    : locChangeClient_f                              !
  ! Description: Handle the Change Client Button                !
  !                                                             !
  ! IN:  - Context                                              !
  !      - Event                                                !
  ! OUT: - Nothing                                              !
  +-------------------------------------------------------------+
*/
function locChangeClient_f(paramCtx, paramEvent) {
    /*
    --- Start processing
    */
    const locUser_o = paramCtx.user_o;
    paramEvent.preventDefault();
    /*
    --- Check if it is a contact with several clients
    */
    if (locUser_o.type === backinterfaceUserType.contact) {
        /*
        --- More than one Client attached to the User: Open the Contact set dialog
        */
        locUser_o.clientId = "";
        locUser_o.clientName = "";
        paramCtx.currentModal = frontmainModal_e.contactSetModal;
        paramCtx.modalContactSet.isDisplayed = true;
        paramCtx.modalContactSet.msgState = frontmsgState_e.idle;
    } else {
        /*
        --- Let choose another Client for non-contact user
        */
        paramCtx.currentModal = frontmainModal_e.clientSetModal;
        paramCtx.modalClientSet.isDisplayed = true;
        paramCtx.modalClientSet.msgGetClientsListState = frontmsgState_e.idle;
        paramCtx.modalClientSet.msgPutClientSetState = frontmsgState_e.idle;
    }
    /*
    --- Refresh the main page
    */
    frontmainRefreshPage_f(paramCtx);
}


/*=============== Local JSX components =========================*/

/*+-------------------------------------------------------------+
  ! Routine    : LocJSXHeader                                   !
  ! Description: JSX Main Page Header, local definition         !
  !                                                             !
  ! IN:  - Properties including Context                         !
  ! OUT: - Page rendering                                       !
  +-------------------------------------------------------------+
*/
function LocJSXHeader(paramProps) {
    /*
    --- Initialisation
    */
    const locCtx = paramProps.ctx;
    const locTrans = locCtx.trans_o;
    const locColors = locCtx.config_o.colors;
    const locUser_o = locCtx.user_o;
    /*
    --- For User other than Contact, define a Client Change Button
    */
    const locClientButton = ((locUser_o.type === backinterfaceUserType.contact) &&
        (locCtx.modalContactSet.contactsList_a.length < 2)) ?
        (<></>) : (
            <Tooltip title={locTrans.comtransGet_m("main", "changeClient")} arrow>
                <IconButton color="primary"
                            onClick={(paramEvent) => locChangeClient_f(locCtx, paramEvent)}>
                    <FindReplaceIcon/>
                </IconButton>
            </Tooltip>
        );
    /*
    --- Define fields according if the User is connected
    */
    let locLogoutButton = (<></>);
    let locNameClient = (<Typography variant="h6" component="div">&nbsp;</Typography>);
    if (locCtx.user_o.connection === frontmsgState_e.ok) {
        locLogoutButton = (<Button
            onClick={() => {
                frontmsgLogoutRequest_f(locCtx);
            }}
            sx={{gridArea: "footer3"}}>
            {locTrans.comtransGet_m("main", "signout")}
        </Button>);
        locNameClient = (<Typography variant="h6" component="div" sx={{textAlign: "center"}}>
            {locCtx.user_o.firstName + " " + locUser_o.lastName + " - " + locUser_o.clientName}
            {locClientButton}
        </Typography>);
    }
    /*
    --- Create the Main page Header
    */
    return (<AppBar position="static" sx={{
        bgcolor: locColors.backgroundHeader, color: locColors.foregroundHeader,
        minHeight: "112px"
    }}>
        <Container maxWidth="xl">
            <Grid container spacing={2} direction="row" justifyContent="space-between">
                <Grid item xs={2} sx={{mt: 2, alignItems: 'left'}}>
                    <a href={locCtx.config_o.urlDigitechGroup} rel="noreferrer" target="_blank">
                        <img src={logoDigitech} alt="Digitech"/>
                    </a>
                </Grid>
                <Grid item xs={8} sx={{mt: 2, textAlign: "center"}}>
                    <Typography variant="h3" component="div">
                        {locTrans.comtransGet_m("main", "title")}
                    </Typography>
                </Grid>
                <Grid item xs={2} sx={{mt: 2, textAlign: 'right'}}>
                    <FrontlocaleSwitcherJSX ctx={locCtx}/>
                </Grid>
            </Grid>
            <Grid container spacing={2} direction="row" justifyContent="space-between" sx={{pt: "0px"}}>
                <Grid item xs={2} sx={{pt: "0px !important"}}></Grid>
                <Grid item xs={8} sx={{pt: "0px !important", textAlign: "center"}}>
                    {locNameClient}
                </Grid>
                <Grid item xs={2} sx={{pt: "0px !important", textAlign: 'right'}}>
                    {locLogoutButton}
                </Grid>
            </Grid>
        </Container>
    </AppBar>);
}

/*+-------------------------------------------------------------+
  ! Routine    : LocJSXFooter                                   !
  ! Description: JSX Main Page Footer, local definition         !
  !                                                             !
  ! IN:  - Properties including Context                         !
  ! OUT: - Page rendering                                       !
  +-------------------------------------------------------------+
*/
function LocJSXFooter(paramProps) {
    /*
    --- Initialisation
    */
    const locCtx = paramProps.ctx;
    const locTrans = locCtx.trans_o;
    const locColors = locCtx.config_o.colors;
    /*
    --- By default URLs are for Digitech France
    */
    const locUrl_o = {
        webSite: locCtx.config_o.urlDigitechFrance,
        twitter: locCtx.config_o.urlTwitterFrance,
        linkedin: locCtx.config_o.urlLinkedinFrance,
        youtube: locCtx.config_o.urlYoutubeFrance,
        addressName: locCtx.config_o.addressNameFrance,
        addressWay: locCtx.config_o.addressWayFrance,
        addressCity: locCtx.config_o.addressCityFrance,
        maps: locCtx.config_o.urlMapsFrance
    };
    /*
    --- For Swiss, set specific URLs
     */
    if (locCtx.user_o.clientType === locCtx.config_o.clientTypeSwiss) {
        locUrl_o.webSite = locCtx.config_o.urlDigitechSwiss;
        locUrl_o.twitter = locCtx.config_o.urlTwitterSwiss;
        locUrl_o.linkedin = locCtx.config_o.urlLinkedinSwiss;
        locUrl_o.youtube = locCtx.config_o.urlYoutubeSwiss;
        locUrl_o.addressName = locCtx.config_o.addressNameSwiss;
        locUrl_o.addressWay = locCtx.config_o.addressWaySwiss;
        locUrl_o.addressCity = locCtx.config_o.addressCitySwiss;
        locUrl_o.maps = locCtx.config_o.urlMapsSwiss;
    }
    /*
    --- Create the Main page Header
    */
    return (<Paper style={{backgroundImage: `url(${footerDigitech})`}}>
            <Container maxWidth="xl" sx={{pl: "64px !important", pt: "24px", pb: "24px", minHeight: "112px"}}>
                <Grid container spacing={2} direction="row" justifyContent="space-around">
                    <Grid item xs={4} container spacing={2} direction="row" justifyContent="left">
                        <Grid item>
                            <a href={locUrl_o.maps} rel="noreferrer" target="_blank">
                                <Avatar sx={{bgcolor: locColors.backgroundIcon, color: locColors.foregroundIcon}}>
                                    <LocationOnIcon/>
                                </Avatar>
                            </a>
                        </Grid>
                        <Grid item>
                            <a href={locUrl_o.maps} rel="noreferrer" target="_blank">
                                <Typography variant="body2" color={locColors.foregroundMain}>
                                    {locUrl_o.addressName}<br/>
                                    {locUrl_o.addressWay}<br/>
                                    {locUrl_o.addressCity}
                                </Typography>
                            </a>
                        </Grid>
                    </Grid>
                    <Grid item xs={4} sx={{textAlign: "center"}}>
                        <Typography variant="body2" color={locColors.foregroundMain}>
                            {locTrans.comtransGet_m("main", "cookies")}
                        </Typography>
                        <br/>
                        <Typography variant="body2" color={locColors.backgroundMainGrey}>
                            {"v" + locCtx.config_o.version}
                        </Typography>
                    </Grid>
                    <Grid item xs={4} sx={{textAlign: "right", pr: "32px"}}>
                        <a href={locUrl_o.twitter} target="_blank" rel="noreferrer">
                            <img src={pictoTwitter} alt="Twitter"/>
                        </a>
                        <a href={locUrl_o.linkedin} target="_blank" rel="noreferrer">
                            <img src={pictoLinkedin} alt="Linkedin"/>
                        </a>
                        <a href={locUrl_o.youtube} target="_blank"
                           rel="noreferrer">
                            <img src={pictoYoutube} alt="Youtube"/>
                        </a>
                        <a href={locUrl_o.webSite} target="_blank" rel="noreferrer">
                            <Typography variant="body2" color={locColors.foregroundMain}>
                                {locUrl_o.webSite}
                            </Typography>
                        </a>
                    </Grid>
                </Grid>
            </Container>
        </Paper>
    );
}

/*
+-------------------------------------------------------------+
! Routine    : LocJSXTicketsList                              !
! Description: JSX Main Page Tickets list Card                !
!                                                             !
! IN:  - Properties including Context                         !
! OUT: - Page rendering                                       !
+-------------------------------------------------------------+
*/
function LocJSXTicketsList(paramProps) {
    /*
    --- Initialisation
    */
    const locCtx = paramProps.ctx;
    const locTrans = locCtx.trans_o;
    const locColors = locCtx.config_o.colors;
    /*
    --- Create the Main page Contents
    */
    return (<Card sx={{width: 400, height: 200, 'border-radius': '20px', opacity: '0.82', "&:hover": {opacity: '1'}}}>
        <CardActionArea
            sx={{width: "100%", height: "100%", display: 'flex', flexDirection: 'row'}}
            onClick={() => {
                locCtx.currentModal = frontmainModal_e.ticketsListModal;
                frontmainRefreshPage_f(locCtx);
            }}>
            <CardContent sx={{width: "100%", height: "100%"}}>
                <Box sx={{mb: 2, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <Avatar sx={{bgcolor: locColors.backgroundIcon, color: locColors.foregroundIcon}}>
                        <ViewTimelineIcon/>
                    </Avatar>
                </Box>
                <Box sx={{mb: 2, display: 'flex', flexDirection: 'column', textAlign: 'center'}}>
                    <Typography gutterBottom variant="h5" component="div">
                        {locTrans.comtransGet_m("ticketsList", "title")}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {locTrans.comtransGet_m("ticketsList", "description")}
                    </Typography>
                </Box>
            </CardContent>
        </CardActionArea>
    </Card>);
}

/*
+-------------------------------------------------------------+
! Routine    : LocJSXTicketCreation                           !
! Description: JSX Main Page Ticket Creation Card             !
!                                                             !
! IN:  - Properties including Context                         !
! OUT: - Page rendering                                       !
+-------------------------------------------------------------+
*/
function LocJSXTicketCreation(paramProps) {
    /*
    --- Initialisation
    */
    const locCtx = paramProps.ctx;
    const locTrans = locCtx.trans_o;
    const locColors = locCtx.config_o.colors;
    /*
    --- Don't display the Card if the User is a Reader only
    */
    if (locCtx.user_o.type === backinterfaceUserType.reader) return (<></>);
    /*
    --- Create the Main page Contents
    */
    return (<Card sx={{width: 400, height: 200, 'border-radius': '20px', opacity: '0.82', "&:hover": {opacity: '1'}}}>
        <CardActionArea
            sx={{width: "100%", height: "100%", display: "flex", flexDirection: "row"}}
            onClick={() => {
                locCtx.currentModal = frontmainModal_e.ticketCreationModal;
                frontmainRefreshPage_f(locCtx);
            }}>
            <CardContent sx={{width: "100%", height: "100%"}}>
                <Box sx={{mb: 2, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <Avatar sx={{bgcolor: locColors.backgroundIcon, color: locColors.foregroundIcon}}>
                        <ConfirmationNumberIcon/>
                    </Avatar>
                </Box>
                <Box sx={{mb: 2, display: 'flex', flexDirection: 'column', textAlign: 'center'}}>
                    <Typography gutterBottom variant="h5" component="div">
                        {locTrans.comtransGet_m("ticketCreation", "title")}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {locTrans.comtransGet_m("ticketCreation", "description")}
                    </Typography>
                </Box>
            </CardContent>
        </CardActionArea>
    </Card>);
}

/*
+-------------------------------------------------------------+
! Routine    : LocJSXFaq                                      !
! Description: JSX Main Page FAQ Card                         !
!                                                             !
! IN:  - Properties including Context                         !
! OUT: - Page rendering                                       !
+-------------------------------------------------------------+
*/
function LocJSXFaq(paramProps) {
    /*
    --- Initialisation
    */
    const locCtx = paramProps.ctx;
    const locTrans = locCtx.trans_o;
    const locColors = locCtx.config_o.colors;
    /*
    --- Create the Main page Contents
    */
    return (<Card sx={{width: 400, height: 200, 'border-radius': '20px', opacity: '0.82', "&:hover": {opacity: '1'}}}>
        <CardActionArea
            sx={{width: "100%", height: "100%", display: 'flex', flexDirection: 'row'}}
            onClick={() => {
                locCtx.currentModal = frontmainModal_e.faqModal;
                frontmainRefreshPage_f(locCtx);
            }}>
            <CardContent sx={{width: "100%", height: "100%"}}>
                <Box sx={{mb: 2, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <Avatar sx={{bgcolor: locColors.backgroundIcon, color: locColors.foregroundIcon}}>
                        <LiveHelpIcon/>
                    </Avatar>
                </Box>
                <Box sx={{mb: 2, display: 'flex', flexDirection: 'column', textAlign: 'center'}}>
                    <Typography gutterBottom variant="h5" component="div">
                        {locTrans.comtransGet_m("faq", "title")}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {locTrans.comtransGet_m("faq", "description")}
                    </Typography>
                </Box>
            </CardContent>
        </CardActionArea>
    </Card>);
}

/*
+-------------------------------------------------------------+
! Routine    : LocJSXDoc                                      !
! Description: JSX Main Page Documentation Card               !
!                                                             !
! IN:  - Properties including Context                         !
! OUT: - Page rendering                                       !
+-------------------------------------------------------------+
*/
function LocJSXDoc(paramProps) {
    /*
    --- Initialisation
    */
    const locCtx = paramProps.ctx;
    const locTrans = locCtx.trans_o;
    const locColors = locCtx.config_o.colors;
    /*
    --- Create the Main page Contents
    */
    return (<Card sx={{width: 400, height: 200, 'border-radius': '20px', opacity: '0.82', "&:hover": {opacity: '1'}}}>
        <CardActionArea
            sx={{width: "100%", height: "100%", display: 'flex', flexDirection: 'row'}}
            onClick={() => {
                locCtx.currentModal = frontmainModal_e.docModal;
                frontmainRefreshPage_f(locCtx);
            }}>
            <CardContent sx={{width: "100%", height: "100%"}}>
                <Box sx={{mb: 2, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <Avatar sx={{bgcolor: locColors.backgroundIcon, color: locColors.foregroundIcon}}>
                        <MenuBookIcon/>
                    </Avatar>
                </Box>
                <Box sx={{mb: 2, display: 'flex', flexDirection: 'column', textAlign: 'center'}}>
                    <Typography gutterBottom variant="h5" component="div">
                        {locTrans.comtransGet_m("doc", "title")}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {locTrans.comtransGet_m("doc", "description")}
                    </Typography>
                </Box>
            </CardContent>
        </CardActionArea>
    </Card>);
}

/*
+-------------------------------------------------------------+
! Routine    : LocJSXSupportContacts                          !
! Description: JSX Main Page Contact Information Card         !
!                                                             !
! IN:  - Properties including Context                         !
! OUT: - Page rendering                                       !
+-------------------------------------------------------------+
*/
function LocJSXSupportContacts(paramProps) {
    /*
    --- Initialisation
    */
    const locCtx = paramProps.ctx;
    const locTrans = locCtx.trans_o;
    const locColors = locCtx.config_o.colors;
    /*
    --- Don't display the Card if the User is a Reader only
    */
    if (locCtx.user_o.type === backinterfaceUserType.reader) return (<></>);
    /*
    --- Create the Main page Contents
    */
    return (<Card sx={{width: 400, height: 200, 'border-radius': '20px', opacity: '0.82', "&:hover": {opacity: '1'}}}>
        <CardActionArea
            sx={{width: "100%", height: "100%", display: 'flex', flexDirection: 'row'}}
            href="https://www.digitech.fr/digitech/cms/7190/support-clients.dhtml"
            target="_blank" rel="noopener noreferrer">
            <CardContent sx={{width: "100%", height: "100%"}}>
                <Box sx={{mb: 2, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <Avatar sx={{bgcolor: locColors.backgroundIcon, color: locColors.foregroundIcon}}>
                        <ContactPhoneIcon/>
                    </Avatar>
                </Box>
                <Box sx={{mb: 2, display: 'flex', flexDirection: 'column', textAlign: 'center'}}>
                    <Typography gutterBottom variant="h5" component="div">
                        {locTrans.comtransGet_m("supportContacts", "title")}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {locTrans.comtransGet_m("supportContacts", "description")}
                    </Typography>
                </Box>
            </CardContent>
        </CardActionArea>
    </Card>);
}

/*
+-------------------------------------------------------------+
! Routine    : LocJSXPasswordChange                           !
! Description: JSX Main Page Password Change Card             !
!                                                             !
! IN:  - Properties including Context                         !
! OUT: - Page rendering                                       !
+-------------------------------------------------------------+
*/
function LocJSXPasswordChange(paramProps) {
    /*
    --- Initialisation
    */
    const locCtx = paramProps.ctx;
    const locTrans = locCtx.trans_o;
    const locColors = locCtx.config_o.colors;
    /*
    --- Don't display the Card if the User is not a Contact
    */
    if (locCtx.user_o.type !== backinterfaceUserType.contact) return (<></>);
    /*
    --- Create the Password Change Card (disabled for any non-contact user)
    */
    return (<Card sx={{width: 400, height: 200, 'border-radius': '20px', opacity: '0.82', "&:hover": {opacity: '1'}}}>
        <CardActionArea
            sx={{width: "100%", height: "100%", display: 'flex', flexDirection: 'row'}}
            onClick={() => {
                locCtx.currentModal = frontmainModal_e.passwordChangeModal;
                /*
                --- Reset Checking Fields
                */
                locCtx.modalPasswordChange.oldPasswordCheckedOk = true;
                locCtx.modalPasswordChange.newPasswordCheckedOk = true;
                frontmainRefreshPage_f(locCtx);
            }}>
            <CardContent sx={{width: "100%", height: "100%"}}>
                <Box sx={{mb: 2, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <Avatar sx={{bgcolor: locColors.backgroundIcon, color: locColors.foregroundIcon}}>
                        <ManageAccountsIcon/>
                    </Avatar>
                </Box>
                <Box sx={{mb: 2, display: 'flex', flexDirection: 'column', textAlign: 'center'}}>
                    <Typography gutterBottom variant="h5" component="div">
                        {locTrans.comtransGet_m("passwordChange", "title")}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {locTrans.comtransGet_m("passwordChange", "description")}
                    </Typography>
                </Box>
            </CardContent>
        </CardActionArea>
    </Card>);
}

/*
+-------------------------------------------------------------+
! Routine    : LocJSXAdminMenu                                !
! Description: JSX Main Page Admin Menu Card                  !
!                                                             !
! IN:  - Properties including Context                         !
! OUT: - Page rendering                                       !
+-------------------------------------------------------------+
*/
function LocJSXAdminMenu(paramProps) {
    /*
    --- Initialisation
    */
    const locCtx = paramProps.ctx;
    const locTrans = locCtx.trans_o;
    const locColors = locCtx.config_o.colors;
    /*
    --- Display the Card only if the User is Admin
    */
    if (locCtx.user_o.type !== backinterfaceUserType.admin) return (<></>);
    /*
    --- Create the Admin Menu Card (disabled for any non-admin user)
    */
    return (<Card sx={{width: 400, height: 200, 'border-radius': '20px', opacity: '0.82', "&:hover": {opacity: '1'}}}>
        <CardActionArea
            sx={{width: "100%", height: "100%", display: 'flex', flexDirection: 'row'}}
            onClick={() => {
                locCtx.currentModal = frontmainModal_e.adminMenuModal;
                frontmainRefreshPage_f(locCtx);
            }}>
            <CardContent sx={{width: "100%", height: "100%"}}>
                <Box sx={{mb: 2, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <Avatar sx={{bgcolor: locColors.backgroundIcon, color: locColors.foregroundIcon}}>
                        <AdminPanelSettingsIcon/>
                    </Avatar>
                </Box>
                <Box sx={{mb: 2, display: 'flex', flexDirection: 'column', textAlign: 'center'}}>
                    <Typography gutterBottom variant="h5" component="div">
                        {locTrans.comtransGet_m("adminMenu", "title")}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {locTrans.comtransGet_m("adminMenu", "description")}
                    </Typography>
                </Box>
            </CardContent>
        </CardActionArea>
    </Card>);
}

/*
+-------------------------------------------------------------+
! Routine    : LocJSXContents                                 !
! Description: JSX Main Page Contents, local definition       !
!                                                             !
! IN:  - Properties including Context                         !
! OUT: - Page rendering                                       !
+-------------------------------------------------------------+
*/
function LocJSXContents(paramProps) {
    /*
    --- Initialisation
    */
    const locCtx = paramProps.ctx;
    /*
    --- Compute the height according the total Window height
    */
    const locHeight = locCtx.window_o.height - 280;
    /*
    --- Create the Main page Contents according the width of the window
    */
    const locWidth = locCtx.window_o.width;
    if ((locWidth > 1293) && (locHeight > 432)) {
        /*
        --- Set 3 tiles by row
        */
        return (
            <Container maxWidth="xl" sx={{mt: 6, mb: 4, height: locHeight}}>
                <Grid container spacing={4} direction="column" justifyContent="space-evenly"
                      sx={{textAlign: "-webkit-center"}}>
                    <Grid container item spacing={2} justifyContent="space-around">
                        <Grid item xs>
                            <LocJSXTicketsList ctx={locCtx}/>
                        </Grid>
                        <Grid item xs>
                            <LocJSXTicketCreation ctx={locCtx}/>
                        </Grid>
                        <Grid item xs>
                            <LocJSXSupportContacts ctx={locCtx}/>
                        </Grid>
                    </Grid>
                    <Grid container item spacing={2} justifyContent="space-evenly" sx={{pb: "24px"}}>
                        <Grid item xs>
                            <LocJSXFaq ctx={locCtx}/>
                        </Grid>
                        <Grid item xs>
                            <LocJSXDoc ctx={locCtx}/>
                        </Grid>
                        <Grid item xs>
                            <LocJSXPasswordChange ctx={locCtx}/>
                            <LocJSXAdminMenu ctx={locCtx}/>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        );
    } else if (locWidth > 880) {
        /*
        --- Set 2 tiles by row
        */
        return (
            <Container maxWidth="xl" sx={{mt: 6, mb: 4, minHeight: locHeight}}>
                <Grid container spacing={4} direction="column" justifyContent="space-evenly"
                      sx={{textAlign: "-webkit-center"}}>
                    <Grid container item spacing={2} justifyContent="space-around">
                        <Grid item xs>
                            <LocJSXTicketsList ctx={locCtx}/>
                        </Grid>
                        <Grid item xs>
                            <LocJSXTicketCreation ctx={locCtx}/>
                        </Grid>
                    </Grid>
                    <Grid container item spacing={2} justifyContent="space-around">
                        <Grid item xs>
                            <LocJSXSupportContacts ctx={locCtx}/>
                        </Grid>
                        <Grid item xs>
                            <LocJSXFaq ctx={locCtx}/>
                        </Grid>
                    </Grid>
                    <Grid container item spacing={2} justifyContent="space-evenly" sx={{pb: "24px"}}>
                        <Grid item xs>
                            <LocJSXDoc ctx={locCtx}/>
                        </Grid>
                        <Grid item xs>
                            <LocJSXPasswordChange ctx={locCtx}/>
                            <LocJSXAdminMenu ctx={locCtx}/>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        );
    } else {
        /*
        --- Set 1 tiles by row
        */
        return (
            <Container maxWidth="xl" sx={{mt: 6, mb: 4, minHeight: locHeight}}>
                <Grid container spacing={4} direction="column" justifyContent="space-evenly"
                      sx={{textAlign: "-webkit-center"}}>
                    <Grid item xs>
                        <LocJSXTicketsList ctx={locCtx}/>
                    </Grid>
                    <Grid item xs>
                        <LocJSXTicketCreation ctx={locCtx}/>
                    </Grid>
                    <Grid item xs>
                        <LocJSXSupportContacts ctx={locCtx}/>
                    </Grid>
                    <Grid item xs>
                        <LocJSXFaq ctx={locCtx}/>
                    </Grid>
                    <Grid item xs>
                        <LocJSXDoc ctx={locCtx}/>
                    </Grid>
                    <Grid item xs>
                        <LocJSXPasswordChange ctx={locCtx}/>
                        <LocJSXAdminMenu ctx={locCtx}/>
                    </Grid>
                </Grid>
            </Container>
        );
    }

}

/*+-------------------------------------------------------------+
  ! Routine    : LocJSXWaiting                                  !
  ! Description: JSX User Waiting modal, local definition       !
  !                                                             !
  ! IN:  - Properties including Context                         !
  ! OUT: - Page rendering                                       !
  +-------------------------------------------------------------+
*/
function LocJSXWaiting(paramProps) {
    /*
    --- Initialisation
    */
    const locTrans = paramProps.ctx.trans_o;

    /*
    --- Return Login Modal
    */
    return (
        <Dialog open="true">
            <DialogTitle>
                {locTrans.comtransGet_m("login", "signIn")}
            </DialogTitle>
            <DialogContent>
                <Box
                    sx={{
                        marginTop: 1, display: 'flex', flexDirection: 'column', alignItems: 'center',
                    }}
                >
                    <Box sx={{mt: 1}}>
                        <CircularProgress/>
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>);
}

/*
+-------------------------------------------------------------+
! Routine    : LocJSXStartModal                               !
! Description: JSX Start Modal if required                    !
!                                                             !
! IN:  - Properties including Context                         !
! OUT: - Page rendering                                       !
+-------------------------------------------------------------+
*/
function LocJSXStartModal(paramProps) {
    /*
    --- Initialisation
    */
    const locCtx = paramProps.ctx;
    /*
    --- Check if a Modal should be started
    */
    switch (locCtx.currentModal) {
        case frontmainModal_e.noModal:
            /*
            --- Check if Waiting for Client Type
            */
            if (locCtx.pageMain.msgState !== frontmsgState_e.ok) {
                return (<LocJSXWaiting ctx={locCtx}/>);
            } else {
                return (<></>);
            }
        case frontmainModal_e.loginModal:
            return (<FrontloginJSX ctx={locCtx}/>);
        case frontmainModal_e.clientSetModal:
            return (<FrontclientsetJS ctx={locCtx} parentModal={frontmainModal_e.noModal}/>);
        case frontmainModal_e.contactSetModal:
            return (<FrontcontactsetJS ctx={locCtx} parentModal={frontmainModal_e.noModal}/>);
        case frontmainModal_e.ticketsListModal:
            return (<FrontticketslistJSX ctx={locCtx}/>);
        case frontmainModal_e.ticketDetails:
            return (<FrontticketdetailJSX ctx={locCtx}/>);
        case frontmainModal_e.ticketCreationModal:
            return (<FrontticketcreationJSX ctx={locCtx}/>);
        case frontmainModal_e.passwordChangeModal:
            return (<FrontpasswordchangeJSX ctx={locCtx}/>);
        case frontmainModal_e.faqModal:
            return (<FrontfaqdocJSX ctx={locCtx} type={frontfaqdocType_e.faq}/>);
        case frontmainModal_e.docModal:
            return (<FrontfaqdocJSX ctx={locCtx} type={frontfaqdocType_e.doc}/>);
        case frontmainModal_e.adminMenuModal:
            return (<FrontadminmenuJSX ctx={locCtx}/>);
        case frontmainModal_e.adminFaqModal:
            return (<FrontadminfaqdocJSX ctx={locCtx} type={frontfaqdocType_e.faq}/>);
        case frontmainModal_e.adminDocModal:
            return (<FrontadminfaqdocJSX ctx={locCtx} type={frontfaqdocType_e.doc}/>);
        case frontmainModal_e.adminUploadModal:
            return (<FrontadminuploadJSX ctx={locCtx}/>);
        case frontmainModal_e.reportHealthCheck:
            return (<FrontreportsHealthCheckJSX ctx={locCtx}/>);
        case frontmainModal_e.adminLogModal:
            return (<FrontadmindownloadJSX ctx={locCtx}/>);
        case frontmainModal_e.adminPasswordModal:
            return (<FrontadminpasswordJS ctx={locCtx}/>);
        default:
            return (<></>);
    }
}

/*+-----------------------------------------------------------+
! Routine    : LocJSXMain                                     !
! Description: JSX Main page                                  !
!                                                             !
! IN:  - Properties including Context                         !
! OUT: - Page rendering                                       !
+-------------------------------------------------------------+
*/
function LocJSXMain(paramProps) {
    /*
    --- Initialisation
    */
    const locCtx = paramProps.ctx;
    const locColors = locCtx.config_o.colors;
    /*
    --- Reset any current Error
    */
    locCtx.error_o.inError = false;
    /*
    --- Get React state for refreshing the page
    */
    const [locMain_s, locMain_f] = React.useState(false);
    locCtx.refresh_o.main_f = locMain_f;
    locCtx.refresh_o.main_s = locMain_s;
    /*
    --- Set hook for Window resizing
    */
    [locCtx.window_o.width, locCtx.window_o.height] = FrontmainWindowResize_f();
    /*
    --- Search the theme locale
    */
    let locLocale_o = frFR;
    if (locCtx.config_o.locale === "en-GB") locLocale_o = enUS;
    /*
    --- The main page is disabled until the user is connected and Client type is got
    */
    let locMainContainer;
    let locBackGroundColor;
    if ((locCtx.user_o.connection === frontmsgState_e.ok) && (locCtx.pageMain.msgState === frontmsgState_e.ok)) {
        /*
        --- Enabled main page
        */
        locMainContainer = (
            <div>
                <LocJSXHeader ctx={locCtx}/>
                <LocJSXContents ctx={locCtx}/>
                <LocJSXFooter ctx={locCtx}/>
            </div>);
        locBackGroundColor = locColors.backgroundMainBlack;
    } else {
        /*
        --- Disabled main page
        */
        locMainContainer = (
            <div>
                <LocJSXHeader ctx={locCtx}/>
            </div>);
        locBackGroundColor = locColors.backgroundMainGrey;
    }
    /*
    --- Create the Theme
    */
    const locTheme = createTheme({
        palette: {
            background: {
                default: locBackGroundColor
            }
        }
    }, locLocale_o);
    /*
    --- Set a Background image according the window width
    */
    const locStyle = (locCtx.window_o.width > 880) ? {backgroundImage: `url(${backgroundDigitech})`} :
        {backgroundColor: locColors.backgroundMainGrey};
    /*
    --- Return the Main page
    */
    return (<ThemeProvider theme={locTheme}>
        <CssBaseline/>
        <Paper style={locStyle}>
            {locMainContainer}
            <LocJSXStartModal ctx={locCtx}/>
        </Paper>
    </ThemeProvider>);
}

/*=============== Exported functions ===========================*/

/*+-------------------------------------------------------------+
! Routine    : frontmain_f                                    !
! Description: Main Page function                             !
!                                                             !
! IN:  - Context                                              !
! OUT: - Main page rendering                                  !
+-------------------------------------------------------------+
*/
export function frontmain_f(paramCtx) {
    /*
    --- Render the Main page
    */
    const locRoot = createRoot(document.getElementById('root'));
    locRoot.render(<React.StrictMode>
        <LocJSXMain ctx={paramCtx}/>
    </React.StrictMode>);
}

/*+-------------------------------------------------------------+
! Routine    : frontmainRefreshPage_f                         !
! Description: Request the refresh of the Login Modal         !
!                                                             !
! IN:  - Context                                              !
! OUT: - Nothing                                              !
+-------------------------------------------------------------+
*/
export function frontmainRefreshPage_f(paramCtx) {
    paramCtx.refresh_o.main_s = !paramCtx.refresh_o.main_s;
    paramCtx.refresh_o.main_f(paramCtx.refresh_o.main_s);
}

/*+-------------------------------------------------------------+
! Routine    : frontmainWindowResize_f                        !
! Description: Manage hook for Window resizing                !
!                                                             !
! IN:  - Context                                              !
! OUT: - Nothing                                              !
+-------------------------------------------------------------+
*/
export const FrontmainWindowResize_f = () => {
    const [locWindowSize, locSetWindowSize_f] = useState([0, 0])
    const locUpdateWindowSize_f = () => {
        locSetWindowSize_f([window.innerWidth, window.innerHeight])
    }
    useLayoutEffect(() => {
        window.addEventListener('resize', locUpdateWindowSize_f);
        locUpdateWindowSize_f();
        return () => window.removeEventListener('resize', locUpdateWindowSize_f);
    }, [])
    return [locWindowSize[0], locWindowSize[1]]
}
