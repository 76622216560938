/*
  +-------------------------------------------------------------+
  !     CODE SOURCE MATERIALS                                   !
  !     DIGITECH CONFIDENTIAL                                   !
  !                                                             !
  !     DIGITECH Platform Code                                  !
  !     (C) COPYRIGHT DIGITECH 2022-2023                        !
  !     Licensed Internal Code - Property of DIGITECH           !
  +-------------------------------------------------------------+
  +-------------------------------------------------------------+
  !                                                             !
  !  File  : frontreports.js                                    !
  !  Desc. : Nodejs Digines Front Display Reports Modal         !
  !                                                             !
  !  Author: D.ESTEVE                                           !
  !  Modif.: 17/01/2023                                         !
  !                                                             !
  !  0.1: Creation                                              !
  +-------------------------------------------------------------+
*/
/*=============== Imports ======================================*/
/*
--- External products
*/
import React from 'react';
import {
    Avatar,
    Box,
    Button,
    Grid,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    IconButton
} from "@mui/material";
import {Close} from "@mui/icons-material";

/*
--- Digitech products
*/
import {frontmainModal_e, frontmainRefreshPage_f} from "./frontmain";
import {backinterfaceApi} from "./diginesback/backinterface";
import FactCheckIcon from '@mui/icons-material/FactCheck';

/*=============== Exported objects =============================*/
export const frontreportsType_e = {
    healthCheck: 0
}

/*=============== Local functions ==============================*/

/*+-------------------------------------------------------------+
  ! Routine    : locClose_f                                     !
  ! Description: Handle the Cancel Button                       !
  !                                                             !
  ! IN:  - Context                                              !
  !      - Event                                                !
  ! OUT: - Nothing                                              !
  +-------------------------------------------------------------+
*/
function locClose_f(paramCtx, paramEvent) {
    /*
    --- Stop Event
    */
    paramEvent.preventDefault();
    /*
    --- Close the Modal and return to Admin Menu
    */
    paramCtx.currentModal = frontmainModal_e.adminMenuModal;
    /*
    --- Refresh the main page
    */
    frontmainRefreshPage_f(paramCtx);
}

/*=============== Local JSX components =========================*/

/*
+-------------------------------------------------------------+
! Routine : LocJSXHealthCheckReport                           !
! Description: JSX Health Check Report modal, local definition!
!                                                             !
! IN: - Properties including Context                          !
! OUT: - Page rendering                                       !
+-------------------------------------------------------------+
*/
function LocJSXHealthCheckReport(paramProps) {
    /*
    --- Initialisation
    */
    const locCtx = paramProps.ctx;
    const locTrans = locCtx.trans_o;
    const locColors = locCtx.config_o.colors;
    /*
    --- Build the Health Check URI
    */
    const locHostname = locCtx.config_o.backServerHostname;
    const locPort = locCtx.config_o.backServerPort;
    let locUri = "https://" + locHostname + ":" + locPort + "/" + backinterfaceApi.getHealthCheck;
    /*
    --- Return Health Check Report Modal
    */
    return (
        <div>
            <DialogTitle>
                <Grid container direction="row" justifyContent="flex-start">
                    <Avatar sx={{bgcolor: locColors.backgroundIcon, color: locColors.foregroundIcon}}>
                        <FactCheckIcon/>
                    </Avatar>
                    <Box sx={{ml: 4, mt: 1}}>
                        {locTrans.comtransGet_m("adminMenu", "titleHealthCheck")}
                    </Box>
                </Grid>
                <IconButton
                    aria-label="close"
                    onClick={(paramEvent) => locClose_f(locCtx, paramEvent)}
                    sx={{position: 'absolute', right: 8, top: 8, color: locColors.backgroundIcon}}
                >
                    <Close/>
                </IconButton>
            </DialogTitle>
            <DialogContent sx={{pb: 0}}>
                <iframe
                    src={locUri}
                    title={locTrans.comtransGet_m("adminMenu", "titleHealthCheck")}
                    style={{width: '100%', minHeight: '450px'}}/>
            </DialogContent>
            <DialogActions>
                <Button onClick={(paramEvent) => locClose_f(locCtx, paramEvent)} sx={{mt: 0, mr: 2, pt: 0}}>
                    {locTrans.comtransGet_m("common", "return")}
                </Button>
            </DialogActions>
        </div>);
}

/*=============== Exported functions ===========================*/

/*=============== Exported JSX components ======================*/

/*
+-------------------------------------------------------------+
! Routine : FrontreportsHealthCheckJSX                        !
! Description: JSX Health Check Report Modal                  !
!                                                             !
! IN: - Properties including Context                          !
! OUT: - Page rendering                                       !
+-------------------------------------------------------------+
*/
export function FrontreportsHealthCheckJSX(paramProps) {
    /*
    --- Initialisation
    */
    const locCtx = paramProps.ctx;
    /*
    --- Return the Modal
    */
    return (<Dialog open={true} fullWidth maxWidth="xl">
        <LocJSXHealthCheckReport ctx={locCtx}/>
    </Dialog>);
}
