/*
  +-------------------------------------------------------------+
  !     CODE SOURCE MATERIALS                                   !
  !     DIGITECH CONFIDENTIAL                                   !
  !                                                             !
  !     DIGITECH Platform Code                                  !
  !     (C) COPYRIGHT DIGITECH 2022                             !
  !     Licensed Internal Code - Property of DIGITECH           !
  +-------------------------------------------------------------+
  +-------------------------------------------------------------+
  !                                                             !
  !  File  : frontticketcreation.js                             !
  !  Desc. : Nodejs Digines Front Ticket creation Modal         !
  !                                                             !
  !  Author: D.ESTEVE                                           !
  !  Modif.: 30/10/2022                                         !
  !                                                             !
  !  0.1: Creation                                              !
  +-------------------------------------------------------------+
*/
/*=============== Imports ======================================*/
/*
--- External products
*/
import React, {useRef} from 'react';
import {
    Box,
    Button,
    Grid,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    CircularProgress,
    Avatar,
    IconButton, TextField, InputLabel, Select, MenuItem, FormControl, Typography
} from "@mui/material";
import {Editor} from '@tinymce/tinymce-react';
import {Close} from "@mui/icons-material";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

/*
--- Digitech products
*/
import {
    frontmsgProductsListRequest_f,
    frontmsgState_e,
    frontmsgTicketCreationRequest_f, frontmsgTicketUpdateRequest_f
} from './frontmsg';
import {frontmainModal_e, frontmainRefreshPage_f} from "./frontmain";
import {FronterrorJSX} from "./fronterror";
import {frontticketaddfilesInitFields_f, FrontticketaddfilesJSX} from "./frontticketaddfiles";

/*=============== Local functions ==============================*/

/*+-------------------------------------------------------------+
  ! Routine    : locInitFields_f                                !
  ! Description: Initialise the Fields                          !
  !                                                             !
  ! IN:  - Context                                              !
  ! OUT: - Nothing                                              !
  +-------------------------------------------------------------+
*/
function locInitFields_f(paramCtx) {
    /*
    --- Reset the Ticket
    */
    paramCtx.modalTicketCreation.ticket = {
        id: "",
        reference: "",
        summary: "",
        product: "",
        type: "",
        severity: "",
        description: "",
        files: []
    };
    /*
    --- Reset the Files list
    */
    frontticketaddfilesInitFields_f(paramCtx, frontticketcreationRefreshModal_f,
        "", frontmsgTicketUpdateRequest_f);
}

/*+-------------------------------------------------------------+
  ! Routine    : locSubmit_f                                    !
  ! Description: Handle the Submit Button                       !
  !                                                             !
  ! IN:  - Context                                              !
  !      - Event                                                !
  ! OUT: - Nothing                                              !
  +-------------------------------------------------------------+
*/
function locSubmit_f(paramCtx, paramEvent) {
    /*
    --- Start Submit processing
    */
    paramEvent.preventDefault();
    /*
    --- Request Ticket Creation
    */
    frontmsgTicketCreationRequest_f(paramCtx);
    /*
    --- Refresh the Ticket Creation Modal
    */
    frontticketcreationRefreshModal_f(paramCtx);
}

/*
  +-------------------------------------------------------------+
  ! Routine    : locClose_f                                     !
  ! Description: Handle the Cancel Button                       !
  !                                                             !
  ! IN:  - Context                                              !
  !      - Event                                                !
  ! OUT: - Nothing                                              !
  +-------------------------------------------------------------+
*/
function locClose_f(paramCtx, paramEvent) {
    /*
    --- Stop Event
    */
    paramEvent.preventDefault();
    /*
    --- Close the Modal
    */
    paramCtx.currentModal = frontmainModal_e.noModal;
    /*
    --- Reset the message states
    */
    paramCtx.modalTicketCreation.msgProductListState = frontmsgState_e.idle;
    paramCtx.modalTicketCreation.msgTicketCreationState = frontmsgState_e.idle;
    paramCtx.error_o.inError = false;
    /*
    --- Refresh the main page
    */
    frontmainRefreshPage_f(paramCtx);
}

/*=============== Local JSX components =========================*/

/*
  +-------------------------------------------------------------+
  ! Routine    : LocJSXTicketFields                             !
  ! Description: JSX Ticket Fields entry                        !
  !                                                             !
  ! IN:  - Properties including Context                         !
  ! OUT: - Page rendering                                       !
  +-------------------------------------------------------------+
*/
function LocJSXTicketFields(paramProps) {
    /*
    --- Initialisation
    */
    const locCtx = paramProps.ctx;
    const locTrans = locCtx.trans_o;
    const locEditorRef = useRef(null);
    /*
    --- Convert locale for Edit zone
    */
    const locLocale = (locCtx.config_o.locale === "fr-FR") ? "fr_FR" : "en";
    /*
    --- Build Product Select list
    */
    const locProductsList = locCtx.modalTicketCreation.productsList;
    const locProductsSelect = [];
    for (let locI = 0; locI < locProductsList.length; locI++) {
        const locIndex = "" + locI;
        locProductsSelect.push(
            <MenuItem value={locIndex}
                      onClick={() => {
                          locCtx.modalTicketCreation.ticket.product = locIndex;
                          frontticketcreationRefreshModal_f(locCtx);
                      }}>
                {locProductsList[locI]}
            </MenuItem>
        );
    }
    /*
    --- Build Ticket Type Select list
    */
    const locTypeSelect = [];
    for (let locI = 1; locI < 10; locI++) {
        const locIndex = "" + locI;
        const locType = locTrans.comtransGet_m("type", locIndex);
        if (locType.length > 0) locTypeSelect.push(
            <MenuItem value={locIndex}
                      onClick={() => {
                          locCtx.modalTicketCreation.ticket.type = locIndex;
                          frontticketcreationRefreshModal_f(locCtx);
                      }}>
                {locType}
            </MenuItem>);
    }

    /*
    --- Build Severity Select list
    */
    const locSeveritySelect = [];
    for (let locI = 2; locI < 5; locI++) {
        const locIndex = "" + locI;
        locSeveritySelect.push(
            <MenuItem value={locIndex}
                      onClick={() => {
                          locCtx.modalTicketCreation.ticket.severity = locIndex;
                          frontticketcreationRefreshModal_f(locCtx);
                      }}>
                {locTrans.comtransGet_m("severity", locIndex)}
            </MenuItem>
        );
    }
    /*
    --- Return Form Field for Ticket Creation
    */
    return (
        <Box component="form" noValidate sx={{mt: 1, mb: 0, pb: 0}}>
            <TextField
                margin="normal"
                required
                fullWidth
                id="summary"
                defaultValue={"" + locCtx.modalTicketCreation.ticket.summary}
                label={locTrans.comtransGet_m("ticketCreation", "summary")}
                name="summary"
                autoFocus
                onChange={(paramEvent) => {
                    locCtx.modalTicketCreation.ticket.summary = paramEvent.target.value.trim();
                    frontticketcreationRefreshModal_f(locCtx);
                }}
            />
            <Box sx={{mb: 1}}>
                <FormControl required sx={{mt: 1, mr: 1, mb: 1, minWidth: 140}}>
                    <InputLabel id="productLabel">{locTrans.comtransGet_m("ticketCreation", "product")}</InputLabel>
                    <Select
                        id="product"
                        labelId="productLabel"
                        value={locCtx.modalTicketCreation.ticket.product}
                        label={locTrans.comtransGet_m("ticketCreation", "product")}
                    >
                        {locProductsSelect}
                    </Select>
                </FormControl>
                <FormControl required sx={{mt: 1, mr: 1, mb: 1, minWidth: 140}}>
                    <InputLabel id="typeLabel">{locTrans.comtransGet_m("type", "title")}</InputLabel>
                    <Select
                        id="type"
                        labelId="typeLabel"
                        value={locCtx.modalTicketCreation.ticket.type}
                        label={locTrans.comtransGet_m("type", "title")}
                    >
                        {locTypeSelect}
                    </Select>
                </FormControl>
                <FormControl required sx={{mt: 1, mb: 1, minWidth: 140}}>
                    <InputLabel id="severityLabel">{locTrans.comtransGet_m("severity", "title")}</InputLabel>
                    <Select
                        id="severity"
                        labelId="severityLabel"
                        value={locCtx.modalTicketCreation.ticket.severity}
                        label={locTrans.comtransGet_m("severity", "title")}
                    >
                        {locSeveritySelect}
                    </Select>
                </FormControl>
            </Box>
            <Editor
                apiKey="hwa4zqtc3saqkyfpqha5fno9cngvumx3ilg8unjrreq4tfyo"
                onInit={(paramEvent, paramEditor) => locEditorRef.current = paramEditor}
                initialValue=""
                init={{
                    selector: 'textarea',
                    language: locLocale,
                    width: '100%',
                    menubar: true,
                    plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table paste code'
                    ],
                    toolbar: 'undo redo | blocks | ' +
                        'bold italic forecolor backcolor | alignleft aligncenter ' +
                        'alignright alignjustify | bullist numlist outdent indent | ',
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                    branding: false,
                    statusbar: false,
                    forced_root_block: 'div',
                    force_br_newlines: true,
                    force_p_newlines: false
                }}
                onBlur={(paramEvent) => {
                    locCtx.modalTicketCreation.ticket.description = paramEvent.target.getContent();
                    frontticketcreationRefreshModal_f(locCtx);
                }}
            />
        </Box>)
}

/*
+-------------------------------------------------------------+
! Routine    : LocJSXTicketCreation                           !
! Description: JSX Ticket Creation display modal              !
!                                                             !
! IN:  - Properties including Context                         !
! OUT: - Page rendering                                       !
+-------------------------------------------------------------+
*/
function LocJSXTicketCreation(paramProps) {
    /*
    --- Initialisation
    */
    const locCtx = paramProps.ctx;
    const locTrans = locCtx.trans_o;
    const locColors = locCtx.config_o.colors;
    /*
    --- Return Login Modal
    */
    return (<div>
        <FronterrorJSX ctx={locCtx}/>
        <DialogTitle>
            <Grid container direction="row" justifyContent="flex-start">
                <Avatar sx={{bgcolor: locColors.backgroundIcon, color: locColors.foregroundIcon}}>
                    <ConfirmationNumberIcon/>
                </Avatar>
                <Box sx={{ml: 4, mt: 1}}>
                    {locTrans.comtransGet_m("ticketCreation", "title")}
                </Box>
            </Grid>
            <IconButton
                aria-label="close"
                onClick={(paramEvent) => locClose_f(locCtx, paramEvent)}
                sx={{position: 'absolute', right: 8, top: 8, color: locColors.backgroundIcon}}
            >
                <Close/>
            </IconButton>
        </DialogTitle>
        <DialogContent sx={{pb: 0, mb: 0}}>
            <LocJSXTicketFields ctx={locCtx}/>
        </DialogContent>
        <DialogActions>
            <Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{ml: 2, mr: 2}}>
                <Grid item xs>
                    <Button
                        variant="contained"
                        onClick={() => {
                            locCtx.modalTicketAddFiles.isDisplayed = true;
                            frontticketcreationRefreshModal_f(locCtx);
                        }}
                    >
                        {locTrans.comtransGet_m("ticketCreation", "files")}
                    </Button>
                    <Typography variant="body2" component="span" align="left" sx={{ml: 2}}>
                        {locTrans.comtransGet_m("ticketCreation", "nbFiles")}
                        <strong> {locCtx.modalTicketAddFiles.files_a.length} </strong>
                    </Typography>
                </Grid>
                <Grid item xs container direction="row" justifyContent="flex-end" alignItems="center">
                    <Button
                        type="submit"
                        variant="contained"
                        disabled={((locCtx.modalTicketCreation.ticket.summary.length < 1) ||
                            (locCtx.modalTicketCreation.ticket.product.length < 1) ||
                            (locCtx.modalTicketCreation.ticket.severity.length < 1) ||
                            (locCtx.modalTicketCreation.ticket.type.length < 1))}
                        onClick={(paramEvent) => locSubmit_f(locCtx, paramEvent)}
                    >
                        {locTrans.comtransGet_m("ticketCreation", "create")}
                    </Button>
                    <Button onClick={(paramEvent) => locClose_f(locCtx, paramEvent)}>
                        {locTrans.comtransGet_m("common", "cancel")}
                    </Button>
                </Grid>
            </Grid>
        </DialogActions>
    </div>);
}

/*
+-------------------------------------------------------------+
! Routine : LocJSXWaiting                                     !
! Description: JSX User Waiting modal, local definition       !
!                                                             !
! IN: - Properties including Context                          !
! OUT: - Page rendering                                       !
+-------------------------------------------------------------+
*/
function LocJSXWaiting(paramProps) {
    /*
    --- Initialisation
    */
    const locCtx = paramProps.ctx;
    const locTrans = locCtx.trans_o;
    const locColors = locCtx.config_o.colors;

    /*
    --- Return Login Modal
    */
    return (<div>
        <DialogTitle>
            <Grid container direction="row" justifyContent="flex-start">
                <Avatar sx={{bgcolor: locColors.backgroundIcon, color: locColors.foregroundIcon}}>
                    <ConfirmationNumberIcon/>
                </Avatar>
                <Box sx={{ml: 4, mt: 1}}>
                    {locTrans.comtransGet_m("ticketCreation", "title")}
                </Box>
            </Grid>
        </DialogTitle>
        <DialogContent>
            <Box
                sx={{
                    marginTop: 1, display: 'flex', flexDirection: 'column', alignItems: 'center',
                }}
            >
                <Box sx={{mt: 1}}>
                    <CircularProgress/>
                </Box>
            </Box>
        </DialogContent>
    </div>);
}

/*+-------------------------------------------------------------+
  ! Routine    : LocJSXResponseOkDisplay                        !
  ! Description: JSX Confirmation modal, local definition       !
  !                                                             !
  ! IN:  - Properties including Context                         !
  ! OUT: - Page rendering                                       !
  +-------------------------------------------------------------+
*/
function LocJSXResponseOkDisplay(paramProps) {
    /*
    --- Initialisation
    */
    const locCtx = paramProps.ctx;
    const locTrans = locCtx.trans_o;
    const locColors = locCtx.config_o.colors;
    /*
    --- Return Login Modal
    */
    return (<div>
        <DialogTitle>
            <Box sx={{mb: 2, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <Avatar sx={{bgcolor: locColors.backgroundIcon, color: locColors.foregroundIcon}}>
                    <LockOutlinedIcon/>
                </Avatar>
            </Box>
        </DialogTitle>
        <DialogContent>
            <Box
                sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}
            >
                <Box sx={{mt: 1, mb: 1}}>
                    <Typography variant="body2" component="div" align="center">
                        {locTrans.comtransGet_m("ticketCreation", "completed")}
                        <br/>
                        <strong> {locCtx.modalTicketCreation.ticket.reference} </strong>
                    </Typography>
                </Box>
            </Box>
        </DialogContent>
        <DialogActions>
            <Button
                type="submit"
                variant="contained"
                onClick={(paramEvent) => locClose_f(locCtx, paramEvent)}
            >
                {locTrans.comtransGet_m("common", "return")}
            </Button>
        </DialogActions>
    </div>);
}

/*=============== Exported functions ===========================*/

/*
+-------------------------------------------------------------+
! Routine : frontticketcreationRefreshModal_f                 !
! Description: Request the refresh of Ticket Creation Modal   !
!                                                             !
! IN: - Context                                               !
! OUT: - Nothing                                              !
+-------------------------------------------------------------+
*/
export function frontticketcreationRefreshModal_f(paramCtx) {
    paramCtx.refresh_o.ticketCreation_s = !paramCtx.refresh_o.ticketCreation_s;
    paramCtx.refresh_o.ticketCreation_f(paramCtx.refresh_o.ticketCreation_s);
}

/*=============== Exported JSX components ======================*/

/*
+-------------------------------------------------------------+
! Routine : FrontticketcreationJSX                            !
! Description: JSX Ticket creation Modal                      !
!                                                             !
! IN: - Properties including Context                          !
! OUT: - Page rendering                                       !
+-------------------------------------------------------------+
*/
export function FrontticketcreationJSX(paramProps) {
    /*
    --- Initialisation
    */
    const locCtx = paramProps.ctx;
    /*
    --- Get React state for refreshing the page
    */
    const [locTicketCreation_s, locTicketCreation_f] = React.useState(false);
    locCtx.refresh_o.ticketCreation_f = locTicketCreation_f;
    locCtx.refresh_o.ticketCreation_s = locTicketCreation_s;
    /*
    --- Process the modal according the Tickets list request message state
    */
    let locModalContains;
    switch (locCtx.modalTicketCreation.msgProductListState) {
        case frontmsgState_e.idle:
            /*
            --- Initialize Fields
            */
            locInitFields_f(locCtx);
            /*
            --- Request the tickets list and display waiting modal
            */
            frontmsgProductsListRequest_f(locCtx);
            locModalContains = (<LocJSXWaiting ctx={locCtx}/>);
            break
        case frontmsgState_e.inError:
            locModalContains = (<LocJSXTicketCreation ctx={locCtx}/>);
            break
        case frontmsgState_e.requested:
            locModalContains = (<LocJSXWaiting ctx={locCtx}/>);
            break
        case frontmsgState_e.ok:
            /*
            --- Process according the Ticket Creation message status
            */
            switch (locCtx.modalTicketCreation.msgTicketCreationState) {
                case frontmsgState_e.idle:
                case frontmsgState_e.inError:
                    locModalContains = (<LocJSXTicketCreation ctx={locCtx}/>);
                    break
                case frontmsgState_e.requested:
                    locModalContains = (<LocJSXWaiting ctx={locCtx}/>);
                    break
                default:
                    locModalContains = (<></>);
                    break
            }
            break
        default:
            locModalContains = (<></>);
            break
    }
    /*
    --- Return the Modal
    */
    return (
        <div>
            <Dialog open={true} fullWidth maxWidth="xl">
                {locModalContains}
            </Dialog>
            <Dialog open={locCtx.modalTicketAddFiles.isDisplayed} fullWidth="true" maxWidth="xl">
                <FrontticketaddfilesJSX ctx={locCtx}/>
            </Dialog>
            <Dialog open={locCtx.modalTicketCreation.msgTicketCreationState === frontmsgState_e.ok}>
                <LocJSXResponseOkDisplay ctx={locCtx}/>
            </Dialog>
        </div>
    );

}

