/*
  +-------------------------------------------------------------+
  !     CODE SOURCE MATERIALS                                   !
  !     DIGITECH CONFIDENTIAL                                   !
  !                                                             !
  !     DIGITECH Platform Code                                  !
  !     (C) COPYRIGHT DIGITECH 2022                             !
  !     Licensed Internal Code - Property of DIGITECH           !
  +-------------------------------------------------------------+
  +-------------------------------------------------------------+
  !                                                             !
  !  File  : comtrans.js                                        !
  !  Desc. : Nodejs Object for translation management           !
  !                                                             !
  !  Author: D.ESTEVE                                           !
  !  Modif.: 22/04/2022                                         !
  !                                                             !
  !  0.1: Creation                                              !
  +-------------------------------------------------------------+
*/
/*=============== Imports ======================================*/

/*=============== Class definition ============================*/

/*+-------------------------------------------------------------+
  ! Class      : Comtrans_c                                     !
  ! Description: Class for translation management               !
  !                                                             !
  ! Public methods:                                             !
  !   - constructor    : Constructor setting the locale         !
  !   - comtrans_get_m : Get the translated string              !
  !   - comtrans_changeLocale_m : Change the locale             !
  +-------------------------------------------------------------+
*/
export class Comtrans_c {
    /*=============== Constructor Routine =========================*/

    /*+-------------------------------------------------------------+
      ! Description: Constructor                                    !
      !                                                             !
      ! IN:  - Locale                                               !
      !      - Strings repository                                   !
      ! OUT: - Nothing                                              !
      +-------------------------------------------------------------+
    */
    constructor(paramLocale, paramStringsRepo) {
        /*=============== Instance Variables =======================*/
        /*
        --- Locale
        */
        this.comtransShortLocale = "fr";
        /*
        --- Strings repository
        */
        this.comtransStringsRepo = {};

        /*=============== Create Instance ==========================*/
        /*
        --- Update Locale
        */
        if (paramLocale) this.comtransShortLocale = paramLocale.substring(0, 2);
        /*
        --- Update Strings repository
        */
        if (paramStringsRepo) {
            this.comtransStringsRepo = paramStringsRepo;
        } else {
            return false;
        } // endif
        return true;
    } // Constructor

    /*=============== Class methods ================================*/

    /*+-------------------------------------------------------------+
      ! Routine    : comtransGet_m                                  !
      ! Description: Get the translated string                      !
      !                                                             !
      ! IN:  - Page id                                              !
      !      - String id                                            !
      !      - Value 1 (optional)                                   !
      !      - Value 2 (optional)                                   !
      !      - Value 3 (optional)                                   !
      ! OUT: - Extracted string                                     !
      +-------------------------------------------------------------+
    */
    comtransGet_m(paramPageId, paramStringId, paramValue1, paramValue2, paramValue3) {
        /*
        --- Get the list of Strings for a page ID and a string ID
        */
        const locStringList = this.comtransStringsRepo[paramPageId][paramStringId];
        /*
        --- If the string list doesn't exist then return empty string
        */
        if (!locStringList) return "";
        /*
        --- Get the String according the locale
        */
        let locString = locStringList[this.comtransShortLocale];
        /*
        --- If the string doesn't exist then return empty string
        */
        if (!locString) return "";
        /*
        --- Process each optional value
        */
        if (paramValue1) locString = locString.replace("$$1$$", paramValue1);
        if (paramValue2) locString = locString.replace("$$2$$", paramValue2);
        if (paramValue3) locString = locString.replace("$$3$$", paramValue3);
        /*
        --- Return the found string
        */
        return locString;
    } // comtransGet_m

    /*+-------------------------------------------------------------+
      ! Routine    : comtransChangeLocale_m                         !
      ! Description: Change the locale                              !
      !                                                             !
      ! IN:  - New locale                                           !
      ! OUT: - Nothing                                              !
      +-------------------------------------------------------------+
    */
    comtransChangeLocale_m(paramLocale) {
        /*
        --- Update the locale value
        */
        this.comtransShortLocale = paramLocale.substring(0, 2);
    } // comtransChangeLocale_m
} // end Class
