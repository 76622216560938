/*
  +-------------------------------------------------------------+
  !     CODE SOURCE MATERIALS                                   !
  !     DIGITECH CONFIDENTIAL                                   !
  !                                                             !
  !     DIGITECH Platform Code                                  !
  !     (C) COPYRIGHT DIGITECH 2022-2024                        !
  !     Licensed Internal Code - Property of DIGITECH           !
  +-------------------------------------------------------------+
  +-------------------------------------------------------------+
  !                                                             !
  !  File  : frontticketdetail.js                               !
  !  Desc. : Nodejs Digines Front Ticket Detail Modal V2        !
  !                                                             !
  !  Author: D.ESTEVE                                           !
  !  Modif.: 25/03/2024                                         !
  !                                                             !
  !  0.1: Creation                                              !
  +-------------------------------------------------------------+
*/
/*=============== Imports ======================================*/
/*
--- External products
*/
import React from 'react';
import {
    Box,
    Button,
    Grid,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    CircularProgress,
    Avatar,
    Typography,
    IconButton,
    TableContainer,
    TableHead,
    TableRow,
    TableCell,
    Table,
    TableBody,
    Collapse,
    TableSortLabel,
    FormControl,
    Input, InputAdornment,
} from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

/*
--- Digitech products
*/
import {frontmsgState_e, frontmsgTicketDetailRequest_f} from './frontmsg';
import {frontmainModal_e, frontmainRefreshPage_f} from "./frontmain";
import {FronterrorJSX} from "./fronterror";
import ViewTimelineIcon from "@mui/icons-material/ViewTimeline";
import {grey} from "@mui/material/colors";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import {frontticketaddcommentInitFields_f, FrontticketaddcommentJSX} from "./frontticketaddcomment";
import {backinterfaceUserType} from "./diginesback/backinterface";
import {Close} from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search";

/*=============== Local functions ==============================*/

/*+-------------------------------------------------------------+
  ! Routine    : locSearchTextRow_f                             !
  ! Description: Search if a text is present in a Row           !
  !                                                             !
  ! IN:  - Context                                              !
  !      - Row                                                  !
  ! OUT: - True=found                                           !
  +-------------------------------------------------------------+
*/
function locSearchTextRow_f(paramCtx, paramRow) {
    /*
    --- Initialisation
    */
    const locColors = paramCtx.config_o.colors;
    const locModalData = paramCtx.modalTicketDetail;
    const locSearchText = locModalData.searchText.toLowerCase();
    const locTextLen = locSearchText.length;
    const locHighlightStart = "<span style='background-color: " + locColors.backgroundHighlight + "'>";
    const locHlStartLen = locHighlightStart.length;
    const locHighlightEnd = "</span>";
    const locHlEndLen = locHighlightEnd.length;

    let locFound = false;
    let locIndex = 0;
    let locStartTag = -1;
    let locEndTag = -1;
    let locNewText = "";

    /*
    --- If no search text then return ok
    */
    if (locTextLen < 1) return true;
    /*
    --- Check each column value
    */
    for (let locKey in paramRow) {
        /*
        --- Loop on each matching
        */
        locIndex = 0;
        while ((locIndex >= 0) && (locIndex < paramRow[locKey].length)) {
            /*
            --- Search text
            */
            locIndex = paramRow[locKey].toLowerCase().indexOf(locSearchText, locIndex);
            /*
            --- Process only if text is found
            */
            if (locIndex >= 0) {
                /*
                --- Search index of last previous Start and End Tag
                */
                locStartTag = paramRow[locKey].lastIndexOf('<', locIndex);
                locEndTag = paramRow[locKey].lastIndexOf('>', locIndex);
                /*
                --- Process only if text is out of HTML Tag
                */
                if ((locStartTag < 0) || (locEndTag > locStartTag)) {
                    /*
                    --- Text found
                    */
                    locFound = true;
                    /*
                    --- Highlight the found text
                    */
                    locNewText = locHighlightStart + paramRow[locKey].substring(locIndex, locIndex + locTextLen) + locHighlightEnd;
                    paramRow[locKey] = paramRow[locKey].substring(0, locIndex) + locNewText + paramRow[locKey].substring(locIndex + locTextLen);
                    /*
                    --- If the field is Detail and if the search entry has been changed then request to extend it
                    */
                    if ((locKey === 'detail') && (locModalData.searchChange)) paramRow.collapsed = false;
                    /*
                    --- Increment the index
                    */
                    locIndex += locHlStartLen + locTextLen + locHlEndLen;
                } else {
                    locIndex += locTextLen;
                }
            }
        }
    }
    /*
    --- Return the found value
    */
    return locFound;
}

/*+-------------------------------------------------------------+
  ! Routine    : locIncrementHour_f                             !
  ! Description: Increment of n hours a date                    !
  !                                                             !
  ! IN:  - Date                                                 !
  !      - Nb Hours to add                                      !
  ! OUT: - Incremented date                                     !
  +-------------------------------------------------------------+
*/
function locIncrementHour_f(paramDate, paramIncrement) {
    /*
    --- Split the Date
    */
    const locDate = paramDate.split(" ");
    const locDate_1 = locDate[0].split("/");
    const locDate_2 = locDate[1].split(":");
    /*
    --- Increment the Hours
    */
    const locHour = parseInt(locDate_2[0]) + paramIncrement;
    /*
    --- Return the rebuilt the Date
    */
    return (locDate_1[0] + "/" + locDate_1[1] + "/" + locDate_1[2] + " " + locHour + ":" + locDate_2[1] + ":" + locDate_2[2]);
}

/*+-------------------------------------------------------------+
  ! Routine    : locInitFields_f                                !
  ! Description: Initialize the Modal fields                    !
  !                                                             !
  ! IN:  - Context                                              !
  ! OUT: - Nothing                                              !
  +-------------------------------------------------------------+
*/
function locInitFields_f(paramCtx) {
    /*
    --- Initialize Modal fields
    */
    const locModalData = paramCtx.modalTicketDetail;
    locModalData.collapseStatus = [];
    locModalData.collapseIndex = 0;
    locModalData.isAllCollapsed = true;
    locModalData.isCollapseRefresh = true;
    locModalData.activeId = "";
    locModalData.sortSens.date = "";
    locModalData.searchText = "";
    locModalData.searchChange = false;
}

/*+-------------------------------------------------------------+
  ! Routine    : locCloseModal_f                                !
  ! Description: Handle the Modal Cancel Button                 !
  !                                                             !
  ! IN:  - Context                                              !
  !      - Event                                                !
  ! OUT: - Nothing                                              !
  +-------------------------------------------------------------+
*/
function locCloseModal_f(paramCtx, paramEvent) {
    /*
    --- Stop Event
    */
    paramEvent.preventDefault();
    /*
    --- Close the Modal and come back to the Ticket List modal
    */
    paramCtx.currentModal = frontmainModal_e.ticketsListModal;
    /*
    --- Reset the messages state
    */
    paramCtx.modalTicketsList.msgState = frontmsgState_e.idle;
    paramCtx.modalTicketDetail.msgState = frontmsgState_e.idle;
    paramCtx.error_o.inError = false;
    /*
    --- Refresh the main page
    */
    frontmainRefreshPage_f(paramCtx);
}

/*+-------------------------------------------------------------+
  ! Routine    : locAddComment_f                                !
  ! Description: Handle the Add Comment Button in Detail modal  !
  !                                                             !
  ! IN:  - Context                                              !
  !      - Event                                                !
  ! OUT: - Nothing                                              !
  +-------------------------------------------------------------+
*/
function locAddComment_f(paramCtx, paramEvent) {
    /*
    --- Stop Event
    */
    paramEvent.preventDefault();
    /*
    --- Open the Add Comment Modal
    */
    paramCtx.modalTicketDetail.isAddCommentDisplay = true;
    paramCtx.modalTicketAddComment.comment.summary = (paramCtx.modalTicketDetail.isTicketReopen) ?
        paramCtx.trans_o.comtransGet_m("ticketDetail", "reopening") +
        " " + paramCtx.modalTicketDetail.ticketReference : "";
    paramCtx.modalTicketAddComment.comment.description = "";
    /*
    --- Refresh the modal
    */
    frontticketdetailRefreshModal_f(paramCtx);
}

/*+-------------------------------------------------------------+
  ! Routine    : locTicketReopen_f                              !
  ! Description: Handle the Ticket Reopen Button in Detail modal!
  !                                                             !
  ! IN:  - Context                                              !
  !      - Event                                                !
  ! OUT: - Nothing                                              !
  +-------------------------------------------------------------+
*/
function locTicketReopen_f(paramCtx, paramEvent) {
    /*
    --- Stop Event
    */
    paramCtx.modalTicketDetail.isTicketReopen = true;
    /*
    --- Start Reopen by an Add Comment
    */
    locAddComment_f(paramCtx, paramEvent);
}

/*+-------------------------------------------------------------+
  ! Routine    : locCleanHtml_f                                 !
  ! Description: Clean an HTML String                           !
  !                                                             !
  ! IN:  - Initial HTML String                                  !
  ! OUT: - Cleaned HTML String                                  !
  +-------------------------------------------------------------+
*/
function locCleanHtml_f(paramHtml_s) {
    /*
    --- Process character by character
    */
    let locCleanedHtml_s = "";
    let locHr = false;
    let locCopy = true;
    let locLink = false;
    let locTarget = false;
    for (let locI = 0; locI < paramHtml_s.length; locI++) {
        const locChar = paramHtml_s.slice(locI, locI + 1);
        /*
        --- Check if <hr> is found
        */
        if ((locChar === '<') && (paramHtml_s.slice(locI, locI + 4) === "<hr>")) locHr = true;
        /*
        --- Start change only after <hr> found
        */
        if (locHr === true) {
            /*
            --- Check if <img is found
            */
            if ((locChar === '<') && (paramHtml_s.slice(locI + 1, locI + 4) === "img")) locCopy = false;
            /*
            --- Check if <a is found
            */
            if ((locCopy === true) && (locChar === '<') && (paramHtml_s.slice(locI + 1, locI + 3) === "a ")) locLink = true;
            /*
            --- Check if target= is found in a Link
            */
            if ((locCopy === true) && (locLink === true) &&
                (locChar === 't') && (paramHtml_s.slice(locI, locI + 7) === "target=")) locTarget = true;
            /*
            --- Check if end of Link statement and no Target found
            */
            if ((locCopy === true) && (locLink === true) && (locChar === '>') && (locTarget === false)) {
                /*
                --- No target is declared in a link then add target="_blank"
                */
                locCleanedHtml_s += " target='_blank'";
            }
        }
        /*
        --- Copy the current char if flag ok
        */
        if (locCopy) locCleanedHtml_s += locChar;
        /*
        --- Restart the copy in end of statement
        */
        if (locChar === '>') {
            locCopy = true;
            locLink = false;
            locTarget = false;
        }
    }
    return locCleanedHtml_s;
}

/*+-------------------------------------------------------------+
  ! Routine    : locExtractHiddenInfo_f                         !
  ! Description: Extract Hidden information from an HTML Text   !
  !                                                             !
  ! IN:  - Context                                              !
  !      - HTML Text                                            !
  !      - Magic tag                                            !
  ! OUT: - Info if found else ""                                !
  +-------------------------------------------------------------+
*/
function locExtractHiddenInfo_f(paramCtx, paramHtmlText, paramMagicStart) {
    /*
    --- Initialization
    */
    let locHiddenInfo = "";
    /*
    --- Search if the Magic is present
    */
    const locMagicEnd = " -->";
    const locIndexStart = paramHtmlText.indexOf(paramMagicStart);
    if (locIndexStart > 0) {
        /*
        --- The Magic is present: Extract the Author name
        */
        const locIndexEnd = paramHtmlText.indexOf(locMagicEnd, locIndexStart);
        if (locIndexEnd > 0) {
            locHiddenInfo = paramHtmlText.substring(locIndexStart + paramMagicStart.length, locIndexEnd);
        }
    }
    /*
    --- Return the result with HTML decoded
    */
    const locDecodedHiddenInfo = (locHiddenInfo.length > 0) ? paramCtx.misc_o.commiscHtmlDecode_m(locHiddenInfo) : "";
    return (locDecodedHiddenInfo);
}

/*+-------------------------------------------------------------+
  ! Routine    : locReplaceOldUrls_f                            !
  ! Description: Replace old Files URLS by new ones             !
  !                                                             !
  ! IN:  - Context                                              !
  !      - Initial HTML Text                                    !
  ! OUT: - Updated HTML Text                                    !
  +-------------------------------------------------------------+
*/
function locReplaceOldUrls_f(paramCtx, paramInitialHtmlText) {
    /*
    --- Initialization
    */
    const locOldUrl1 = paramCtx.config_o.urlOld1FilesDownload;
    const locOldUrl2 = paramCtx.config_o.urlOld2FilesDownload;
    const locNewUrl = paramCtx.config_o.urlFilesDownload;
    /*
    --- Replace the two old URL by the new one
    */
    return (paramInitialHtmlText.replaceAll(locOldUrl1, locNewUrl).replaceAll(locOldUrl2, locNewUrl));
}

/*+-------------------------------------------------------------+
  ! Routine    : locExtractFilesList_f                          !
  ! Description: Extract Files list from an HTML Text           !
  !                                                             !
  ! IN:  - Context                                              !
  !      - HTML Text                                            !
  !      - Initial Files list                                   !
  ! OUT: - true: Files list increased                           !
  +-------------------------------------------------------------+
*/
function locExtractFilesList_f(paramCtx, paramHtmlText, paramFiles_a) {
    /*
    --- Initialization
    */
    const locSearchedElement = '<a href="' + paramCtx.config_o.urlFilesDownload;
    const locEndLink = '</a>';
    let locUpdated = false;
    /*
    --- Replace Old File Urls by the new One
    */
    const locNewHtmlText = locReplaceOldUrls_f(paramCtx, paramHtmlText);
    /*
    --- Scan each '<a>' element
    */
    for (let locI = 0; locI < locNewHtmlText.length; locI++) {
        /*
        --- Check if '<' found
        */
        if (locNewHtmlText.substring(locI, locI + 1) === '<') {
            /*
            --- Check if it's the searched element
            */
            if (locNewHtmlText.substring(locI, locI + locSearchedElement.length) === locSearchedElement) {
                /*
                --- It's a file link to save
                */
                let locEnd = locNewHtmlText.indexOf(locEndLink, locI);
                if (locEnd > locI) {
                    locEnd += locEndLink.length;
                    paramFiles_a.push(locNewHtmlText.substring(locI, locEnd));
                    locUpdated = true;
                    locI = locEnd - 1;
                }
            }
        }
    }
    /*
    --- Return the result
    */
    return locUpdated;
}

/*+-------------------------------------------------------------+
  ! Routine    : locExtractDigitechFilesList_f                  !
  ! Description: Extract Files list from an Activity            !
  !                                                             !
  ! IN:  - Context                                              !
  !      - Activity Information                                 !
  !      - Initial Files list                                   !
  ! OUT: - true: Files list increased                           !
  +-------------------------------------------------------------+
*/
function locExtractDigitechFilesList_f(paramCtx, paramActivity, paramDigitechFiles_a) {
    /*
    --- Initialization
    */
    let locUpdated = false;
    /*
    --- Search if File list exist in the activity
    */
    if (!paramActivity.files || paramActivity.files.length < 1) return locUpdated;
    /*
    --- Scan the file list of the activity
    */
    for (let locI = 0; locI < paramActivity.files.length; locI++) {
        /*
        --- Get File information
        */
        const locFile_o = paramActivity.files[locI];
        /*
        --- Only if File ID is present: update the file list
        */
        if ((locFile_o.id) && (locFile_o.id.length > 0)) {
            const locFileName = (locFile_o.name) ? locFile_o.name : "noName";
            const locFileLink = paramCtx.config_o.urlInesFiles + locFile_o.id;
            paramDigitechFiles_a.push('<a href="' + locFileLink + '" target="_blank">' + locFileName + '</a>');
            locUpdated = true;
        }
    }
    /*
    --- Return the result
    */
    return locUpdated;
}

/*+-------------------------------------------------------------+
  ! Routine    : locBuildFluxList_f                             !
  ! Description: Build the Flux list                            !
  !                                                             !
  ! IN:  - Context                                              !
  ! OUT: - Flux list                                            !
  +-------------------------------------------------------------+
*/
function locBuildFluxList_f(paramCtx) {
    /*
    --- Initialization
    */
    const locFlux_a = [];
    const locInfo = paramCtx.modalTicketDetail.ticketInformation;
    const locFluxTicket_a = locInfo.flux ? locInfo.flux : [];
    /*
    --- The first element is the ticket creation
    */
    locFlux_a.push({
        date: locInfo.creationDate ? locInfo.creationDate : "",
        author: {value: frontticketdetailGetTicketAuthor_f(paramCtx, locInfo)},
        state: {id: "14"},
        text: locInfo.text ? locInfo.text : ""
    });
    /*
    --- Add Flux elements from ticket other than creation and opening and other than Requesting Digitech response
    */
    for (let locI = 0; locI < locFluxTicket_a.length; locI++) {
        const locRecord = locFluxTicket_a[locI];
        if ((locRecord.state) && (locRecord.state.id) &&
            (locRecord.state.id !== "2") && (locRecord.state.id !== "14") && (locRecord.state.id !== "11")) {
            /*
            --- For Closing Flux, get the closing date and don't add any text
            */
            let locDate = locRecord.date ? locRecord.date : "";
            let locText = locRecord.text ? locRecord.text : "";
            if (locRecord.state.id === "4") {
                locDate = (locInfo.closingDate) ? locInfo.closingDate : "";
                locText = "";
            }
            /*
            --- Add the record in the flux list
            */
            locFlux_a.push({
                date: locDate,
                author: {value: ((locRecord.author) && (locRecord.author.value)) ? locRecord.author.value : ""},
                state: {id: locRecord.state.id},
                text: locText
            });
        }
    }
    /*
    --- Return the Flux list
    */
    return locFlux_a;
}

/*+-------------------------------------------------------------+
  ! Routine    : locAddDigitechInfoInText_f                     !
  ! Description: Add Digitech information in a comment          !
  !                                                             !
  ! IN:  - Context                                              !
  !      - Digitech Author                                      !
  !      - Activity Information                                 !
  ! OUT: - Updated text                                         !
  +-------------------------------------------------------------+
*/
function locAddDigitechInfoInText_f(paramCtx, paramAuthor, paramTask) {
    /*
    --- Initialisation
    */
    const locTrans = paramCtx.trans_o;
    let locNewText = "<div><div>";
    let locDetail = paramTask.comment ? paramTask.comment : "";

    /*
    --- Add Author Information
    */
    locNewText += "<strong>" + locTrans.comtransGet_m("ticketDetail", "author") + "</strong>: " + paramAuthor;
    /*
    --- Check if Files are attached
    */
    const locFiles_a = [];
    if (locExtractDigitechFilesList_f(paramCtx, paramTask, locFiles_a)) {
        /*
        --- Add attached files Links
        */
        locNewText += "<br /><strong>" + locTrans.comtransGet_m("ticketDetail", "attachedFiles") + "</strong>:<br />";
        let locLineFeed = 3;
        for (let locI = 0; locI < locFiles_a.length; locI++) {
            locNewText += locFiles_a[locI];
            locLineFeed--;
            if (locLineFeed < 1) {
                locNewText += "<br />"
                locLineFeed = 3;
            } else {
                locNewText += "&nbsp;&nbsp;&nbsp;";
            }
        }
    }
    /*
    --- Add the initial text and return it
    */
    locNewText += "<hr></div>" + locDetail + "</div>";
    return locNewText;
}

/*+-------------------------------------------------------------+
  ! Routine    : locHeader_f                                    !
  ! Description: Define the Tickets list columns titles         !
  !                                                             !
  ! IN:  - Context                                              !
  ! OUT: - Nothing                                              !
  +-------------------------------------------------------------+
*/
function locHeader_f(paramCtx) {
    /*
    --- Initialisation
    */
    const locTrans = paramCtx.trans_o;
    /*
    --- Return the defined columns titles
    */
    return [
        {
            id: 'type',
            label: locTrans.comtransGet_m("ticketDetail", "type"),
            minWidth: 40,
            editable: false,
            align: 'left',
        }, {
            id: 'date',
            label: locTrans.comtransGet_m("ticketDetail", "date"),
            minWidth: 80,
            editable: false,
            align: 'left',
        }, {
            id: 'author',
            label: locTrans.comtransGet_m("ticketDetail", "author"),
            minWidth: 200,
            editable: false,
            align: 'left',
        }, {
            id: 'object',
            label: locTrans.comtransGet_m("ticketDetail", "object"),
            minWidth: 500,
            editable: false,
            align: 'left',
        }];
}

/*+-------------------------------------------------------------+
  ! Routine    : locOneRowFlux_f                                !
  ! Description: Define one row of the Ticket Flux              !
  !                                                             !
  ! IN:  - Context                                              !
  !      - JSON values of the Row                               !
  !      - Index of the Row                                     !
  ! OUT: - Nothing                                              !
  +-------------------------------------------------------------+
*/
function locOneRowFlux_f(paramCtx, paramFlux) {
    /*
    --- Initialisation
    */
    const locTrans = paramCtx.trans_o;
    const locModalData = paramCtx.modalTicketDetail;
    /*
    --- Get Flux information
    */
    const locDate = paramFlux.date ? locIncrementHour_f(paramFlux.date, 1) : "";
    let locAuthor = "";
    if ((paramFlux.author) && (paramFlux.author.value)) {
        locAuthor = paramFlux.author.value;
    }
    let locObject;
    if ((paramFlux.state) && (paramFlux.state.id)) {
        locObject = locTrans.comtransGet_m("fluxObject", paramFlux.state.id);
    }
    const locDetail = paramFlux.text ? paramFlux.text : "";
    /*
    --- Search if Collapsed or not
    */
    let locCollapsed = true;
    if (locModalData.collapseStatus[locModalData.collapseIndex] !== undefined)
        locCollapsed = locModalData.collapseStatus[locModalData.collapseIndex];
    if (locModalData.isCollapseRefresh) locCollapsed = locModalData.isAllCollapsed;
    /*
    --- Build the Row
    */
    const locRow = {
        id: locModalData.collapseIndex,
        type: locTrans.comtransGet_m("ticketDetail", "flux"),
        date: locDate,
        author: locAuthor,
        object: locObject,
        detail: locDetail,
        collapsed: locCollapsed
    };
    /*
    --- If search text doesn't found then return an empty row
    */
    if (!locSearchTextRow_f(paramCtx, locRow)) {
        locModalData.collapseIndex++;
        return ({});
    }
    /*
    --- Return the column values
    */
    locModalData.collapseStatus[locModalData.collapseIndex] = locRow.collapsed;
    if (!locRow.collapsed) locModalData.isAllCollapsed = false;
    locModalData.collapseIndex++;
    return (locRow)
}

/*+-------------------------------------------------------------+
  ! Routine    : locOneRowTask_f                                !
  ! Description: Define one row of the Ticket Tasks             !
  !                                                             !
  ! IN:  - Context                                              !
  !      - JSON values of the Row                               !
  !      - Index of the Row                                     !
  ! OUT: - Row object                                           !
  +-------------------------------------------------------------+
*/
function locOneRowTask_f(paramCtx, paramTask) {
    /*
    --- Initialisation
    */
    const locTrans = paramCtx.trans_o;
    const locModalData = paramCtx.modalTicketDetail;
    const locTicket = locModalData.ticketInformation;
    /*
    --- Get the task type
    */
    const locTypeID = (paramTask.type) ? paramTask.type : "0";
    const locType = locTrans.comtransGet_m("task", locTypeID);
    /*
    --- Don't display any task with type 'Tâche (1)' if the user is not an admin or a writer
    */
    if ((locTypeID === "1") && (paramCtx.user_o.type !== backinterfaceUserType.admin) &&
        (paramCtx.user_o.type !== backinterfaceUserType.writer)) return ({});
    /*
    --- Extract Author from the Comment description
    */
    let locDetail = paramTask.comment ? paramTask.comment : "";
    let locAuthor = locExtractHiddenInfo_f(paramCtx, locDetail, paramCtx.config_o.magicTags.author);
    if (locAuthor === "") {
        /*
        --- No Author name in the Description then get the Ticket Owner Name
        */
        locAuthor = "Digitech: ";
        locAuthor += (locTicket.owner && locTicket.owner.value) ? locTicket.owner.value : "";
        /*
        --- Add Digitech Information to the Comment
        */
        locDetail = locAddDigitechInfoInText_f(paramCtx, locAuthor, paramTask);
    }
    /*
    --- Get Task information
    */
    const locDate = paramTask.creationDate ? paramTask.creationDate : "";
    const locObject = paramTask.subject ? paramTask.subject : "";
    /*
    --- Search if Collapsed or not
    */
    let locCollapsed = true;
    if (locModalData.collapseStatus[locModalData.collapseIndex] !== undefined)
        locCollapsed = locModalData.collapseStatus[locModalData.collapseIndex];
    if (locModalData.isCollapseRefresh) locCollapsed = locModalData.isAllCollapsed;
    /*
    --- Build the Row
    */
    const locRow = {
        id: locModalData.collapseIndex,
        type: locType,
        date: locDate,
        author: locAuthor,
        object: locObject,
        detail: locDetail,
        collapsed: locCollapsed
    }
    /*
    --- If search text doesn't exist then return an empty row
    */
    if (!locSearchTextRow_f(paramCtx, locRow)) {
        locModalData.collapseIndex++;
        return ({});
    }
    /*
    --- Return the column values
    */
    locModalData.collapseStatus[locModalData.collapseIndex] = locRow.collapsed;
    if (!locRow.collapsed) locModalData.isAllCollapsed = false;
    locModalData.collapseIndex++;
    /*
    --- Return the defined columns titles
    */
    return (locRow)
}

/*=============== Local JSX components =========================*/

/*+-------------------------------------------------------------+
  ! Routine    : LocJSXTableSortLabel                           !
  ! Description: JSX display of the Files attached to the Ticket!
  !                                                             !
  ! IN:  - Properties including Context                         !
  ! OUT: - Page rendering                                       !
  +-------------------------------------------------------------+
*/
function LocJSXTableSortLabel(paramProps) {
    /*
    --- Initialisation
    */
    const locCtx = paramProps.ctx;
    const locHead = paramProps.head;
    const locColors = locCtx.config_o.colors;
    const locSortSens = locCtx.modalTicketDetail.sortSens;
    const locActive = (locCtx.modalTicketDetail.activeId === locHead.id);
    /*
    --- If the header has no Sort entry then just return the label
    */
    if (!(locHead.id in locSortSens)) {
        return (
            <div>
                {locHead.label}
            </div>
        )
    }
    /*
    --- Sortable column
    */
    return (
        <TableSortLabel
            active={locActive}
            direction={(locSortSens[locHead.id].length > 0) ? locSortSens[locHead.id] : 'desc'}
            onClick={() => {
                locCtx.modalTicketDetail.activeId = locHead.id;
                switch (locSortSens[locHead.id]) {
                    case 'asc':
                        locSortSens[locHead.id] = 'desc';
                        break;
                    case 'desc':
                        locSortSens[locHead.id] = 'asc';
                        break;
                    default:
                        locSortSens[locHead.id] = 'asc';
                        break;
                }
                frontticketdetailRefreshModal_f(locCtx);
            }}
            style={{color: locColors.foregroundTableHead, width: "97%"}}
            sx={{
                '& .MuiTableSortLabel-icon': {
                    width: "20px",
                    height: "20px",
                    opacity: "50%",
                    color: locColors.foregroundTableHead + " !important",
                },
                '&:hover': {
                    background: locColors.hoverTableHead,
                    opacity: "100% !important",
                    borderRadius: '5px'
                }
            }}
        >
            {locHead.label}
            &nbsp;
        </TableSortLabel>
    )
}

/*+-------------------------------------------------------------+
  ! Routine    : LocJSXTableActions                             !
  ! Description: JSX display Table Actions fields and buttons   !
  !                                                             !
  ! IN:  - Properties including Context                         !
  ! OUT: - Page rendering                                       !
  +-------------------------------------------------------------+
*/
function LocJSXTableActions(paramProps) {
    /*
    --- Initialisation
    */
    const locCtx = paramProps.ctx;
    const locTrans = locCtx.trans_o;
    const locColors = locCtx.config_o.colors;
    const locModalData = locCtx.modalTicketDetail;
    /*
    --- Don't display the Add Comment button if the User is a Reader only
    */
    const locAddCommentButton = (locCtx.user_o.type === backinterfaceUserType.reader) ? (<></>) :
        (<Button variant="contained" sx={{ml: "4px"}} size="small"
                 onClick={(paramEvent) => locAddComment_f(locCtx, paramEvent)}>
            {locTrans.comtransGet_m("ticketDetail", "add")}
        </Button>);
    /*
    --- Display Reopen button if the ticket status is close
    */
    const locReopenButton = ((locCtx.modalTicketDetail.ticketInformation.status.id === "4") &&
        (locCtx.user_o.type !== backinterfaceUserType.reader)) ? (
            <Button variant="contained" sx={{ml: "4px"}} size="small"
                    onClick={(paramEvent) => locTicketReopen_f(locCtx, paramEvent)}>
                {locTrans.comtransGet_m("ticketDetail", "reopen")}
            </Button>)
        :
        (<></>);
    /*
    --- Return the Actions
    */
    return (
        <Grid container direction="row" justifyContent="space-between" sx={{mr: "4px", mb: "6px", mt: "10px"}}>
            <Grid item xs={6} sx={{display: "inline-flex", textAlign: "left"}}>
                <Box sx={{
                    width: "fit-content",
                    border: "1px solid " + locColors.borderTable,
                    borderRadius: "4px"
                }}>
                    <FormControl variant="standard" sx={{flexDirection: "row", alignItems: "center"}}>
                        <Input
                            id="tableSearch"
                            placeholder={locTrans.comtransGet_m("ticketDetail", "search")}
                            value={locModalData.searchText}
                            startAdornment={
                                <InputAdornment position="start"><SearchIcon fontSize="small"/></InputAdornment>}
                            sx={{width: "250px", ml: "4px"}}
                            onChange={(paramEvent) => {
                                locModalData.searchText = paramEvent.target.value;
                                locModalData.collapseStatus = [];
                                locModalData.searchChange = true;
                                frontticketdetailRefreshModal_f(locCtx);
                            }}
                            endAdornment={(locModalData.searchText.length > 0) ? (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="close"
                                        onClick={() => {
                                            locInitFields_f(locCtx);
                                            frontticketdetailRefreshModal_f(locCtx);
                                        }}
                                    >
                                        <Close fontSize="small"/>
                                    </IconButton>
                                </InputAdornment>) : (<div></div>)}
                        />
                    </FormControl>
                </Box>
                <Button
                    variant="outlined"
                    size="small"
                    startIcon={locModalData.isAllCollapsed ? <KeyboardArrowDownIcon/> : <KeyboardArrowUpIcon/>}
                    sx={{ml: "4px"}}
                    onClick={() => {
                        locModalData.isAllCollapsed = !locModalData.isAllCollapsed;
                        locModalData.isCollapseRefresh = true;
                        frontticketdetailRefreshModal_f(locCtx);
                    }}
                >
                    {locModalData.isAllCollapsed ? locTrans.comtransGet_m("ticketDetail", "expand") :
                        locTrans.comtransGet_m("ticketDetail", "collapse")}
                </Button>
            </Grid>
            <Grid item xs={6} sx={{textAlign: "right"}}>
                {locReopenButton}
                {locAddCommentButton}
                <Button size="small"
                        onClick={(paramEvent) => locCloseModal_f(locCtx, paramEvent)}
                        sx={{ml: "4px"}}>
                    {locTrans.comtransGet_m("common", "return")}
                </Button>
            </Grid>
        </Grid>
    )
}

/*+-------------------------------------------------------------+
! Routine : LocJSXFilesList !
! Description: JSX display of the Files attached to the Ticket!
! !
! IN: - Properties including Context !
! OUT: - Page rendering !
+-------------------------------------------------------------+
*/
function LocJSXFilesList(paramProps) {
    /*
    --- Initialisation
    */
    const locCtx = paramProps.ctx;
    const locTrans = locCtx.trans_o;
    const locColors = locCtx.config_o.colors;
    const locInfo = locCtx.modalTicketDetail.ticketInformation;
    /*
    --- Extract all Files from Ticket Description and Activities comment
    */
    const locFiles_a = [];
    const locDescription = (locInfo.text) ? locInfo.text : "";
    locExtractFilesList_f(locCtx, locDescription, locFiles_a);
    const locTasks_a = (locInfo.activities) ? locInfo.activities : [];
    for (let locI = 0; locI < locTasks_a.length; locI++) {
        /*
        --- Extract files from Comment and from Activity
        */
        const locTask = locTasks_a[locI];
        const locComment = (locTask.comment) ? locTask.comment : "";
        locExtractDigitechFilesList_f(locCtx, locTask, locFiles_a);
        locExtractFilesList_f(locCtx, locComment, locFiles_a);
    }
    /*
    --- Check if Attached files are present
    */
    if (locFiles_a.length < 1) {
        return (
            <div>
                <Typography variant="caption" component="div" align="left">
                    {locTrans.comtransGet_m("ticketDetail", "noAttachedFile")}
                </Typography>
            </div>
        )
    }
    /*
    --- Build the Attached Files list
    */
    let locFilesListDisplay = "";
    let locLineFeed = 2;
    for (let locI = 0; locI < locFiles_a.length; locI++) {
        locFilesListDisplay += locFiles_a[locI];
        locLineFeed--;
        if (locLineFeed < 1) {
            locFilesListDisplay += "<br/>";
            locLineFeed = 2;
        } else {
            locFilesListDisplay += "&nbsp;&nbsp;&nbsp;";
        }
    }
    locFilesListDisplay += "</div>";
    /*
    --- Return the Files list for the ticket
    */
    return (
        <div>
            <Typography variant="caption" component="div" align="left">
                {locTrans.comtransGet_m("ticketDetail", "listAttachedFiles")}
            </Typography>
            <Box sx={{
                border: "1px solid " + locColors.borderTable,
                borderRadius: "4px",
                height: "100px",
                overflowY: "auto"
            }}>
                <div dangerouslySetInnerHTML={{__html: locFilesListDisplay}}>
                </div>
            </Box>
        </div>
    );
}

/*+-------------------------------------------------------------+
! Routine    : LocJSXGeneralInfo                              !
! Description: JSX Ticket General Information display         !
!                                                             !
! IN:  - Properties including Context                         !
! OUT: - Page rendering                                       !
+-------------------------------------------------------------+
*/
function LocJSXGeneralInfo(paramProps) {
    /*
    --- Initialisation
    */
    const locCtx = paramProps.ctx;
    const locTrans = locCtx.trans_o;
    const locInfo = locCtx.modalTicketDetail.ticketInformation;
    /*
    --- Prepare Values
    */
    const locShortDescription = locInfo.name ? locInfo.name : "";
    const locAuthor = frontticketdetailGetTicketAuthor_f(locCtx, locInfo);
    let locOwner = "";
    if ((locInfo.owner) && (locInfo.owner.value)) {
        locOwner = locInfo.owner.value;
    }
    let locSeverity = "";
    if ((locInfo.criticality) && (locInfo.criticality.id)) {
        locSeverity = locTrans.comtransGet_m("severity", locInfo.criticality.id);
    }
    let locStatus = "";
    if ((locInfo.status) && (locInfo.status.id)) {
        locStatus = locTrans.comtransGet_m("status", locInfo.status.id);
    }
    let locProduct = "";
    if ((locInfo.persoFields) && (locInfo.persoFields["Service Clients"])) {
        const locSC = locInfo.persoFields["Service Clients"];
        for (let locI = 0; locI < locSC.length; locI++) {
            if (locSC[locI].id === "305") {
                locProduct = locSC[locI].value[0].value;
                break;
            }
        }
    }
    let locType = "";
    if ((locInfo.type) && (locInfo.type.id)) {
        locType = locTrans.comtransGet_m("type", locInfo.type.id);
    }
    let locOrigin = "";
    if ((locInfo.origin) && (locInfo.origin.id)) {
        if (locInfo.origin.id === "4") {
            locOrigin = locTrans.comtransGet_m("ticketDetail", "form");
        } else {
            locOrigin = locTrans.comtransGet_m("ticketDetail", "digitech");
        }
    }
    const locCreationDate = locInfo.creationDate ? locIncrementHour_f(locInfo.creationDate, 1) : "";
    const locModificationDate = locInfo.modificationDate ? locIncrementHour_f(locInfo.modificationDate, 1) : "";

    /*
    --- Return the General Information for the ticket
    */
    return (
        <Grid container direction="row" spacing={0} sx={{mb: 1}}>
            <Grid item xs={2}>
                <Typography variant="caption" component="div" align="left">
                    {locTrans.comtransGet_m("ticketDetail", "shortDescription")}
                </Typography>
            </Grid>
            <Grid item xs={10}>
                <Typography variant="body2" component="div" align="left">
                    {locShortDescription}
                </Typography>
            </Grid>

            <Grid item xs={2}>
                <Typography variant="caption" component="div" align="left">
                    {locTrans.comtransGet_m("ticketDetail", "author")}
                </Typography>
            </Grid>
            <Grid item xs={4}>
                <Typography variant="body2" component="div" align="left">
                    {locAuthor}
                </Typography>
            </Grid>

            <Grid item xs={2}>
                <Typography variant="caption" component="div" align="left">
                    {locTrans.comtransGet_m("ticketDetail", "owner")}
                </Typography>
            </Grid>
            <Grid item xs={4}>
                <Typography variant="body2" component="div" align="left">
                    {locOwner}
                </Typography>
            </Grid>

            <Grid item xs={2}>
                <Typography variant="caption" component="div" align="left">
                    {locTrans.comtransGet_m("severity", "title")}
                </Typography>
            </Grid>
            <Grid item xs={4}>
                <Typography variant="body2" component="div" align="left">
                    {locSeverity}
                </Typography>
            </Grid>

            <Grid item xs={2}>
                <Typography variant="caption" component="div" align="left">
                    {locTrans.comtransGet_m("ticketDetail", "status")}
                </Typography>
            </Grid>
            <Grid item xs={4}>
                <Typography variant="body2" component="div" align="left">
                    {locStatus}
                </Typography>
            </Grid>

            <Grid item xs={2}>
                <Typography variant="caption" component="div" align="left">
                    {locTrans.comtransGet_m("ticketDetail", "product")}
                </Typography>
            </Grid>
            <Grid item xs={4}>
                <Typography variant="body2" component="div" align="left">
                    {locProduct}
                </Typography>
            </Grid>

            <Grid item xs={2}>
                <Typography variant="caption" component="div" align="left">
                    {locTrans.comtransGet_m("ticketDetail", "type")}
                </Typography>
            </Grid>
            <Grid item xs={4}>
                <Typography variant="body2" component="div" align="left">
                    {locType}
                </Typography>
            </Grid>

            <Grid item xs={2}>
                <Typography variant="caption" component="div" align="left">
                    {locTrans.comtransGet_m("ticketDetail", "creationDate")}
                </Typography>
            </Grid>
            <Grid item xs={4}>
                <Typography variant="body2" component="div" align="left">
                    {locCreationDate}
                </Typography>
            </Grid>

            <Grid item xs={2}>
                <Typography variant="caption" component="div" align="left">
                    {locTrans.comtransGet_m("ticketDetail", "modificationDate")}
                </Typography>
            </Grid>
            <Grid item xs={4}>
                <Typography variant="body2" component="div" align="left">
                    {locModificationDate}
                </Typography>
            </Grid>

            <Grid item xs={2}>
                <Typography variant="caption" component="div" align="left">
                    {locTrans.comtransGet_m("ticketDetail", "origin")}
                </Typography>
            </Grid>
            <Grid item xs={4}>
                <Typography variant="body2" component="div" align="left">
                    {locOrigin}
                </Typography>
            </Grid>
        </Grid>)
}

/*
+-------------------------------------------------------------+
! Routine : LocJSXDataGrid                                    !
! Description: JSX Ticket table display                       !
!                                                             !
! IN: - Properties including Context                          !
! OUT: - Page rendering                                       !
+-------------------------------------------------------------+
*/
function LocJSXDataGrid(paramProps) {
    /*
    --- Initialisation
    */
    const locCtx = paramProps.ctx;
    const locColors = locCtx.config_o.colors;
    const locMisc = locCtx.misc_o;
    const locModalData = locCtx.modalTicketDetail;
    const locFlux = locBuildFluxList_f(locCtx);
    const locTask = locModalData.ticketInformation.activities ? locModalData.ticketInformation.activities : [];
    /*
    --- If Collapse Refresh is requested then reinitialize the Collapse Array
    */
    locModalData.collapseIndex = 0;
    if (locModalData.isCollapseRefresh) locModalData.collapseStatus = [];
    /*
    --- Build the Columns header
    */
    const locHeader = locHeader_f(locCtx);
    /*
    --- Build a Row for each flux and each task of the ticket
    */
    let locRows = [];
    let locRow = {};
    for (let locI = 0; locI < locFlux.length; locI++) {
        /*
        --- Get Row for a Flux
        */
        locRow = locOneRowFlux_f(locCtx, locFlux[locI]);
        /*
        --- Don't process empty row
        */
        if (locRow.type !== undefined) locRows.push(locRow);
    }
    for (let locI = 0; locI < locTask.length; locI++) {
        /*
        --- Get Row for a task
        */
        locRow = locOneRowTask_f(locCtx, locTask[locI]);
        /*
        --- Don't process empty row
        */
        if (locRow.type !== undefined) locRows.push(locRow);
    }
    /*
    --- Reset the Collapse Refresh request and the search change flag
    */
    locModalData.isCollapseRefresh = false;
    locModalData.searchChange = false;
    /*
    --- Sort the Rows according the Active sort Id
    */
    let locSortedRows;
    switch (locModalData.activeId) {
        case 'date':
            /*
            --- According if ascendant or descendant
            */
            if (locModalData.sortSens.date === 'desc') {
                locSortedRows = locRows.sort((a, b) => locMisc.commiscCompareDate_m(b.date, a.date));
            } else {
                locSortedRows = locRows.sort((a, b) => locMisc.commiscCompareDate_m(a.date, b.date));
            }
            break;
        default:
            locSortedRows = locRows.sort((a, b) => locMisc.commiscCompareDate_m(b.date, a.date));
            break;
    }
    /*
    --- Return the Data Grid
    */
    return (<div style={{width: "100%"}}>
        <TableContainer
            sx={{
                height: (locCtx.window_o.height - 310) + "px",
                mt: 0,
                border: "1px solid " + locColors.backgroundMainGrey,
                borderRadius: "5px"
            }}>
            <Table stickyHeader aria-label="sticky table" size="small" padding="none" key="tableMain">
                <TableHead key="tableHead">
                    <TableRow key="rowHeader">
                        <TableCell
                            key="arrowMain"
                            align="center"
                            style={{
                                width: "20px",
                                background: locColors.backgroundTableHead,
                                color: locColors.foregroundTableHead,
                                borderRight: "2px solid " + locColors.foregroundTableHead
                            }}>
                            <IconButton
                                aria-label="expand row"
                                size="small"
                                sx={{
                                    color: locColors.foregroundTableHead,
                                    '&:hover': {background: locColors.hoverTableHead}
                                }}
                                onClick={() => {
                                    locModalData.isAllCollapsed = !locModalData.isAllCollapsed;
                                    locModalData.isCollapseRefresh = true;
                                    frontticketdetailRefreshModal_f(locCtx);
                                }}
                            >
                                {locModalData.isAllCollapsed ? <KeyboardArrowDownIcon/> :
                                    <KeyboardArrowUpIcon/>}
                            </IconButton>
                        </TableCell>
                        {locHeader.map((paramHead, paramIndex) => (
                            <TableCell
                                key={"head" + paramHead.id}
                                align={paramHead.align}
                                style={{
                                    paddingLeft: "6px",
                                    minWidth: paramHead.minWidth,
                                    background: locColors.backgroundTableHead,
                                    color: locColors.foregroundTableHead,
                                    borderRight: (paramIndex < (locHeader.length - 1)) ?
                                        "2px solid " + locColors.foregroundTableHead : 0
                                }}
                            >
                                <LocJSXTableSortLabel ctx={locCtx} head={paramHead} index={paramIndex}/>
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {locSortedRows.map((paramRow, paramIndex) => {
                        return (
                            <React.Fragment>
                                <TableRow hover role="checkbox" tabIndex={-1} key={"row" + paramRow.id}>
                                    <TableCell key={"arrow" + paramIndex} align="center">
                                        <IconButton
                                            aria-label="expand row"
                                            size="small"
                                            onClick={() => {
                                                if (paramRow.collapsed) locModalData.isAllCollapsed = false;
                                                locModalData.collapseStatus[paramRow.id] = !paramRow.collapsed;
                                                frontticketdetailRefreshModal_f(locCtx);
                                            }}
                                        >
                                            {paramRow.collapsed ? <KeyboardArrowDownIcon/> : <KeyboardArrowUpIcon/>}
                                        </IconButton>
                                    </TableCell>
                                    {locHeader.map((paramHead) => {
                                        return (
                                            <TableCell key={"cell" + paramHead.id + paramIndex}
                                                       align={paramHead.align}
                                                       style={{paddingLeft: "6px"}}
                                            >
                                                <div dangerouslySetInnerHTML={{__html: paramRow[paramHead.id]}}/>
                                            </TableCell>
                                        )
                                    })}
                                </TableRow>
                                <TableRow role="checkbox" tabIndex={-1} key={"rowText" + paramRow.id}>
                                    <TableCell
                                        key={"cellText" + paramIndex}
                                        style={{padding: 0}}
                                        colSpan={5}>
                                        <Collapse
                                            in={!paramRow.collapsed}
                                            timeout="auto"
                                            unmountOnExit>
                                            <LocJSXDetailDisplay ctx={locCtx} text={paramRow.detail}/>
                                        </Collapse>
                                    </TableCell>
                                </TableRow>
                            </React.Fragment>
                        )
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    </div>);
}

/*
+-------------------------------------------------------------+
! Routine : LocJSXTicketDetail                                !
! Description: JSX Tickets List display modal                 !
!                                                             !
! IN: - Properties including Context                          !
! OUT: - Page rendering                                       !
+-------------------------------------------------------------+
*/
function LocJSXTicketDetail(paramProps) {
    /*
    --- Initialisation
    */
    const locCtx = paramProps.ctx;
    const locTrans = locCtx.trans_o;
    const locColors = locCtx.config_o.colors;
    /*
    --- Display Reopen button if the ticket status is close
    */
    const locReopenButton = ((locCtx.modalTicketDetail.ticketInformation.status.id === "4") &&
        (locCtx.user_o.type !== backinterfaceUserType.reader)) ? (
        <Button variant="contained" size="small"
                onClick={(paramEvent) => locTicketReopen_f(locCtx, paramEvent)}>
            {locTrans.comtransGet_m("ticketDetail", "reopen")}
        </Button>) : (<></>);
    /*
    --- Don't display the Add Comment button if the User is a Reader only
    */
    const locAddCommentButton = (locCtx.user_o.type === backinterfaceUserType.reader) ? (<></>) :
        (<Button variant="contained" sx={{ml: "4px"}} size="small"
                 onClick={(paramEvent) => locAddComment_f(locCtx, paramEvent)}>
            {locTrans.comtransGet_m("ticketDetail", "add")}
        </Button>);
    /*
    --- Return Ticket Detail Modal
    */
    return (<div>
        <FronterrorJSX ctx={locCtx}/>
        <DialogTitle>
            <Grid container direction="row" justifyContent="flex-start">
                <Avatar sx={{bgcolor: locColors.backgroundIcon, color: locColors.foregroundIcon}}>
                    <ConfirmationNumberIcon/>
                </Avatar>
                <Box sx={{ml: 4, mt: 1}}>
                    {locTrans.comtransGet_m("ticketDetail", "title")}
                    <strong>{locCtx.modalTicketDetail.ticketInformation.reference}</strong>
                </Box>
            </Grid>
            <IconButton
                aria-label="close"
                onClick={(paramEvent) => locCloseModal_f(locCtx, paramEvent)}
                sx={{position: 'absolute', right: 8, top: 8, color: grey[500]}}
            >
                <Close/>
            </IconButton>
        </DialogTitle>
        <DialogContent sx={{pb: 0, overflowX: "hidden"}}>
            <Grid container direction="row" justifyContent="space-between"
                  alignItems="flex-start" spacing={0}>
                <Grid item xs={7}>
                    <LocJSXGeneralInfo ctx={locCtx}/>
                </Grid>
                <Grid item xs={5}>
                    <LocJSXFilesList ctx={locCtx}/>
                </Grid>
            </Grid>
            <LocJSXTableActions ctx={locCtx}/>
            <LocJSXDataGrid ctx={locCtx}/>
        </DialogContent>
        <DialogActions>
            <Grid container direction="row" justifyContent="space-between"
                  alignItems="flex-end" spacing={0}>
                <Grid item xs={6} sx={{textAlign: "left"}}>
                </Grid>
                <Grid item xs={6} sx={{textAlign: "right"}}>
                    {locReopenButton}
                    {locAddCommentButton}
                    <Button size="small"
                            onClick={(paramEvent) => locCloseModal_f(locCtx, paramEvent)}
                            sx={{ml: "4px", mr: "12px"}}>
                        {locTrans.comtransGet_m("common", "return")}
                    </Button>
                </Grid>
            </Grid>
        </DialogActions>
    </div>);
}

/*
+-------------------------------------------------------------+
! Routine : LocJSXWaiting                                     !
! Description: JSX User Waiting modal, local definition       !
!                                                             !
! IN: - Properties including Context                          !
! OUT: - Page rendering                                       !
+-------------------------------------------------------------+
*/
function LocJSXWaiting(paramProps) {
    /*
    --- Initialisation
    */
    const locCtx = paramProps.ctx;
    const locTrans = locCtx.trans_o;
    const locColors = locCtx.config_o.colors;

    /*
    --- Return Waiting Modal
    */
    return (<div>
        <DialogTitle>
            <Grid container direction="row" justifyContent="flex-start">
                <Avatar sx={{bgcolor: locColors.backgroundIcon, color: locColors.foregroundIcon}}>
                    <ViewTimelineIcon/>
                </Avatar>
                <Box sx={{ml: 4, mt: 1}}>
                    {locTrans.comtransGet_m("ticketDetail", "title", locCtx.modalTicketDetail.ticketReference)}
                    <strong>{locCtx.modalTicketDetail.ticketReference}</strong>
                </Box>
            </Grid>
        </DialogTitle>
        <DialogContent>
            <Box
                sx={{
                    marginTop: 1, display: 'flex', flexDirection: 'column', alignItems: 'center',
                }}
            >
                <Box sx={{mt: 1}}>
                    <CircularProgress/>
                </Box>
            </Box>
        </DialogContent>
    </div>);
}

/*
+-------------------------------------------------------------+
! Routine : LocJSXDetailDisplay                               !
! Description: JSX Display Detail Text modal, local definition!
!                                                             !
! IN: - Properties including Context                          !
! OUT: - Page rendering                                       !
+-------------------------------------------------------------+
*/
function LocJSXDetailDisplay(paramProps) {
    /*
    --- Initialisation
    */
    const locCtx = paramProps.ctx;
    const locTrans = locCtx.trans_o;
    const locColors = locCtx.config_o.colors;
    /*
    --- Check if Text is present
    */
    const locDetailText = (paramProps.text.length > 0) ? paramProps.text :
        locTrans.comtransGet_m("ticketDetail", "noDetail");
    const locNewDetailText = locReplaceOldUrls_f(locCtx, locDetailText);
    const locCleanedText = locCleanHtml_f(locNewDetailText);
    /*
    --- Return Detail Modal
    */
    return (
        <div style={{width: "100%", padding: "10px", backgroundColor: locColors.backgroundTableDetail}}
             dangerouslySetInnerHTML={{__html: locCleanedText}}>
        </div>)
}

/*=============== Exported functions ===========================*/

/*
+-------------------------------------------------------------+
! Routine : frontticketdetailRefreshModal_f                   !
! Description: Request the refresh of the Tickets List Modal  !
!                                                             !
! IN: - Context                                               !
! OUT: - Nothing                                              !
+-------------------------------------------------------------+
*/
export function frontticketdetailRefreshModal_f(paramCtx) {
    paramCtx.refresh_o.ticketDetail_s = !paramCtx.refresh_o.ticketDetail_s;
    paramCtx.refresh_o.ticketDetail_f(paramCtx.refresh_o.ticketDetail_s);
}

/*
+-------------------------------------------------------------+
! Routine : frontticketdetailGetTicketAuthor_f                !
! Description: Get the Ticket Author                          !
!                                                             !
! IN: - Context                                               !
!     - Ticket data                                           !
! OUT: - Author if found else ""                              !
+-------------------------------------------------------------+
*/
export function frontticketdetailGetTicketAuthor_f(paramCtx, paramTicket) {
    /*
    --- Extract Author from Ticket description
    */
    const locDescription = paramTicket.text ? paramTicket.text : "";
    let locAuthor = locExtractHiddenInfo_f(paramCtx, locDescription, paramCtx.config_o.magicTags.author);
    if (locAuthor === "") {
        /*
        --- No Author name in the Description then get the Ticket Author Name
        */
        locAuthor = (paramTicket.author && paramTicket.author.value) ? paramTicket.author.value : "";
    }
    return locAuthor;
}

/*
+-------------------------------------------------------------+
! Routine : frontticketdetailGetTicketProduct_f               !
! Description: Get the Ticket Product                         !
!                                                             !
! IN: - Context                                               !
!     - Ticket data                                           !
! OUT: - Author if found else ""                              !
+-------------------------------------------------------------+
*/
export function frontticketdetailGetTicketProduct_f(paramCtx, paramTicket) {
    /*
    --- Extract Product from Ticket description
    */
    const locDescription = paramTicket.text ? paramTicket.text : "";
    return locExtractHiddenInfo_f(paramCtx, locDescription, paramCtx.config_o.magicTags.product);
}

/*=============== Exported JSX components ======================*/

/*
+-------------------------------------------------------------+
! Routine : FrontticketdetailJSX                              !
! Description: JSX Ticket Detail Modal                        !
!                                                             !
! IN: - Properties including Context                          !
! OUT: - Page rendering                                       !
+-------------------------------------------------------------+
*/
export function FrontticketdetailJSX(paramProps) {
    /*
    --- Initialisation
    */
    const locCtx = paramProps.ctx;
    /*
    --- Get React state for refreshing the page
    */
    const [locTicketDetail_s, locTicketDetail_f] = React.useState(false);
    locCtx.refresh_o.ticketDetail_f = locTicketDetail_f;
    locCtx.refresh_o.ticketDetail_s = locTicketDetail_s;
    /*
    --- Process the modal according the Tickets list request message state
    */
    let locModalContains;
    switch (locCtx.modalTicketDetail.msgState) {
        case frontmsgState_e.idle:
            /*
            --- Initialize fields, then request the tickets list, reset the add comment and display waiting modal
            */
            locInitFields_f(locCtx);
            frontmsgTicketDetailRequest_f(locCtx);
            frontticketaddcommentInitFields_f(locCtx);
            locModalContains = (<LocJSXWaiting ctx={locCtx}/>);
            break
        case frontmsgState_e.ok:
        case frontmsgState_e.inError:
            locModalContains = (<LocJSXTicketDetail ctx={locCtx}/>);
            break
        case frontmsgState_e.requested:
            locModalContains = (<LocJSXWaiting ctx={locCtx}/>);
            break
        default:
            locModalContains = (<></>);
            break
    }
    /*
    --- Return the Modal, with the Detail Display or add comment dialog if required
    */
    return (
        <div>
            <Dialog open={true} fullScreen>
                {locModalContains}
            </Dialog>
            <Dialog open={locCtx.modalTicketDetail.isAddCommentDisplay} maxWidth="xl" fullWidth>
                <FrontticketaddcommentJSX ctx={locCtx}/>
            </Dialog>
        </div>
    )
}
