/*
  +-------------------------------------------------------------+
  !     CODE SOURCE MATERIALS                                   !
  !     DIGITECH CONFIDENTIAL                                   !
  !                                                             !
  !     DIGITECH Platform Code                                  !
  !     (C) COPYRIGHT DIGITECH 2022                             !
  !     Licensed Internal Code - Property of DIGITECH           !
  +-------------------------------------------------------------+
  +-------------------------------------------------------------+
  !                                                             !
  !  File  : frontclientset.js                                  !
  !  Desc. : Nodejs Digines Front Client selection Modal        !
  !                                                             !
  !  Author: D.ESTEVE                                           !
  !  Modif.: 22/12/2022                                         !
  !                                                             !
  !  0.1: Creation                                              !
  +-------------------------------------------------------------+
*/
/*=============== Imports ======================================*/
/*
--- External products
*/
import React from 'react';
import {
    Avatar,
    Box,
    Button,
    Grid,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    CircularProgress, Paper, IconButton, InputBase, InputLabel, Select, FormControl, MenuItem, Typography,
} from "@mui/material";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import SearchIcon from '@mui/icons-material/Search';

/*
--- Digitech products
*/
import {frontmsgClientSetRequest_f, frontmsgClientsListRequest_f, frontmsgState_e} from './frontmsg';
import {FronterrorJSX} from "./fronterror";
import {frontmainModal_e, frontmainRefreshPage_f} from "./frontmain";
import {grey} from "@mui/material/colors";
import {Close} from "@mui/icons-material";

/*=============== Local functions ==============================*/

/*+-------------------------------------------------------------+
  ! Routine    : locSearch_f                                    !
  ! Description: Handle the Client Search request               !
  !                                                             !
  ! IN:  - Context                                              !
  !      - Event                                                !
  ! OUT: - Nothing                                              !
  +-------------------------------------------------------------+
*/
function locSearch_f(paramCtx, paramEvent) {
    /*
    --- Start Submit processing
    */
    paramEvent.preventDefault();
    /*
    --- Reset Selection
    */
    paramCtx.modalClientSet.selectedClientIndex = -1;
    /*
    --- Request Clients list
    */
    frontmsgClientsListRequest_f(paramCtx);
    /*
    --- Refresh the Set Client Modal
    */
    frontclientsetRefreshModal_f(paramCtx);
}

/*+-------------------------------------------------------------+
  ! Routine    : locSubmit_f                                    !
  ! Description: Handle the Submit Button                       !
  !                                                             !
  ! IN:  - Context                                              !
  !      - Event                                                !
  ! OUT: - Nothing                                              !
  +-------------------------------------------------------------+
*/
function locSubmit_f(paramCtx, paramEvent) {
    /*
    --- Start Submit processing
    */
    paramEvent.preventDefault();
    /*
    --- Get the selected Client
    */
    const locIndex = paramCtx.modalClientSet.selectedClientIndex;
    paramCtx.user_o.clientId = paramCtx.modalClientSet.clientsList_a[locIndex].id;
    paramCtx.user_o.clientName = paramCtx.modalClientSet.clientsList_a[locIndex].value;
    /*
    --- Request the Client Setting
    */
    frontmsgClientSetRequest_f(paramCtx);
    /*
    --- Refresh the Client Change Modal
    */
    frontclientsetRefreshModal_f(paramCtx);
}

/*+-------------------------------------------------------------+
  ! Routine    : locClose_f                                     !
  ! Description: Handle the Cancel Button                       !
  !                                                             !
  ! IN:  - Context                                              !
  !      - Event                                                !
  ! OUT: - Nothing                                              !
  +-------------------------------------------------------------+
*/
function locClose_f(paramCtx, paramEvent) {
    /*
    --- Stop Event
    */
    paramEvent.preventDefault();
    /*
    --- Close the Modal
    */
    paramCtx.currentModal = paramCtx.modalClientSet.parentModal;
    if (paramCtx.modalClientSet.parentModal === frontmainModal_e.loginModal)
        paramCtx.user_o.connection = frontmsgState_e.idle;
    paramCtx.modalClientSet.isDisplayed = false;
    /*
    --- Reset the message state
    */
    paramCtx.modalClientSet.msgGetClientsListState = frontmsgState_e.idle;
    paramCtx.modalClientSet.msgPutClientSetState = frontmsgState_e.idle;
    paramCtx.modalClientSet.searchText = "";
    paramCtx.modalClientSet.selectedClientIndex = -1;
    paramCtx.error_o.inError = false;
    /*
    --- Refresh the main page
    */
    frontmainRefreshPage_f(paramCtx);
}

/*=============== Local JSX components =========================*/

/*+-------------------------------------------------------------+
  ! Routine    : LocJSXClientSet                                !
  ! Description: JSX Client Selection, local definition         !
  !                                                             !
  ! IN:  - Properties including Context                         !
  ! OUT: - Page rendering                                       !
  +-------------------------------------------------------------+
*/
function LocJSXClientSet(paramProps) {
    /*
    --- Initialisation
    */
    const locCtx = paramProps.ctx;
    const locTrans = locCtx.trans_o;
    const locColors = locCtx.config_o.colors;
    /*
    --- Prepare the client list selector only if the client list has been requested
    */
    let locSelector = (<></>);
    if (locCtx.modalClientSet.msgGetClientsListState !== frontmsgState_e.idle) {
        const locClientsList_a = locCtx.modalClientSet.clientsList_a;
        /*
        --- Build Client Select list
        */
        const locClientsSelect_a = [];
        for (let locI = 0; locI < locClientsList_a.length; locI++) {
            const locIndex = "" + locI;
            locClientsSelect_a.push(
                <MenuItem value={locIndex}
                          onClick={() => {
                              locCtx.modalClientSet.selectedClientIndex = locI;
                              frontclientsetRefreshModal_f(locCtx);
                          }}>
                    {locClientsList_a[locI].value}
                </MenuItem>
            );
        }
        /*
        --- Define Select area
        */
        if (locClientsSelect_a.length > 0) {
            locSelector = (
                <FormControl required sx={{mt: 2, mr: 1, mb: 1, minWidth: 500}}>
                    <InputLabel id="clientSelectLabel">{locTrans.comtransGet_m("clientSet", "select")}</InputLabel>
                    <Select
                        id="clientSelect"
                        labelId="clientSelectLabel"
                        value={(locCtx.modalClientSet.selectedClientIndex < 0) ? "" : "" + locCtx.modalClientSet.selectedClientIndex}
                        label={locTrans.comtransGet_m("clientSet", "select")}
                    >
                        {locClientsSelect_a}
                    </Select>
                </FormControl>
            );
        } else {
            locSelector = (
                <Typography variant="body2" component="span" align="left"
                            sx={{ml: 2, color: locColors.foregroundError}}>
                    {locTrans.comtransGet_m("clientSet", "noClient")}
                </Typography>
            );
        }
    }
    /*
    --- Return Login Modal
    */
    return (
        <div>
            <FronterrorJSX ctx={locCtx}/>
            <DialogTitle>
                <Grid container direction="row" justifyContent="flex-start">
                    <Avatar sx={{bgcolor: locColors.backgroundIcon, color: locColors.foregroundIcon}}>
                        <LockOutlinedIcon/>
                    </Avatar>
                    <Box sx={{ml: 4, mt: 1}}>
                        {locTrans.comtransGet_m("clientSet", "title")}
                    </Box>
                </Grid>
                <IconButton
                    aria-label="close"
                    onClick={(paramEvent) => locClose_f(locCtx, paramEvent)}
                    sx={{position: 'absolute', right: 8, top: 8, color: grey[500]}}
                >
                    <Close/>
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Paper component="form"
                       sx={{mt: 2, mb: 2, display: 'flex', alignItems: 'center', width: 500}}
                       onSubmit={(paramEvent) => locSearch_f(locCtx, paramEvent)}>
                    <InputBase
                        sx={{ml: 1, flex: 1}}
                        defaultValue={locCtx.modalClientSet.searchText}
                        placeholder={locTrans.comtransGet_m("clientSet", "searchField")}
                        inputProps={{'aria-label': locTrans.comtransGet_m("clientSet", "searchField")}}
                        onChange={(paramEvent) => {
                            locCtx.modalClientSet.searchText = paramEvent.target.value.trim();
                        }}
                    />
                    <IconButton type="button" sx={{p: '10px'}} aria-label="search"
                                onClick={(paramEvent) => locSearch_f(locCtx, paramEvent)}>
                        <SearchIcon/>
                    </IconButton>
                </Paper>
                {locSelector}
            </DialogContent>
            <DialogActions>
                <Button
                    type="submit"
                    variant="contained"
                    disabled={(locCtx.modalClientSet.selectedClientIndex < 0)}
                    onClick={(paramEvent) => locSubmit_f(locCtx, paramEvent)}
                >
                    {locTrans.comtransGet_m("clientSet", "submit")}
                </Button>
                <Button onClick={(paramEvent) => locClose_f(locCtx, paramEvent)}>
                    {locTrans.comtransGet_m("common", "cancel")}
                </Button>
            </DialogActions>
        </div>);
}

/*+-------------------------------------------------------------+
  ! Routine    : LocJSXWaiting                                  !
  ! Description: JSX User Waiting modal, local definition       !
  !                                                             !
  ! IN:  - Properties including Context                         !
  ! OUT: - Page rendering                                       !
  +-------------------------------------------------------------+
*/
function LocJSXWaiting(paramProps) {
    /*
    --- Initialisation
    */
    const locCtx = paramProps.ctx;
    const locTrans = locCtx.trans_o;
    const locColors = locCtx.config_o.colors;
    /*
    --- Return Login Modal
    */
    return (<div>
        <DialogTitle>
            <Grid container direction="row" justifyContent="flex-start">
                <Avatar sx={{bgcolor: locColors.backgroundIcon, color: locColors.foregroundIcon}}>
                    <LockOutlinedIcon/>
                </Avatar>
                <Box sx={{ml: 4, mt: 1}}>
                    {locTrans.comtransGet_m("clientSet", "title")}
                </Box>
            </Grid>
        </DialogTitle>
        <DialogContent>
            <Box
                sx={{
                    marginTop: 1, display: 'flex', flexDirection: 'column', alignItems: 'center',
                }}
            >
                <Box sx={{mt: 1}}>
                    <CircularProgress/>
                </Box>
            </Box>
        </DialogContent>
    </div>);
}

/*=============== Exported functions ===========================*/

/*+-------------------------------------------------------------+
  ! Routine    : frontclientsetRefreshModal_f                   !
  ! Description: Request refresh of the Client selection Modal  !
  !                                                             !
  ! IN:  - Context                                              !
  ! OUT: - Nothing                                              !
  +-------------------------------------------------------------+
*/
export function frontclientsetRefreshModal_f(paramCtx) {
    paramCtx.refresh_o.clientSet_s = !paramCtx.refresh_o.clientSet_s;
    paramCtx.refresh_o.clientSet_f(paramCtx.refresh_o.clientSet_s);
}

/*=============== Exported JSX components ======================*/

/*+-------------------------------------------------------------+
  ! Routine    : FrontclientsetJS                               !
  ! Description: JSX Client selection Modal                     !
  !                                                             !
  ! IN:  - Properties including Context                         !
  ! OUT: - Page rendering                                       !
  +-------------------------------------------------------------+
*/
export function FrontclientsetJS(paramProps) {
    /*
    --- Initialisation
    */
    const locCtx = paramProps.ctx;
    locCtx.modalClientSet.parentModal = paramProps.parentModal;
    /*
    --- Get React state for refreshing the page
    */
    const [locClientSet_s, locClientSet_f] = React.useState(false);
    locCtx.refresh_o.clientSet_f = locClientSet_f;
    locCtx.refresh_o.clientSet_s = locClientSet_s;
    /*
    --- Process Modal according the Change request message status
    */
    let locContains;
    switch (locCtx.modalClientSet.msgGetClientsListState) {
        case frontmsgState_e.idle:
        case frontmsgState_e.inError:
        case frontmsgState_e.ok:
            /*
            --- Check if Message Client Set has been sent
            */
            if (locCtx.modalClientSet.msgPutClientSetState === frontmsgState_e.requested) {
                locContains = (<LocJSXWaiting ctx={locCtx}/>);
            } else {
                locContains = (<LocJSXClientSet ctx={locCtx}/>);
            }
            break
        case frontmsgState_e.requested:
            locContains = (<LocJSXWaiting ctx={locCtx}/>);
            break
        default:
            locContains = (<></>);
            break
    }
    /*
    --- Return the Modal
    */
    return (
        <div>
            <Dialog open={true}>
                {locContains}
            </Dialog>
        </div>
    );
}

