/*
  +-------------------------------------------------------------+
  !     CODE SOURCE MATERIALS                                   !
  !     DIGITECH CONFIDENTIAL                                   !
  !                                                             !
  !     DIGITECH Platform Code                                  !
  !     (C) COPYRIGHT DIGITECH 2022-2023                        !
  !     Licensed Internal Code - Property of DIGITECH           !
  +-------------------------------------------------------------+
  +-------------------------------------------------------------+
  !                                                             !
  !  File  : frontadminupload.js                                !
  !  Desc. : Nodejs Digines Front Admin Upload FAQ/DOC Modal    !
  !                                                             !
  !  Author: D.ESTEVE                                           !
  !  Modif.: 09/01/2023                                         !
  !                                                             !
  !  0.1: Creation                                              !
  +-------------------------------------------------------------+
*/
/*=============== Imports ======================================*/
/*
--- External products
*/
import React from 'react';
import {
    Box,
    Button,
    Grid,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Avatar,
    IconButton, CircularProgress
} from "@mui/material";
import {Dropzone, FileItem} from "@dropzone-ui/react";

/*
--- Digitech products
*/
import {Close} from "@mui/icons-material";
import {frontmsgFaqDocUploadRequest_f, frontmsgState_e} from "./frontmsg";
import {frontfaqdocType_e} from "./frontfaqdoc";
import LiveHelpIcon from "@mui/icons-material/LiveHelp";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import {frontmainModal_e, frontmainRefreshPage_f} from "./frontmain";

/*=============== Local functions ==============================*/

/*
  +-------------------------------------------------------------+
  ! Routine    : locClose_f                                     !
  ! Description: Handle the Return or Close Button              !
  !                                                             !
  ! IN:  - Context                                              !
  !      - Event                                                !
  ! OUT: - Nothing                                              !
  +-------------------------------------------------------------+
*/
function locClose_f(paramCtx, paramEvent) {
    /*
    --- Stop Event
    */
    paramEvent.preventDefault();
    /*
    --- Set the Modal according the type
    */
    const locType = paramCtx.modalAdminUpload.type;
    paramCtx.currentModal = (locType === frontfaqdocType_e.faq) ? frontmainModal_e.adminFaqModal : frontmainModal_e.adminDocModal;
    /*
    --- Re-initialize the Modal
    */
    frontadminuploadInitFields_f(paramCtx, locType);
    /*
    --- Refresh the Main page
    */
    frontmainRefreshPage_f(paramCtx);
}

/*
  +-------------------------------------------------------------+
  ! Routine    : locSubmit_f                                    !
  ! Description: Handle the Submit button                       !
  !                                                             !
  ! IN:  - Context                                              !
  !      - Event                                                !
  ! OUT: - Nothing                                              !
  +-------------------------------------------------------------+
*/
function locSubmit_f(paramCtx, paramEvent) {
    /*
    --- Stop Event
    */
    paramEvent.preventDefault();
    /*
    --- Remove all not valid files
    */
    paramCtx.modalAdminUpload.files_a =
        paramCtx.modalAdminUpload.files_a.filter((paramFile) => paramFile.valid === true);
    /*
    --- If no file to upload then 'Return' to previous dialog
    */
    if (paramCtx.modalAdminUpload.files_a.length < 1) {
        locClose_f(paramCtx, paramEvent);
        return;
    }
    /*
    --- Start the Files Upload
    */
    frontmsgFaqDocUploadRequest_f(paramCtx);
}

/*=============== Local JSX components =========================*/

/*
  +-------------------------------------------------------------+
  ! Routine    : LocJSXUploadList                               !
  ! Description: JSX List selected FAQ/DOC files                !
  !                                                             !
  ! IN:  - Properties including Context                         !
  ! OUT: - Page rendering                                       !
  +-------------------------------------------------------------+
*/
function LocJSXUploadList(paramProps) {
    /*
    --- Initialisation
    */
    const locCtx = paramProps.ctx;
    const locTrans = locCtx.trans_o;
    /*
    --- Convert locale for Dropzone
    */
    const locLocale = (locCtx.config_o.locale === "fr-FR") ? "FR-fr" : "EN-en";
    /*
    --- Return Dropzone with Files list updated
    */
    return (
        <Box>
            <Dropzone
                localization={locLocale}
                view="list"
                info={true}
                maxFileSize={locCtx.config_o.maxFaqDocFileSize}
                label={locTrans.comtransGet_m("adminFaqDoc", "select")}
                onChange={(paramFilesList) => {
                    locCtx.modalAdminUpload.files_a = paramFilesList;
                    frontadminuploadRefreshModal_f(locCtx);
                }}
                value={locCtx.modalAdminUpload.files_a}>
                {locCtx.modalAdminUpload.files_a.length > 0 &&
                    locCtx.modalAdminUpload.files_a.map((paramFile) => (
                        <FileItem
                            {...paramFile}
                            preview
                            info
                            alwaysActive={true}
                            localization={locLocale}
                            onDelete={(paramId) => {
                                locCtx.modalAdminUpload.files_a =
                                    locCtx.modalAdminUpload.files_a.filter((paramFile) => paramFile.id !== paramId);
                                frontadminuploadRefreshModal_f(locCtx);
                            }}
                        />
                    ))}
            </Dropzone>
        </Box>)
}

/*
+-------------------------------------------------------------+
! Routine    : LocJSXAdminUpload                              !
! Description: JSX Admin Upload FAQ/Doc display modal         !
!                                                             !
! IN:  - Properties including Context                         !
! OUT: - Page rendering                                       !
+-------------------------------------------------------------+
*/
function LocJSXAdminUpload(paramProps) {
    /*
    --- Initialisation
    */
    const locCtx = paramProps.ctx;
    const locTrans = locCtx.trans_o;
    const locColors = locCtx.config_o.colors;
    const locIcon = (locCtx.modalAdminUpload.type === frontfaqdocType_e.faq) ? (<LiveHelpIcon/>) : (<MenuBookIcon/>);
    /*
    --- Return Add Comment Modal
    */
    return (<div>
        <DialogTitle>
            <Grid container direction="row" justifyContent="flex-start">
                <Avatar sx={{bgcolor: locColors.backgroundIcon, color: locColors.foregroundIcon}}>
                    {locIcon}
                </Avatar>
                <Box sx={{ml: 4, mt: 1}}>
                    {locTrans.comtransGet_m("adminFaqDoc", "titleSelect")}
                </Box>
            </Grid>
            <IconButton
                aria-label="close"
                onClick={(paramEvent) => locClose_f(locCtx, paramEvent)}
                sx={{position: 'absolute', right: 8, top: 8, color: locColors.backgroundIcon}}
            >
                <Close/>
            </IconButton>
        </DialogTitle>
        <DialogContent sx={{pb: 0}}>
            <LocJSXUploadList ctx={locCtx}/>
        </DialogContent>
        <DialogActions sx={{mt: 1, pt: 0, mr: 2}}>
            <Button variant="contained" onClick={(paramEvent) => locSubmit_f(locCtx, paramEvent)}
                    disabled={locCtx.modalAdminUpload.files_a.length < 1}>
                {locTrans.comtransGet_m("adminFaqDoc", "uploadSubmit")}
            </Button>
        </DialogActions>
    </div>);
}

/*
+-------------------------------------------------------------+
! Routine : LocJSXWaiting                                     !
! Description: JSX Waiting modal, local definition            !
!                                                             !
! IN: - Properties including Context                          !
! OUT: - Page rendering                                       !
+-------------------------------------------------------------+
*/
function LocJSXWaiting(paramProps) {
    /*
    --- Initialisation
    */
    const locCtx = paramProps.ctx;
    const locTrans = locCtx.trans_o;
    const locColors = locCtx.config_o.colors;
    const locIcon = (locCtx.modalAdminFaqDoc.type === frontfaqdocType_e.faq) ? (<LiveHelpIcon/>) : (<MenuBookIcon/>);
    /*
    --- Return Login Modal
    */
    return (<div>
        <DialogTitle>
            <Grid container direction="row" justifyContent="flex-start">
                <Avatar sx={{bgcolor: locColors.backgroundIcon, color: locColors.foregroundIcon}}>
                    {locIcon}
                </Avatar>
                <Box sx={{ml: 4, mt: 1}}>
                    {locTrans.comtransGet_m("adminFaqDoc", "uploading")+
                    locCtx.modalAdminUpload.currentFile}
                </Box>
            </Grid>
        </DialogTitle>
        <DialogContent>
            <Box
                sx={{
                    marginTop: 1, display: 'flex', flexDirection: 'column', alignItems: 'center',
                }}
            >
                <Box sx={{mt: 1}}>
                    <CircularProgress/>
                </Box>
            </Box>
        </DialogContent>
    </div>);
}

/*=============== Exported functions ===========================*/

/*+-------------------------------------------------------------+
  ! Routine    : frontadminuploadInitFields_f                   !
  ! Description: Initialise the Fields                          !
  !                                                             !
  ! IN:  - Context                                              !
  !      - Dialog Refresh Function                              !
  !      - Current Ticket Id                                    !
  ! OUT: - Nothing                                              !
  +-------------------------------------------------------------+
*/
export function frontadminuploadInitFields_f(paramCtx, paramType) {
    paramCtx.modalAdminUpload = {
        type: paramType,
        files_a: [],
        nbUploadsDone: 0,
        uploadState: frontmsgState_e.idle,
        currentFile: ""
    };
}

/*
+-------------------------------------------------------------+
! Routine : frontadminuploadRefreshModal_f                    !
! Description: Request the refresh of FAQ/DOC Upload Modal    !
!                                                             !
! IN: - Context                                               !
! OUT: - Nothing                                              !
+-------------------------------------------------------------+
*/
export function frontadminuploadRefreshModal_f(paramCtx) {
    paramCtx.refresh_o.adminUpload_s = !paramCtx.refresh_o.adminUpload_s;
    paramCtx.refresh_o.adminUpload_f(paramCtx.refresh_o.adminUpload_s);
}

/*=============== Exported JSX components ======================*/

/*
+-------------------------------------------------------------+
! Routine : FrontadminuploadJSX                               !
! Description: JSX Admin Upload FAQ/DOC files Modal           !
!                                                             !
! IN: - Properties including Context                          !
! OUT: - Page rendering                                       !
+-------------------------------------------------------------+
*/
export function FrontadminuploadJSX(paramProps) {
    /*
    --- Initialisation
    */
    const locCtx = paramProps.ctx;
    /*
    --- Get React state for refreshing the page
    */
    const [locAdminUpload_s, locAdminUpload_f] = React.useState(false);
    locCtx.refresh_o.adminUpload_f = locAdminUpload_f;
    locCtx.refresh_o.adminUpload_s = locAdminUpload_s;
    /*
    --- Process the modal according the Upload request message state
    */
    let locModalContains;
    switch (locCtx.modalAdminUpload.uploadState) {
        case frontmsgState_e.idle:
        case frontmsgState_e.ok:
        case frontmsgState_e.inError:
            locModalContains = (<LocJSXAdminUpload ctx={locCtx}/>);
            break
        case frontmsgState_e.requested:
            locModalContains = (<LocJSXWaiting ctx={locCtx}/>);
            break
        default:
            locModalContains = (<></>);
            break
    }
    /*
    --- Return the Modal
    */
    return (
        <Dialog open={true} maxWidth="lg" fullWidth>
            {locModalContains}
        </Dialog>);
}

