/*
  +-------------------------------------------------------------+
  !     CODE SOURCE MATERIALS                                   !
  !     DIGITECH CONFIDENTIAL                                   !
  !                                                             !
  !     DIGITECH Platform Code                                  !
  !     (C) COPYRIGHT DIGITECH 2022-2023                        !
  !     Licensed Internal Code - Property of DIGITECH           !
  +-------------------------------------------------------------+
  +-------------------------------------------------------------+
  !                                                             !
  !  File  : frontpasswordchange.js                             !
  !  Desc. : Nodejs Digines Front Password Change Modal         !
  !                                                             !
  !  Author: D.ESTEVE                                           !
  !  Modif.: 08/01/2023                                         !
  !                                                             !
  !  0.1: Creation                                              !
  +-------------------------------------------------------------+
*/
/*=============== Imports ======================================*/
/*
--- External products
*/
import React from 'react';
import {
    Avatar,
    Box,
    Button,
    Grid,
    TextField,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    CircularProgress, IconButton, Typography,
} from "@mui/material";
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';

/*
--- Digitech products
*/
import {frontmsgPasswordChangeRequest_f, frontmsgState_e} from './frontmsg';
import {FronterrorJSX} from "./fronterror";
import {Close} from "@mui/icons-material";
import {frontmainModal_e, frontmainRefreshPage_f} from "./frontmain";

/*=============== Local functions ==============================*/

/*+-------------------------------------------------------------+
  ! Routine    : locSubmit_f                                    !
  ! Description: Handle the Submit Button                       !
  !                                                             !
  ! IN:  - Context                                              !
  !      - Event                                                !
  ! OUT: - Nothing                                              !
  +-------------------------------------------------------------+
*/
function locSubmit_f(paramCtx, paramEvent) {
    /*
    --- Start Submit processing
    */
    paramEvent.preventDefault();
    /*
    --- Request Change Password
    */
    frontmsgPasswordChangeRequest_f(paramCtx);
    /*
    --- Refresh the Change Password Modal
    */
    frontPasswordChangeRefreshModal_f(paramCtx);
}

/*+-------------------------------------------------------------+
  ! Routine    : locClose_f                                     !
  ! Description: Handle the Cancel Button                       !
  !                                                             !
  ! IN:  - Context                                              !
  !      - Event                                                !
  ! OUT: - Nothing                                              !
  +-------------------------------------------------------------+
*/
function locClose_f(paramCtx, paramEvent) {
    /*
    --- Stop Event
    */
    paramEvent.preventDefault();
    /*
    --- Close the Modal
    */
    paramCtx.currentModal = frontmainModal_e.noModal;
    /*
    --- Reset the message state
    */
    paramCtx.modalPasswordChange.msgState = frontmsgState_e.idle;
    paramCtx.error_o.inError = false;
    /*
    --- Refresh the main page
    */
    frontmainRefreshPage_f(paramCtx);
}

/*+-------------------------------------------------------------+
  ! Routine    : locCheckPassword_f                             !
  ! Description: Check if password is in correct format         !
  !                                                             !
  ! IN:  - Context                                              !
  !      - Event                                                !
  ! OUT: - true: Correct format                                 !
  +-------------------------------------------------------------+
*/
function locCheckPassword_f(paramPassword) {
    /*
    --- Check length
    */
    if (paramPassword.length < 8) return false;
    /*
    --- Check if a Lowercase, an Uppercase and a numeric is present
    */
    let locUpperCaseDetected = false;
    let locLowerCaseDetected = false;
    let locNumericDetected = false;
    for (let locI = 0;
         (locI < paramPassword.length) || ((locUpperCaseDetected === false) && (locLowerCaseDetected === false) && (locNumericDetected === false));
         locI++) {
        const locChar = paramPassword.charAt(locI);
        if (locChar === locChar.toUpperCase() && locChar.match(/[a-z]/i)) locUpperCaseDetected = true;
        if (locChar === locChar.toLowerCase() && locChar.match(/[a-z]/i)) locLowerCaseDetected = true;
        if (locChar.match(/[0-9]/i)) locNumericDetected = true;
    }
    /*
    --- Return the result
    */
    if ((!locUpperCaseDetected) || (!locNumericDetected) || (!locLowerCaseDetected)) return false;
    /*
    --- All is ok: return ok
     */
    return true;
}

/*=============== Local JSX components =========================*/

/*+-------------------------------------------------------------+
  ! Routine    : LocJSXPasswordChange                           !
  ! Description: JSX Password Change modal, local definition    !
  !                                                             !
  ! IN:  - Properties including Context                         !
  ! OUT: - Page rendering                                       !
  +-------------------------------------------------------------+
*/
function LocJSXPasswordChange(paramProps) {
    /*
    --- Initialisation
    */
    const locCtx = paramProps.ctx;
    const locTrans = locCtx.trans_o;
    const locColors = locCtx.config_o.colors;
    /*
    --- Return Login Modal
    */
    return (
        <div>
            <FronterrorJSX ctx={locCtx}/>
            <DialogTitle>
                <Grid container direction="row" justifyContent="flex-start">
                    <Avatar sx={{bgcolor: locColors.backgroundIcon, color: locColors.foregroundIcon}}>
                        <ManageAccountsIcon/>
                    </Avatar>
                    <Box sx={{ml: 4, mt: 1}}>
                        {locTrans.comtransGet_m("passwordChange", "title")}
                    </Box>
                </Grid>
                <IconButton
                    aria-label="close"
                    onClick={(paramEvent) => locClose_f(locCtx, paramEvent)}
                    sx={{position: 'absolute', right: 8, top: 8, color: locColors.backgroundIcon}}
                >
                    <Close/>
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Box component="form"
                     noValidate sx={{mt: 1}}>
                    <TextField
                        disabled
                        margin="normal"
                        sx={{mb: 4}}
                        fullWidth
                        id="email"
                        defaultValue={locCtx.user_o.email}
                        label={locTrans.comtransGet_m("passwordChange", "emailAddress")}
                        name="email"
                    />
                    <TextField
                        margin="normal"
                        error={!locCtx.modalPasswordChange.oldPasswordCheckedOk}
                        autoFocus
                        required
                        fullWidth
                        name="oldPassword"
                        label={locTrans.comtransGet_m("passwordChange", "oldPassword")}
                        type="password"
                        id="oldPassword"
                        autoComplete="new-password"
                        onChange={(paramEvent) => {
                            const locOldPassword = paramEvent.target.value.trim();
                            locCtx.modalPasswordChange.oldPassword = locOldPassword;
                            locCtx.modalPasswordChange.oldPasswordCheckedOk = (locOldPassword === locCtx.user_o.password);
                            frontPasswordChangeRefreshModal_f(locCtx);
                        }}
                    />
                    <TextField
                        margin="normal"
                        error={!locCtx.modalPasswordChange.newPasswordCheckedOk}
                        required
                        fullWidth
                        name="newPassword"
                        label={locTrans.comtransGet_m("passwordChange", "newPassword")}
                        helperText={locTrans.comtransGet_m("passwordChange", "passwordRules")}
                        type="password"
                        id="newPassword"
                        autoComplete="new-password"
                        onChange={(paramEvent) => {
                            const locNewPassword = paramEvent.target.value.trim();
                            locCtx.modalPasswordChange.newPassword = locNewPassword;
                            locCtx.modalPasswordChange.newPasswordCheckedOk = locCheckPassword_f(locNewPassword);
                            frontPasswordChangeRefreshModal_f(locCtx);
                        }}
                    />
                    <TextField
                        margin="none"
                        error={!locCtx.modalPasswordChange.twicePasswordCheckedOk}
                        required
                        fullWidth
                        name="twicePassword"
                        label={locTrans.comtransGet_m("passwordChange", "twicePassword")}
                        type="password"
                        id="twicePassword"
                        autoComplete="new-password"
                        onChange={(paramEvent) => {
                            const locTwicePassword = paramEvent.target.value.trim();
                            locCtx.modalPasswordChange.twicePasswordCheckedOk =
                                (locTwicePassword === locCtx.modalPasswordChange.newPassword);
                            frontPasswordChangeRefreshModal_f(locCtx);
                        }}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button
                    type="submit"
                    variant="contained"
                    disabled={((locCtx.modalPasswordChange.oldPassword.length < 1) ||
                        (locCtx.modalPasswordChange.newPassword.length < 1) ||
                        (!locCtx.modalPasswordChange.oldPasswordCheckedOk) ||
                        (!locCtx.modalPasswordChange.newPasswordCheckedOk) ||
                        (!locCtx.modalPasswordChange.twicePasswordCheckedOk))}
                    onClick={(paramEvent) => locSubmit_f(locCtx, paramEvent)}
                >
                    {locTrans.comtransGet_m("passwordChange", "change")}
                </Button>
                <Button onClick={(paramEvent) => locClose_f(locCtx, paramEvent)}>
                    {locTrans.comtransGet_m("common", "cancel")}
                </Button>
            </DialogActions>
        </div>);
}

/*+-------------------------------------------------------------+
  ! Routine    : LocJSXWaiting                                  !
  ! Description: JSX User Waiting modal, local definition       !
  !                                                             !
  ! IN:  - Properties including Context                         !
  ! OUT: - Page rendering                                       !
  +-------------------------------------------------------------+
*/
function LocJSXWaiting(paramProps) {
    /*
    --- Initialisation
    */
    const locCtx = paramProps.ctx;
    const locTrans = locCtx.trans_o;
    const locColors = locCtx.config_o.colors;
    /*
    --- Return Login Modal
    */
    return (<div>
        <DialogTitle>
            <Grid container direction="row" justifyContent="flex-start">
                <Avatar sx={{bgcolor: locColors.backgroundIcon, color: locColors.foregroundIcon}}>
                    <ManageAccountsIcon/>
                </Avatar>
                <Box sx={{ml: 4, mt: 1}}>
                    {locTrans.comtransGet_m("passwordChange", "title")}
                </Box>
            </Grid>
        </DialogTitle>
        <DialogContent>
            <Box
                sx={{
                    marginTop: 1, display: 'flex', flexDirection: 'column', alignItems: 'center',
                }}
            >
                <Box sx={{mt: 1}}>
                    <CircularProgress/>
                </Box>
            </Box>
        </DialogContent>
    </div>);
}

/*+-------------------------------------------------------------+
  ! Routine    : LocJSXResponseOkDisplay                        !
  ! Description: JSX Confirmation modal, local definition       !
  !                                                             !
  ! IN:  - Properties including Context                         !
  ! OUT: - Page rendering                                       !
  +-------------------------------------------------------------+
*/
function LocJSXResponseOkDisplay(paramProps) {
    /*
    --- Initialisation
    */
    const locCtx = paramProps.ctx;
    const locTrans = locCtx.trans_o;
    const locColors = locCtx.config_o.colors;
    /*
    --- Return Login Modal
    */
    return (<div>
        <DialogTitle>
            <Box sx={{mb: 2, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <Avatar sx={{bgcolor: locColors.backgroundIcon, color: locColors.foregroundIcon}}>
                    <ManageAccountsIcon/>
                </Avatar>
            </Box>
        </DialogTitle>
        <DialogContent>
            <Box
                sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}
            >
                <Box sx={{mt: 1, mb: 1}}>
                    <Typography variant="body2" component="div" align="left">
                        {locTrans.comtransGet_m("passwordChange", "completed")}
                    </Typography>
                </Box>
            </Box>
        </DialogContent>
        <DialogActions>
            <Button
                type="submit"
                variant="contained"
                onClick={(paramEvent) => locClose_f(locCtx, paramEvent)}
            >
                {locTrans.comtransGet_m("common", "return")}
            </Button>
        </DialogActions>
    </div>);
}

/*=============== Exported functions ===========================*/

/*+-------------------------------------------------------------+
  ! Routine    : frontPasswordChangeRefreshModal_f              !
  ! Description: Request refresh of the Change Password Modal   !
  !                                                             !
  ! IN:  - Context                                              !
  ! OUT: - Nothing                                              !
  +-------------------------------------------------------------+
*/
export function frontPasswordChangeRefreshModal_f(paramCtx) {
    paramCtx.refresh_o.PasswordChange_s = !paramCtx.refresh_o.PasswordChange_s;
    paramCtx.refresh_o.PasswordChange_f(paramCtx.refresh_o.PasswordChange_s);
}

/*=============== Exported JSX components ======================*/

/*+-------------------------------------------------------------+
  ! Routine    : FrontpasswordchangeJSX                          !
  ! Description: JSX User Password Change Modal                 !
  !                                                             !
  ! IN:  - Properties including Context                         !
  ! OUT: - Page rendering                                       !
  +-------------------------------------------------------------+
*/
export function FrontpasswordchangeJSX(paramProps) {
    /*
    --- Initialisation
    */
    const locCtx = paramProps.ctx;
    /*
    --- Get React state for refreshing the page
    */
    const [locPasswordChange_s, locPasswordChange_f] = React.useState(false);
    locCtx.refresh_o.PasswordChange_f = locPasswordChange_f;
    locCtx.refresh_o.PasswordChange_s = locPasswordChange_s;
    /*
    --- Process Modal according the Change request message status
    */
    let locContains;
    switch (locCtx.modalPasswordChange.msgState) {
        case frontmsgState_e.idle:
        case frontmsgState_e.inError:
        case frontmsgState_e.ok:
            locContains = (<LocJSXPasswordChange ctx={locCtx}/>);
            break
        case frontmsgState_e.requested:
            locContains = (<LocJSXWaiting ctx={locCtx}/>);
            break
        default:
            locContains = (<></>);
            break
    }
    /*
    --- Return the Modal
    */
    return (
        <div>
            <Dialog open={true}>
                {locContains}
            </Dialog>
            <Dialog open={locCtx.modalPasswordChange.msgState === frontmsgState_e.ok}>
                <LocJSXResponseOkDisplay ctx={locCtx}/>
            </Dialog>
        </div>
    );
}

