/*
  +-------------------------------------------------------------+
  !     CODE SOURCE MATERIALS                                   !
  !     DIGITECH CONFIDENTIAL                                   !
  !                                                             !
  !     DIGITECH Platform Code                                  !
  !     (C) COPYRIGHT DIGITECH 2022                             !
  !     Licensed Internal Code - Property of DIGITECH           !
  +-------------------------------------------------------------+
  +-------------------------------------------------------------+
  !                                                             !
  !  File  : frontcontactset.js                                 !
  !  Desc. : Nodejs Digines Front Contact selection Modal       !
  !                                                             !
  !  Author: D.ESTEVE                                           !
  !  Modif.: 13/12/2022                                         !
  !                                                             !
  !  0.1: Creation                                              !
  +-------------------------------------------------------------+
*/
/*=============== Imports ======================================*/
/*
--- External products
*/
import React from 'react';
import {
    Avatar,
    Box,
    Button,
    Grid,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    CircularProgress, IconButton, InputLabel, Select, FormControl, MenuItem,
} from "@mui/material";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

/*
--- Digitech products
*/
import {frontmsgContactSetRequest_f, frontmsgState_e} from './frontmsg';
import {FronterrorJSX} from "./fronterror";
import {frontmainModal_e, frontmainRefreshPage_f} from "./frontmain";
import {grey} from "@mui/material/colors";
import {Close} from "@mui/icons-material";

/*=============== Local functions ==============================*/

/*+-------------------------------------------------------------+
  ! Routine    : locSubmit_f                                    !
  ! Description: Handle the Submit Button                       !
  !                                                             !
  ! IN:  - Context                                              !
  !      - Event                                                !
  ! OUT: - Nothing                                              !
  +-------------------------------------------------------------+
*/
function locSubmit_f(paramCtx, paramEvent) {
    /*
    --- Start Submit processing
    */
    paramEvent.preventDefault();
    /*
    --- Get the selected Client
    */
    const locIndex = paramCtx.modalContactSet.selectedContactIndex;
    paramCtx.user_o.id = paramCtx.modalContactSet.contactsList_a[locIndex].contactId;
    paramCtx.user_o.clientId = paramCtx.modalContactSet.contactsList_a[locIndex].client_o.id;
    paramCtx.user_o.clientName = paramCtx.modalContactSet.contactsList_a[locIndex].client_o.value;
    /*
    --- Request the Client Setting
    */
    frontmsgContactSetRequest_f(paramCtx);
    /*
    --- Refresh the Change Password Modal
    */
    frontcontactsetRefreshModal_f(paramCtx);
}

/*+-------------------------------------------------------------+
  ! Routine    : locClose_f                                     !
  ! Description: Handle the Cancel Button                       !
  !                                                             !
  ! IN:  - Context                                              !
  !      - Event                                                !
  ! OUT: - Nothing                                              !
  +-------------------------------------------------------------+
*/
function locClose_f(paramCtx, paramEvent) {
    /*
    --- Stop Event
    */
    paramEvent.preventDefault();
    /*
    --- Close the Modal
    */
    /*
    --- Close the Modal
    */
    paramCtx.currentModal = paramCtx.modalContactSet.parentModal;
    if (paramCtx.modalContactSet.parentModal === frontmainModal_e.loginModal)
        paramCtx.user_o.connection = frontmsgState_e.idle;
    paramCtx.modalContactSet.isDisplayed = false;
    /*
    --- Reset the message state
    */
    paramCtx.modalContactSet.msgState = frontmsgState_e.idle;
    paramCtx.error_o.inError = false;
    /*
    --- Refresh the main page
    */
    frontmainRefreshPage_f(paramCtx);
}

/*=============== Local JSX components =========================*/

/*+-------------------------------------------------------------+
  ! Routine    : LocJSXContactSet                               !
  ! Description: JSX Contact Selection, local definition        !
  !                                                             !
  ! IN:  - Properties including Context                         !
  ! OUT: - Page rendering                                       !
  +-------------------------------------------------------------+
*/
function LocJSXContactSet(paramProps) {
    /*
    --- Initialisation
    */
    const locCtx = paramProps.ctx;
    const locTrans = locCtx.trans_o;
    const locColors = locCtx.config_o.colors;
    /*
    --- Build Clients Select list
    */
    const locContactList_a = locCtx.modalContactSet.contactsList_a;
    const locClientsSelect_a = [];
    for (let locI = 0; locI < locContactList_a.length; locI++) {
        const locIndex = "" + locI;
        locClientsSelect_a.push(
            <MenuItem value={locIndex}
                      onClick={() => {
                          locCtx.modalContactSet.selectedContactIndex = locI;
                          frontcontactsetRefreshModal_f(locCtx);
                      }}>
                {locContactList_a[locI].client_o.value}
            </MenuItem>
        );
    }
    /*
    --- Return Login Modal
    */
    return (
        <div>
            <FronterrorJSX ctx={locCtx}/>
            <DialogTitle>
                <Grid container direction="row" justifyContent="flex-start">
                    <Avatar sx={{bgcolor: locColors.backgroundIcon, color: locColors.foregroundIcon}}>
                        <LockOutlinedIcon/>
                    </Avatar>
                    <Box sx={{ml: 4, mt: 1}}>
                        {locTrans.comtransGet_m("contactSet", "title")}
                    </Box>
                </Grid>
                <IconButton
                    aria-label="close"
                    onClick={(paramEvent) => locClose_f(locCtx, paramEvent)}
                    sx={{position: 'absolute', right: 8, top: 8, color: grey[500]}}
                >
                    <Close/>
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <FormControl required sx={{mt: 2, mr: 1, mb: 1, minWidth: 500}}>
                    <InputLabel id="clientSelectLabel">{locTrans.comtransGet_m("contactSet", "select")}</InputLabel>
                    <Select
                        id="clientSelect"
                        labelId="clientSelectLabel"
                        value={(locCtx.modalContactSet.selectedContactIndex < 0) ? "" : "" + locCtx.modalContactSet.selectedContactIndex}
                        label={locTrans.comtransGet_m("contactSet", "select")}
                    >
                        {locClientsSelect_a}
                    </Select>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button
                    type="submit"
                    variant="contained"
                    disabled={(locCtx.modalContactSet.selectedContactIndex < 0)}
                    onClick={(paramEvent) => locSubmit_f(locCtx, paramEvent)}
                >
                    {locTrans.comtransGet_m("contactSet", "submit")}
                </Button>
                <Button onClick={(paramEvent) => locClose_f(locCtx, paramEvent)}>
                    {locTrans.comtransGet_m("common", "cancel")}
                </Button>
            </DialogActions>
        </div>);
}

/*+-------------------------------------------------------------+
  ! Routine    : LocJSXWaiting                                  !
  ! Description: JSX User Waiting modal, local definition       !
  !                                                             !
  ! IN:  - Properties including Context                         !
  ! OUT: - Page rendering                                       !
  +-------------------------------------------------------------+
*/
function LocJSXWaiting(paramProps) {
    /*
    --- Initialisation
    */
    const locCtx = paramProps.ctx;
    const locTrans = locCtx.trans_o;
    const locColors = locCtx.config_o.colors;
    /*
    --- Return Login Modal
    */
    return (<div>
        <DialogTitle>
            <Grid container direction="row" justifyContent="flex-start">
                <Avatar sx={{bgcolor: locColors.backgroundIcon, color: locColors.foregroundIcon}}>
                    <LockOutlinedIcon/>
                </Avatar>
                <Box sx={{ml: 4, mt: 1}}>
                    {locTrans.comtransGet_m("contactSet", "title")}
                </Box>
            </Grid>
        </DialogTitle>
        <DialogContent>
            <Box
                sx={{
                    marginTop: 1, display: 'flex', flexDirection: 'column', alignItems: 'center',
                }}
            >
                <Box sx={{mt: 1}}>
                    <CircularProgress/>
                </Box>
            </Box>
        </DialogContent>
    </div>);
}

/*=============== Exported functions ===========================*/

/*+-------------------------------------------------------------+
  ! Routine    : frontcontactsetExtractContactsList_f           !
  ! Description: Extract Contacts List (one per Client)         !
  !                                                             !
  ! IN:  - Context                                              !
  !      - Received Contacts List                               !
  ! OUT: - Nb extracted contact                                 !
  +-------------------------------------------------------------+
*/
export function frontcontactsetExtractContactsList_f(paramCtx, paramContactsList_a) {
    /*
    --- Initialisation
    */
    const locNewContactsList_a = [];
    /*
    --- Process each received Contact
    */
    for (let locI = 0; locI < paramContactsList_a.length; locI++) {
        /*
        --- Check if the Client ID is already present
        */
        const locContact_o = paramContactsList_a[locI];
        let locFound = false;
        for (let locJ = 0; locJ < locNewContactsList_a.length; locJ++) {
            if (locNewContactsList_a[locJ].client_o.id === locContact_o.client_o.id) {
                locFound = true;
                break;
            }
        }
        /*
        --- Save the contact only if already present
        */
        if (!locFound) locNewContactsList_a.push(locContact_o);
    }
    /*
    --- Update the context and return the number of extracted Contacts
    */
    paramCtx.modalContactSet.contactsList_a = locNewContactsList_a;
    return (locNewContactsList_a.length);
}

/*+-------------------------------------------------------------+
  ! Routine    : frontcontactsetRefreshModal_f                  !
  ! Description: Request refresh of the Contact selection Modal !
  !                                                             !
  ! IN:  - Context                                              !
  ! OUT: - Nothing                                              !
  +-------------------------------------------------------------+
*/
export function frontcontactsetRefreshModal_f(paramCtx) {
    paramCtx.refresh_o.contactSet_s = !paramCtx.refresh_o.contactSet_s;
    paramCtx.refresh_o.contactSet_f(paramCtx.refresh_o.contactSet_s);
}

/*=============== Exported JSX components ======================*/

/*+-------------------------------------------------------------+
  ! Routine    : FrontcontactsetJS                              !
  ! Description: JSX Contact selection Modal                    !
  !                                                             !
  ! IN:  - Properties including Context                         !
  ! OUT: - Page rendering                                       !
  +-------------------------------------------------------------+
*/
export function FrontcontactsetJS(paramProps) {
    /*
    --- Initialisation
    */
    const locCtx = paramProps.ctx;
    locCtx.modalContactSet.parentModal = paramProps.parentModal;
    /*
    --- Get React state for refreshing the page
    */
    const [locContactSet_s, locContactSet_f] = React.useState(false);
    locCtx.refresh_o.contactSet_f = locContactSet_f;
    locCtx.refresh_o.contactSet_s = locContactSet_s;
    /*
    --- Process Modal according the Change request message status
    */
    let locContains;
    switch (locCtx.modalContactSet.msgState) {
        case frontmsgState_e.idle:
        case frontmsgState_e.inError:
            locContains = (<LocJSXContactSet ctx={locCtx}/>);
            break
        case frontmsgState_e.requested:
            locContains = (<LocJSXWaiting ctx={locCtx}/>);
            break
        default:
            locContains = (<></>);
            break
    }
    /*
    --- Return the Modal
    */
    return (
        <div>
            <Dialog open={true}>
                {locContains}
            </Dialog>
        </div>
    );
}
