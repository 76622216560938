/*
  +-------------------------------------------------------------+
  !     CODE SOURCE MATERIALS                                   !
  !     DIGITECH CONFIDENTIAL                                   !
  !                                                             !
  !     DIGITECH Platform Code                                  !
  !     (C) COPYRIGHT DIGITECH 2022-2023                        !
  !     Licensed Internal Code - Property of DIGITECH           !
  +-------------------------------------------------------------+
  +-------------------------------------------------------------+
  !                                                             !
  !  File  : frontadminfaqdoc.js                                !
  !  Desc. : Nodejs Digines Front Admin FAQ and DOC files       !
  !                                                             !
  !  Author: D.ESTEVE                                           !
  !  Modif.: 05/11/2022                                         !
  !                                                             !
  !  0.1: Creation                                              !
  +-------------------------------------------------------------+
*/
/*=============== Imports ======================================*/
/*
--- External products
*/
import React from 'react';
import {
    Box,
    Button,
    Grid,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    CircularProgress,
    Avatar,
    Link, IconButton
} from "@mui/material";
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarExport,
    GridToolbarFilterButton, GridToolbarQuickFilter
} from '@mui/x-data-grid';

/*
--- Digitech products
*/
import {frontmsgFaqDocDeleteRequest_f, frontmsgFaqDocDirRequest_f, frontmsgState_e} from './frontmsg';
import {frontmainModal_e, frontmainRefreshPage_f} from "./frontmain";
import {FronterrorJSX} from "./fronterror";
import {Close} from "@mui/icons-material";
import LiveHelpIcon from "@mui/icons-material/LiveHelp";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import {frontfaqdocType_e} from "./frontfaqdoc";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {backinterfaceApi} from "./diginesback/backinterface";
import {frontadminuploadInitFields_f} from "./frontadminupload";

/*=============== Local functions ==============================*/

/*+-------------------------------------------------------------+
  ! Routine    : locClose_f                                     !
  ! Description: Handle the Cancel Button                       !
  !                                                             !
  ! IN:  - Context                                              !
  !      - Event                                                !
  ! OUT: - Nothing                                              !
  +-------------------------------------------------------------+
*/
function locClose_f(paramCtx, paramEvent) {
    /*
    --- Stop Event
    */
    paramEvent.preventDefault();
    /*
    --- Close the Modal
    */
    paramCtx.currentModal = frontmainModal_e.adminMenuModal;
    /*
    --- Reset the message state
    */
    paramCtx.modalAdminFaqDoc.msgGetDirState = frontmsgState_e.idle;
    paramCtx.error_o.inError = false;
    /*
    --- Refresh the main page
    */
    frontmainRefreshPage_f(paramCtx);
}

/*+-------------------------------------------------------------+
  ! Routine    : locHeader_f                                    !
  ! Description: Define the FAQ/DOC Files columns titles        !
  !                                                             !
  ! IN:  - Context                                              !
  ! OUT: - Nothing                                              !
  +-------------------------------------------------------------+
*/
function locHeader_f(paramCtx) {
    /*
    --- Initialisation
    */
    const locTrans = paramCtx.trans_o;
    const locMisc = paramCtx.misc_o;
    const locColors = paramCtx.config_o.colors;
    const locFaqDocCommand = (paramCtx.modalAdminFaqDoc.type === frontfaqdocType_e.faq) ?
        backinterfaceApi.getFaq : backinterfaceApi.getDoc;
    const locUriFaqDoc = "https://" + paramCtx.config_o.backServerHostname + ":" + paramCtx.config_o.backServerPort +
        "/" + locFaqDocCommand + "?raw=true&file=";
    /*
    --- Return the defined columns titles
    */
    return [{
        field: 'id', headerName: "Index", minWidth: 1, hide: true, editable: false, flex: 1
    }, {
        field: 'type',
        headerName: locTrans.comtransGet_m("adminFaqDoc", "type"),
        width: 80,
        editable: false,
    }, {
        field: 'fileName',
        headerName: locTrans.comtransGet_m("adminFaqDoc", "fileName"),
        minWidth: 400,
        flex: 1,
        editable: false,
        renderCell: (paramCell) => {
            const locUrl = locUriFaqDoc + paramCell.value;
            return (<Link href={locUrl}>{paramCell.value}</Link>);
        }
    }, {
        field: 'lastUpdate',
        type: 'dateTime',
        headerName: locTrans.comtransGet_m("adminFaqDoc", "lastUpdate"),
        width: 170,
        editable: false,
        sortComparator: (v1, v2) => locMisc.commiscCompareDate_m(v1, v2)
    }, {
        field: 'size',
        headerName: locTrans.comtransGet_m("adminFaqDoc", "size"),
        width: 200,
        editable: false,
        renderCell: (paramCell) => {
            const locSize = paramCell.value;
            if (locSize < 1024) {
                return (locSize + " " + locTrans.comtransGet_m("size", "bytes"));
            } else if (locSize < 1048576) {
                return ((locSize / 1024).toFixed(0) + " " + locTrans.comtransGet_m("size", "kBytes"));
            } else {
                return ((locSize / 1048576).toFixed(0) + " " + locTrans.comtransGet_m("size", "mBytes"));
            }
        }
    }, {
        field: 'delete',
        headerName: locTrans.comtransGet_m("adminFaqDoc", "delete"),
        width: 100,
        editable: false,
        renderCell: (paramCell) => {
            if (paramCell.value) {
                return (
                    <IconButton aria-label="delete" sx={{color: locColors.foregroundIconButton}} onClick={() => {
                        paramCtx.modalAdminFaqDoc.fileToDelete = paramCell.row.fileName;
                        frontadminfaqdocRefreshModal_f(paramCtx);
                    }}>
                        <DeleteForeverIcon/>
                    </IconButton>);
            } else {
                return (<></>);
            }
        }
    }
    ];
}

/*+-------------------------------------------------------------+
  ! Routine    : locOneRow_f                                    !
  ! Description: Define one row of the FAQ/DOC Files            !
  !                                                             !
  ! IN:  - Context                                              !
  !      - JSON values of the Row                               !
  !      - Index of the Row                                     !
  ! OUT: - Nothing                                              !
  +-------------------------------------------------------------+
*/
function locOneRow_f(paramCtx, paramFile_o, paramIndex) {
    /*
    --- Convert the Last Update Date
    */
    const locLastUpdate = new Date(paramFile_o.lastUpdate).toLocaleString("fr-FR");
    /*
    --- Get the file extension
    */
    const locFileName_a = paramFile_o.name.split('.');
    const locFileExtension = locFileName_a[locFileName_a.length - 1].toUpperCase();
    /*
    --- Return the defined columns titles
    */
    return {
        id: paramIndex,
        type: locFileExtension,
        fileName: paramFile_o.name,
        lastUpdate: locLastUpdate,
        size: paramFile_o.size,
        delete: !paramFile_o.isList
    };
}

/*=============== Local JSX components =========================*/

/*+-------------------------------------------------------------+
  ! Routine    : LocJSXDataGrid                                 !
  ! Description: JSX Admin FAQ/DOC table display                !
  !                                                             !
  ! IN:  - Properties including Context                         !
  ! OUT: - Page rendering                                       !
  +-------------------------------------------------------------+
*/
function LocJSXDataGrid(paramProps) {
    /*
    --- Initialisation
    */
    const locCtx = paramProps.ctx;
    const locColors = locCtx.config_o.colors;
    /*
    --- Build the Columns header
    */
    const locHeader = locHeader_f(locCtx);
    /*
    --- Build a Row for each File
    */
    let locRows = [];
    for (let locI = 0; locI < locCtx.modalAdminFaqDoc.faqDocDir_a.length; locI++) {
        locRows.push(locOneRow_f(locCtx, locCtx.modalAdminFaqDoc.faqDocDir_a[locI], locI))
    }
    /*
    --- Tool bar definition
    */
    const locToolBar = () => {
        return (<GridToolbarContainer>
            <GridToolbarQuickFilter/>
            <GridToolbarFilterButton/>
            <GridToolbarExport/>
        </GridToolbarContainer>)
    }
    /*
    --- Return the Data Grid
    */
    return (<div style={{height: "500px", width: "100%"}}>
        <DataGrid
            rows={locRows}
            columns={locHeader}
            density="compact"
            components={{Toolbar: locToolBar}}
            sx={{
                "& .MuiDataGrid-columnHeader": {
                    background: locColors.backgroundMainGrey,
                    color: locColors.foregroundMain
                }
            }}
            autoPageSize
            initialState={{
                sorting: {
                    sortModel: [{field: 'lastUpdate', sort: 'desc'}],
                },
            }}
        />
    </div>);
}

/*+-------------------------------------------------------------+
  ! Routine    : LocJSXAdminFaqDoc                              !
  ! Description: JSX Admin FAQ/DOC modal                        !
  !                                                             !
  ! IN:  - Properties including Context                         !
  ! OUT: - Page rendering                                       !
  +-------------------------------------------------------------+
*/
function LocJSXAdminFaqDoc(paramProps) {
    /*
    --- Initialisation
    */
    const locCtx = paramProps.ctx;
    const locTrans = locCtx.trans_o;
    const locColors = locCtx.config_o.colors;
    const locIcon = (locCtx.modalAdminFaqDoc.type === frontfaqdocType_e.faq) ? (<LiveHelpIcon/>) : (<MenuBookIcon/>);
    /*
    --- Return Login Modal
    */
    return (<div>
        <FronterrorJSX ctx={locCtx}/>
        <DialogTitle>
            <Grid container direction="row" justifyContent="flex-start">
                <Avatar sx={{bgcolor: locColors.backgroundIcon, color: locColors.foregroundIcon}}>
                    {locIcon}
                </Avatar>
                <Box sx={{ml: 4, mt: 1}}>
                    {locTrans.comtransGet_m("adminMenu",
                        (locCtx.modalAdminFaqDoc.type === frontfaqdocType_e.faq) ? "titleFaq" : "titleDoc")}
                </Box>
            </Grid>
            <IconButton
                aria-label="close"
                onClick={(paramEvent) => locClose_f(locCtx, paramEvent)}
                sx={{position: 'absolute', right: 8, top: 8, color: locColors.backgroundIcon}}
            >
                <Close/>
            </IconButton>
        </DialogTitle>
        <DialogContent sx={{pb: 0, mb: 0}}>
            <LocJSXDataGrid ctx={locCtx}/>
        </DialogContent>
        <DialogActions sx={{mt: 0, mb: 0}}>
            <Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{ml: 2, mr: 2}}>
                <Grid item xs>
                    <Button
                        variant="contained"
                        onClick={() => {
                            locCtx.currentModal = frontmainModal_e.adminUploadModal;
                            frontadminuploadInitFields_f(locCtx, locCtx.modalAdminFaqDoc.type);
                            frontmainRefreshPage_f(locCtx);
                        }}
                    >
                        {locTrans.comtransGet_m("adminFaqDoc", "upload")}
                    </Button>
                </Grid>
                <Grid item xs container direction="row" justifyContent="flex-end" alignItems="center">
                    <Button onClick={(paramEvent) => locClose_f(locCtx, paramEvent)} sx={{mr: 2}}>
                        {locTrans.comtransGet_m("common", "return")}
                    </Button>
                </Grid>
            </Grid>
        </DialogActions>
    </div>);
}

/*
+-------------------------------------------------------------+
! Routine : LocJSXWaiting                                     !
! Description: JSX Waiting modal, local definition            !
!                                                             !
! IN: - Properties including Context                          !
! OUT: - Page rendering                                       !
+-------------------------------------------------------------+
*/
function LocJSXWaiting(paramProps) {
    /*
    --- Initialisation
    */
    const locCtx = paramProps.ctx;
    const locTrans = locCtx.trans_o;
    const locColors = locCtx.config_o.colors;
    const locFaqDoc = (locCtx.modalAdminFaqDoc.type === frontfaqdocType_e.faq) ? "faq" : "doc";
    const locIcon = (locCtx.modalAdminFaqDoc.type === frontfaqdocType_e.faq) ? (<LiveHelpIcon/>) : (<MenuBookIcon/>);
    /*
    --- Return Login Modal
    */
    return (<div>
        <DialogTitle>
            <Grid container direction="row" justifyContent="flex-start">
                <Avatar sx={{bgcolor: locColors.backgroundIcon, color: locColors.foregroundIcon}}>
                    {locIcon}
                </Avatar>
                <Box sx={{ml: 4, mt: 1}}>
                    {locTrans.comtransGet_m(locFaqDoc, "adminTitle")}
                </Box>
            </Grid>
        </DialogTitle>
        <DialogContent>
            <Box sx={{marginTop: 1, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <Box sx={{mt: 1}}>
                    <CircularProgress/>
                </Box>
            </Box>
        </DialogContent>
    </div>);
}

/*
+-------------------------------------------------------------+
! Routine : LocJSXDelete                                      !
! Description: JSX Delete Confirmation modal, local definition!
!                                                             !
! IN: - Properties including Context                          !
! OUT: - Page rendering                                       !
+-------------------------------------------------------------+
*/
function LocJSXDelete(paramProps) {
    /*
    --- Initialisation
    */
    const locCtx = paramProps.ctx;
    const locTrans = locCtx.trans_o;
    const locColors = locCtx.config_o.colors;
    const locIcon = (locCtx.modalAdminFaqDoc.type === frontfaqdocType_e.faq) ? (<LiveHelpIcon/>) : (<MenuBookIcon/>);
    /*
    --- Return Login Modal
    */
    return (<div>
        <DialogTitle>
            <Grid container direction="row" justifyContent="center">
                <Avatar sx={{bgcolor: locColors.backgroundIcon, color: locColors.foregroundIcon}}>
                    {locIcon}
                </Avatar>
                <Box sx={{ml: 4, mt: 1}}>
                    {locTrans.comtransGet_m("adminFaqDoc", "titleDelete")}
                    <br/>
                    {locCtx.modalAdminFaqDoc.fileToDelete}
                </Box>
            </Grid>
        </DialogTitle>
        <DialogContent>
            <Grid item xs container direction="row" justifyContent="center" alignItems="center">
                <Button variant="contained" onClick={() => {
                    frontmsgFaqDocDeleteRequest_f(locCtx, locCtx.modalAdminFaqDoc.fileToDelete);
                    locCtx.modalAdminFaqDoc.fileToDelete = "";
                }} sx={{mr: 2}}>
                    {locTrans.comtransGet_m("common", "yes")}
                </Button>
                <Button variant="contained" onClick={() => {
                    locCtx.modalAdminFaqDoc.fileToDelete = "";
                    frontadminfaqdocRefreshModal_f(locCtx);
                }}>
                    {locTrans.comtransGet_m("common", "no")}
                </Button>
            </Grid>
        </DialogContent>
    </div>);
}

/*=============== Exported functions ===========================*/

/*
+-------------------------------------------------------------+
! Routine : frontadminfaqdocRefreshModal_f                    !
! Description: Request the refresh of the FAQ/DOC Admin Modal !
!                                                             !
! IN: - Context                                               !
! OUT: - Nothing                                              !
+-------------------------------------------------------------+
*/
export function frontadminfaqdocRefreshModal_f(paramCtx) {
    paramCtx.refresh_o.adminFaqDoc_s = !paramCtx.refresh_o.adminFaqDoc_s;
    paramCtx.refresh_o.adminFaqDoc_f(paramCtx.refresh_o.adminFaqDoc_s);
}

/*=============== Exported JSX components ======================*/

/*
+-------------------------------------------------------------+
! Routine : FrontadminfaqdocJSX                               !
! Description: JSX Admin FAQ and DOC files Modal              !
!                                                             !
! IN: - Properties including Context                          !
! OUT: - Page rendering                                       !
+-------------------------------------------------------------+
*/
export function FrontadminfaqdocJSX(paramProps) {
    /*
    --- Initialisation
    */
    const locCtx = paramProps.ctx;
    locCtx.modalAdminFaqDoc.type = paramProps.type;
    /*
    --- Get React state for refreshing the page
    */
    const [locAdminFaqDoc_s, locAdminFaqDoc_f] = React.useState(false);
    locCtx.refresh_o.adminFaqDoc_f = locAdminFaqDoc_f;
    locCtx.refresh_o.adminFaqDoc_s = locAdminFaqDoc_s;
    /*
    --- Process the modal according the Directory Content request message state
    */
    let locModalContains;
    switch (locCtx.modalAdminFaqDoc.msgGetDirState) {
        case frontmsgState_e.idle:
            /*
            --- Request the Directory contents and display waiting modal
            */
            frontmsgFaqDocDirRequest_f(locCtx);
            locModalContains = (<LocJSXWaiting ctx={locCtx}/>);
            break
        case frontmsgState_e.ok:
        case frontmsgState_e.inError:
            locModalContains = (<LocJSXAdminFaqDoc ctx={locCtx}/>);
            break
        case frontmsgState_e.requested:
            locModalContains = (<LocJSXWaiting ctx={locCtx}/>);
            break
        default:
            locModalContains = (<></>);
            break
    }
    /*
    --- Check if a File should be deleted
    */
    const locModalDelete = (locCtx.modalAdminFaqDoc.fileToDelete.length > 0) ?
        (
            <Dialog open={true}>
                <LocJSXDelete ctx={locCtx}/>
            </Dialog>
        ) : (<></>);
    /*
    --- Return the Modal
    */
    return (
        <div>
            <Dialog open={true} fullWidth maxWidth="xl">
                {locModalContains}
            </Dialog>
            {locModalDelete}
        </div>);

}

